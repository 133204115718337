import React from "react";

import { Dropdown, Button } from "neetoui";
import { useHistory } from "react-router-dom";
import { isPresent } from "utils";

import authenticationApi from "apis/authentication";
import {
  LOGIN_PATH,
  PRODUCT_CART_PATH,
  MY_DRAFT_PATH,
  MY_MAPS_PATH,
  MY_ORDERS_PATH,
  PERSONAL_DETAILS,
  GALLERY,
  CATALOGS,
} from "components/routeConstants";
import { useAuthState, useAuthDispatch } from "contexts/auth";
import { useUserState } from "contexts/user";
import DownSvg from "images/dropdown-down.svg";
import LogoDark from "images/logo-dark.svg";
import menuOne from "images/menu-1.jpg";
import menuThree from "images/menu-3.jpg";
import UserSvg from "images/user-icon.svg";
import { getFromLocalStorage } from "utils/storage";

import ProductTab from "./ProductTab";

const { Menu, MenuItem } = Dropdown;

const Header = () => {
  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const { authToken } = useAuthState();
  const { user: userContextState } = useUserState();
  const currentUser = userContextState;
  const isLoggedIn = isPresent(authToken) && isPresent(currentUser);
  const is_admin = getFromLocalStorage("is_admin");

  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      window.location.href = LOGIN_PATH;
    } catch (error) {
      logger.error(error);
    }
  };

  const handleCartClick = () => history.push(PRODUCT_CART_PATH);

  const handleRedirect = () => (window.location.href = process.env.HOME_URL);

  const handleAdminClick = () => history.push(CATALOGS);

  return (
    <div className="common">
      <header>
        <div className="container flex">
          <div className="h-logo-cont w-1/3">
            <div
              onClick={handleRedirect}
              className="logo"
              style={{
                width: "124px",
                height: "28px",
              }}
            >
              <LogoDark />
            </div>
            <span className="logo-sub">Builder</span>
          </div>

          <div className="h-menu-cont w-1/3">
            <nav>
              <ul className="main-menu">
                <li>
                  <a href="https://boundri.com/shop/">Online Store</a>
                  <div className="sub-menu-inn">
                    <div className="w-1/2">
                      <h3>Store categories</h3>
                      <ul className="sub-menu">
                        <li>
                          <a className="#">Toy play rugs</a>
                        </li>
                        <li>
                          <a className="#">Race tracks</a>
                        </li>
                        <li>
                          <a className="#">Sporting events</a>
                        </li>
                        <li>
                          <a className="#">World sights</a>
                        </li>
                      </ul>
                    </div>
                    <div className="w-1/2">
                      <img width="180" height="252" src={menuOne} alt="" />
                    </div>
                  </div>
                </li>
                <ProductTab />
                <li>
                  <a href="#">Info</a>
                  <div className="sub-menu-inn">
                    <div className="w-1/2">
                      <h3>Info</h3>
                      <ul className="sub-menu">
                        <li>
                          <a className="#" href="https://boundri.com/faq/">
                            FAQ
                          </a>
                        </li>
                        <li>
                          <a className="#" href="https://boundri.com/about-us/">
                            About us
                          </a>
                        </li>
                        <li>
                          <a className="#">Reviews</a>
                        </li>
                        <li>
                          <a className="#">Report issues</a>
                        </li>
                        <li>
                          <a className="#">Custom Requests</a>
                        </li>
                        <li>
                          <a
                            className="#"
                            href="https://boundri.com/basic-rules/"
                          >
                            Basic Rules
                          </a>
                        </li>
                        <li>
                          <a
                            className="#"
                            href="https://boundri.com/shipping-policy/"
                          >
                            Shipping Policy
                          </a>
                        </li>
                        <li>
                          <a
                            className="#"
                            href="https://boundri.com/refund_returns/"
                          >
                            Return Policy
                          </a>
                        </li>
                        <li>
                          <a
                            className="#"
                            href="https://boundri.com/privacy-policy/"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            className="#"
                            href="https://boundri.com/terms-and-conditions/"
                          >
                            Terms and Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            className="#"
                            href="https://boundri.com/contact-us/"
                          >
                            Contact us
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="w-1/2">
                      <img width="180" height="240" src={menuThree} alt="" />
                    </div>
                  </div>
                </li>
                <li>
                  <button
                    className="neeto-ui-btn neeto-ui-btn--style-primary neeto-ui-btn--size-medium header-login-btn"
                    type="button"
                  >
                    Sign in
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div className="h-btns w-1/3">
            <div className="mob-menu-toggle">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>

            <div className="flex items-center justify-end">
              <a className="header-cart" onClick={handleCartClick}>
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.331 21H6.67a2.997 2.997 0 01-2.951-2.463L2.017 9.18A1 1 0 013 8.001h8.28l1.772-5.317a1.002 1.002 0 011.265-.635 1 1 0 01.631 1.268l-2 6A.999.999 0 0112 10H4.198l1.487 8.179a1 1 0 00.984.82h10.662a1 1 0 00.985-.82L19.802 10H16a1 1 0 010-2h5a1 1 0 01.983 1.18l-1.7 9.357A2.997 2.997 0 0117.33 21z"
                    fill="#E4E5E6"
                  ></path>
                </svg>
              </a>
              {isLoggedIn ? (
                <Dropdown
                  className="border-none shadow-2xl"
                  label={<UserSvg />}
                  buttonProps={{
                    className: "p-2 shadow-none rounded-none bg-none",
                    style: "link",
                  }}
                  icon={DownSvg}
                >
                  <Menu>
                    <MenuItem.Button to={MY_MAPS_PATH}>My maps</MenuItem.Button>
                    <MenuItem.Button to={MY_ORDERS_PATH}>
                      My Orders
                    </MenuItem.Button>
                    <MenuItem.Button to={MY_DRAFT_PATH}>
                      Draft products
                    </MenuItem.Button>
                    <MenuItem.Button to={PERSONAL_DETAILS}>
                      Personal details
                    </MenuItem.Button>
                    <MenuItem.Button to={GALLERY}>My Library</MenuItem.Button>
                    {is_admin && (
                      <MenuItem.Button onClick={handleAdminClick} to>
                        Admin Panel
                      </MenuItem.Button>
                    )}
                    <MenuItem.Button onClick={handleLogout} to>
                      Logout
                    </MenuItem.Button>
                  </Menu>
                </Dropdown>
              ) : (
                <Button
                  label="Sign in"
                  to={LOGIN_PATH}
                  className="rounded-full"
                />
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
