import React from "react";

const ProductList = ({ lineItems }) => (
  <div className="px-5">
    <div className="mb-4 text-base font-bold">Product list</div>
    {!!lineItems?.length &&
      lineItems.map(
        ({
          id,
          product: {
            images,
            title,

            active_variant: { price, title: size },
          },
          quantity,
        }) => (
          <div key={id}>
            <div className="mb-3 text-sm font-medium">{title}</div>
            <div className="flex">
              <img src={images[0].src} className="w-44" />
              <div className="flex flex-col justify-center px-3">
                <div className="mb-2 text-xs font-semibold text-gray-500">
                  {size}
                </div>
                <div className="mb-2 text-xs font-semibold text-gray-500">
                  {`Production cost: $${
                    price * quantity
                  } ($${price} x ${quantity})`}
                </div>
              </div>
            </div>
          </div>
        )
      )}
  </div>
);

export default ProductList;
