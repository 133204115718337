import axios from "axios";

const BASE_URL = "/api/v1/custom_request";

const create = ({ firstName, lastName, email, phone }) =>
  axios.post(BASE_URL, {
    contact: {
      firstName,
      lastName,
      email,
      phone,
    },
  });

const contactApi = { create };

export default contactApi;
