import React, { useState, useEffect } from "react";

import { Typography, Input, Button, Alert } from "neetoui";
import { useHistory } from "react-router-dom";

import mapsApi from "apis/maps";
import { PAGE_LIMIT } from "common/constants";
import Header from "components/Common/Header";
import { ADD_MAP_PATH } from "components/routeConstants";
import { useMapDispatch } from "contexts/map";
import { useProductDispatch } from "contexts/product";
import useDebounce from "hooks/useDebounce";
import Search from "images/search.svg";

import MapList from "./MapList";

const MyMaps = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [mapList, setMapList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm);
  const dispatch = useProductDispatch();
  const mapDispatch = useMapDispatch();
  const history = useHistory();

  const getmaps = async () => {
    const {
      data: { maps, total_count },
    } = await mapsApi.fetch({
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: searchTerm,
    });
    setMapList(maps);
    setIsLoading(false);
    setTotalCount(total_count);
  };

  useEffect(() => {
    getmaps();
  }, [debouncedSearchTerm, pageNumber]);

  const onDeleteClick = async id => {
    setSelectedProduct([id]);
    setOpen(true);
  };

  const onMultiDeleteClick = async () => {
    const ids = selected.map(map => map.id);
    setSelectedProduct(ids);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await mapsApi.destroy({ ids: selectedProduct });
      getmaps();
      setIsDeleting(false);
      setOpen(false);
    } catch (err) {
      logger.error(err);
    }
  };

  const onEdit = row => {
    const {
      id: mapId,
      name,
      map_details: { center, zoom, catalogueId, width },
    } = row;

    dispatch({
      type: "SET_PRODUCT_CANVAS_OBJECT",
      payload: {
        catalogId: catalogueId,
      },
    });

    mapDispatch({
      type: "SET_MAP_DETAILS",
      payload: {
        center,
        zoom,
        mapId,
        mapName: name,
        canvasWidth: width,
      },
    });
    history.push(`${ADD_MAP_PATH}/${catalogueId}`);
  };

  return (
    <>
      <div
        className="my-accounts h-full w-full"
        style={{ background: "#fdfdfe" }}
      >
        <Header />
        <div className="px-36">
          <div className="container mx-auto">
            <div className="mb-8 flex items-center justify-between pt-10">
              <Typography style="h2">My Maps</Typography>
            </div>
            <div className="mb-8 w-full">
              <Input
                size="large"
                className="m-auto px-14"
                prefix={<Search className="h-6 w-6" />}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="Search"
              />
            </div>
            <div className="mb-8 flex justify-end gap-4">
              <Button
                label="Delete selected"
                className="rounded-full"
                style="secondary"
                onClick={onMultiDeleteClick}
              />
            </div>
            <MapList
              selected={selected}
              setSelected={setSelected}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              mapList={mapList}
              onDeleteClick={onDeleteClick}
              isLoading={isLoading}
              totalCount={totalCount}
              onEdit={onEdit}
            />
          </div>
        </div>
      </div>
      <Alert
        isOpen={open}
        title="Delete Map"
        message="Are you sure?"
        onClose={() => {
          setOpen(false);
          setSelectedProduct([]);
        }}
        onSubmit={() => handleDelete()}
        isSubmitting={isDeleting}
      />
    </>
  );
};

export default MyMaps;
