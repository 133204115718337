import React, { useState, useEffect, useMemo } from "react";

import { Spinner } from "neetoui";
import { useHistory, useParams } from "react-router-dom";

import lineItemsApi from "apis/lineItems";
import ordersApi from "apis/orders";
import orderApi from "apis/orders";
import productApi from "apis/printify/product";
import { getSubTotal } from "common/helpers";
import Footer from "components/Common/Footer";
import Header from "components/Common/Header";
import SubHeader from "components/Common/SubHeader";
import { PRODUCT_CART_PATH } from "components/routeConstants";

import OrderSummary from "./OrderSummary";
import ProductList from "./ProductList";
import PromoCode from "./PromoCode";
import ShippingMethod from "./ShippingMethod";

const ProductReview = () => {
  const history = useHistory();
  const { id } = useParams();

  const [order, setOrder] = useState();
  const [shippingMethod, setShippingMethod] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(null);
  const [isLoadingPromoCode, setIsLoadingPromoCode] = useState(false);
  const [productId, setProductId] = useState(null);

  const updateTag = async () => await productApi.addTag();

  useEffect(() => {
    updateTag();
  }, []);

  const subTotal = useMemo(() => {
    let subTotal = 0;
    if (order) {
      const lineItems = order;
      subTotal = getSubTotal(lineItems);
    }

    return subTotal;
  }, [order]);

  const getOrderDetails = async () => {
    const { data } = id
      ? await orderApi.fetchById(id)
      : await lineItemsApi.fetch();
    setOrder(id ? data.line_items : data);
    setIsLoading(false);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const redirectToCart = () => history.push(PRODUCT_CART_PATH);

  const onPromoCodeChange = e => setPromoCode(e.target.value);

  const applyPromoCode = async () => {
    try {
      setIsLoadingPromoCode(true);
      const productIds = order?.map(
        item => item.product.active_variant.product_id
      );
      const {
        data: { discount = null, product_id: productId = null },
      } = await ordersApi.applyPromoCode({
        code: promoCode,
        productIds,
      });
      setDiscount(discount);
      setProductId(productId);
    } finally {
      setIsLoadingPromoCode(false);
    }
  };

  return (
    <div className="h-screen w-full">
      <Header />
      <div className="cart product-review">
        <div className="px-36">
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="container mx-auto">
              <SubHeader
                title="Product summary"
                showButton
                redirectToCart={redirectToCart}
              />
              <div className="mx-3 flex justify-center py-10">
                <div className="w-2/4 px-5">
                  <ShippingMethod
                    shippingMethod={shippingMethod}
                    setShippingMethod={setShippingMethod}
                  />
                  <ProductList lineItems={order} />
                </div>
                <div className="w-2/5 px-5">
                  <OrderSummary
                    subtotal={subTotal}
                    orderId={id}
                    discount={discount?.coupon}
                    couponCode={discount?.id}
                    productId={productId}
                    order={order}
                  />
                  <PromoCode
                    onPromoCodeChange={onPromoCodeChange}
                    promoCode={promoCode}
                    applyPromoCode={applyPromoCode}
                    isLoadingPromoCode={isLoadingPromoCode}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProductReview;
