import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1";
const ORDERS_URL = `${BASE_URL}/orders`;

const fetch = params => axios.get(`${ORDERS_URL}?${qs.stringify(params)}`);

const create = payload => axios.post(ORDERS_URL, { order: payload });

const fetchById = id => axios.get(`${ORDERS_URL}/${id}`);

const update = (id, payload) =>
  axios.patch(`${ORDERS_URL}/${id}`, { order: payload });

const orderSuccess = payload =>
  axios.post(`${ORDERS_URL}/order_success`, payload);

const orderFailed = payload =>
  axios.post(`${ORDERS_URL}/order_failed`, payload);

const deleteOrder = id => axios.delete(`${ORDERS_URL}/${id}`);

const applyPromoCode = ({ code, productIds }) =>
  axios.post(`${ORDERS_URL}/apply_promo_code`, {
    promo_code: { code, product_ids: productIds },
  });

const createPendingOrder = () =>
  axios.post(`${ORDERS_URL}/create_pending_order`);

const orderApi = {
  fetch,
  create,
  fetchById,
  update,
  orderSuccess,
  orderFailed,
  deleteOrder,
  applyPromoCode,
  createPendingOrder,
};

export default orderApi;
