import React from "react";

import Header from "components/Common/Header";

import ComingSoon from "./ComingSoon";
import HowItWorks from "./HowItWorks";
import MainSection from "./MainSection";
import Reviews from "./Reviews";

const Home = () => (
  <div className="landing-page h-screen w-full">
    <Header />
    <div className="container mx-auto">
      <MainSection />
      <Reviews />
      <HowItWorks />
      <ComingSoon />
    </div>
  </div>
);

export default Home;
