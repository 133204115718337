import React from "react";

import { Button } from "neetoui";

import Carousel from "components/Common/Carousel";
import { CATALOGUE_PATH } from "components/routeConstants";
import category1 from "images/category1.png";
import category2 from "images/category2.png";
import category3 from "images/category3.png";
import main2 from "images/main2.png";
import main3 from "images/main3.png";
import main4 from "images/main4.png";
import main5 from "images/main5.png";
import main6 from "images/main6.png";
import main7 from "images/main7.png";
import main8 from "images/main8.png";
import main9 from "images/main9.png";
import product1 from "images/product1.png";
import product2 from "images/product2.png";
import product3 from "images/product3.png";
import product4 from "images/product4.png";

import InvertedText from "./InvertedText";

const Product = ({ src, title, price, size }) => (
  <div>
    <img src={src} />
    <div>
      <div className="my-1 text-xs">{title}</div>
      <div className="mb-1 text-sm">
        from <strong>USD {price}</strong>
      </div>
      <div className="mb-1 text-xs text-gray-300">{size} sizes</div>
    </div>
  </div>
);

const MainSection = () => (
  <div className="map-product-info mt-10 px-16 pb-5">
    <div className="flex">
      <div className="my-40 flex w-1/3">
        <InvertedText title="Brings maps to your living" />
        <div className="ml-28">
          <div className="mb-4 text-6xl font-bold">
            Map it.
            <br /> Print it.
          </div>
          <div className="mb-4 text-gray-500">
            Boundri is the premier custom map based product printing tool on the
            market. From wall maps to rugs and much more. Click the button to
            get on the waiting list.
          </div>
          <div>
            <Button label="Order now" className="mr-5 rounded-full" />
            <Button
              label="Contact us"
              style="secondary"
              className="rounded-full"
            />
          </div>
        </div>
      </div>
      <div className="ml-6 w-2/3">
        <Carousel
          images={[
            { src: main2 },
            { src: main3 },
            { src: main4 },
            { src: main5 },
            { src: main6 },
            { src: main7 },
            { src: main8 },
            { src: main9 },
          ]}
          width={400}
          height={360}
          showButton={false}
          showDot={true}
          isPlaying
        />
      </div>
    </div>
    <div className="my-6 mx-44 grid grid-cols-3 gap-5">
      <div className="relative">
        <img src={category1} />
        <Button
          style="secondary"
          label="14 Options"
          className="absolute bottom-5 left-3 rounded-full"
        />
      </div>
      <div className="relative">
        <img src={category2} />
        <Button
          style="secondary"
          label="Premade or custom"
          className="absolute bottom-5 left-3 rounded-full"
        />
      </div>
      <div className="relative">
        <img src={category3} />
        <Button
          style="secondary"
          label="Premade or custom"
          className="absolute bottom-5 left-3 rounded-full"
        />
      </div>
    </div>
    <div className="my-10 mx-44">
      <div className="mb-10 flex justify-between">
        <div className="text-2xl font-semibold">Our product</div>
        <Button
          label="Browse Catalog"
          to={CATALOGUE_PATH}
          className="rounded-full"
        />
      </div>
      <div className="grid grid-cols-4 gap-6">
        <Product
          src={product1}
          title="Horse Track Play Rug"
          price="10"
          size={4}
        />
        <Product
          src={product2}
          title="Custom Field Image Ceramic Mug 11oz"
          price="10"
          size={4}
        />
        <Product
          src={product3}
          title="Field Image Broadcloth Pillow"
          price="10"
          size={4}
        />
        <Product
          src={product4}
          title="Unique Farm Area Crushed Velvet Blanket"
          price="10"
          size={4}
        />
      </div>
    </div>
  </div>
);

export default MainSection;
