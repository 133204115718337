import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1/public";
const PRODUCTS_URL = `${BASE_URL}/products`;

const fetch = params => axios.get(`${PRODUCTS_URL}?${qs.stringify(params)}`);
const fetchById = id => axios.get(`${PRODUCTS_URL}/${id}`);
const destroy = payload => axios.post(`${PRODUCTS_URL}/bulk_delete`, payload);

const productsApi = {
  fetch,
  fetchById,
  destroy,
};

export default productsApi;
