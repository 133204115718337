const CONFIG = {
  longitude: -91.874,
  latitude: 42.76,
  defaultZoom: 2,
  LngLatBounds: [
    [-124.736342, 24.521208],
    [-66.945392, 49.382808],
  ],
  styleUrl: "mapbox://styles/boundridevaccount/cldm47oji000w01my3fbu96d7",
};

const COORDINATE_REGEX =
  /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i;

const DRAW_STYLES = [
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-point-point-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-opacity": 1,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-point-stroke-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#000",
    },
  },
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#404040",
      "fill-outline-color": "#404040",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-static",
    type: "circle",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#404040",
    },
  },
];

const INITIAL_FIELD_DETAILS = {
  featureCollection: null,
  area: 0,
  details: null,
};

const INITIAL_MAP_DETAILS = {
  canvasWidth: null,
  mapId: null,
  center: null,
  zoom: null,
  fromProductDesign: null,
  attachmentId: null,
  canvasHeight: null,
  bearing: null,
};

const CANVAS = { LENGTH: 400, HEIGHT: 550 };

const TAB_LIST = [
  { id: 1, label: "Catalog", path: `${process.env.APP_URL}/catalog` },
  {
    id: 2,
    label: "Contact Us",
    path: `${process.env.HOME_URL}/contact-us/`,
  },
  {
    id: 3,
    label: "Custom Request",
    path: `${process.env.HOME_URL}/custom-requests/`,
  },
];

const PAGE_LIMIT = 9;

const LAYERS = {
  naip: ["naip-layer"],
  naip2: ["naip2-layer"],
  plss: ["sections_label", "townships_label", "townships", "sections"],
  showRoads: [
    "tunnel-minor-case",
    "tunnel-street-case",
    "tunnel-minor-link-case",
    "tunnel-secondary-tertiary-case",
    "tunnel-primary-case",
    "tunnel-major-link-case",
    "tunnel-motorway-trunk-case",
    "tunnel-minor",
    "tunnel-minor-link",
    "tunnel-major-link",
    "tunnel-street",
    "tunnel-street-low",
    "tunnel-secondary-tertiary",
    "tunnel-primary",
    "tunnel-motorway-trunk",
    "road-minor-case",
    "road-street-case",
    "road-minor-link-case",
    "road-secondary-tertiary-case",
    "road-primary-case",
    "road-major-link-case",
    "road-motorway-trunk-case",
    "road-minor",
    "road-minor-link",
    "road-major-link",
    "road-street",
    "road-street-low",
    "road-secondary-tertiary",
    "road-primary",
    "road-motorway-trunk",
    "bridge-minor-case",
    "bridge-street-case",
    "bridge-minor-link-case",
    "bridge-secondary-tertiary-case",
    "bridge-primary-case",
    "bridge-major-link-case",
    "bridge-motorway-trunk-case",
    "bridge-minor",
    "bridge-minor-link",
    "bridge-major-link",
    "bridge-street",
    "bridge-street-low",
    "bridge-secondary-tertiary",
    "bridge-primary",
    "bridge-motorway-trunk",
    "bridge-major-link-2-case",
    "bridge-motorway-trunk-2-case",
    "bridge-major-link-2",
    "bridge-motorway-trunk-2",
    "road-label",
    "road-intersection",
    "road-number-shield",
    "road-exit-shield",
  ],
  showLabels: [
    // poi labels
    "poi-label",
    // place labels
    "settlement-subdivision-label",
    "settlement-minor-label",
    "settlement-major-label",
    "state-label",
    "country-label",
    "county-label",
    "continent-label",
    // natural features
    "waterway-label",
    "natural-line-label",
    "natural-point-label",
    "water-line-label",
    "water-point-label",
  ],
  adminBoundaries: [
    "admin-0-boundary",
    "admin-0-boundary-bg",
    "admin-0-boundary-disputed",
    "admin-1-boundary",
    "admin-1-boundary-bg",
    "admin-2-boundary",
    "admin-2-boundary-bg",
  ],
};

const NUMBER_OF_STATIC_LINES = 4;

const IMAGE = "image";
const TEXT = "text";
const LOGO = "logo";
const LIB = "lib";
const MAP = "map";
const LINE = "line";
const MAP_BOX_LOGO = "map-box-logo";
const ROUND_RUG = "1083";
const JOURNAL = "486";
const GLOSS = "1267";
const RUGS = "1085";
const NUMBER_OF_CURVED_CIRCLE_BORDER = 1;
const NUMBER_OF_PRINT_ON_SIDE_BORDER = 4;

const LIST = [
  {
    title: "Browse",
    links: [
      {
        label: "Online store",
        link: "/",
      },
    ],
  },
  {
    title: "Quick links",
    links: [
      {
        label: "Catalogue",
        to: "/catalog",
      },
      {
        label: "Help",
        to: "/",
      },
      {
        label: "About us",
        to: "/",
      },
      {
        label: "Contact us",
        to: "/",
      },
    ],
  },
  {
    title: "My account",
    links: [
      {
        label: "Personal info",
        to: "/personal-details",
      },
      {
        label: "Address book",
        to: "/personal-details",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        label: "Data protection policy",
        to: "/",
      },
      {
        label: "Privacy Policy",
        to: "/",
      },
      {
        label: "Terms and Conditions",
        to: "/",
      },
      {
        label: "Return Policy",
        to: "/",
      },
      {
        label: "Shipping Policy",
        to: "/",
      },
    ],
  },
  {
    title: "Mapping license",
    links: [
      {
        label: "Mapbox",
        to: "https://www.mapbox.com/",
      },
      {
        label: "Open Street Map",
        to: "https://www.openstreetmap.org/",
      },
    ],
  },
];

export {
  CANVAS,
  CONFIG,
  COORDINATE_REGEX,
  DRAW_STYLES,
  INITIAL_FIELD_DETAILS,
  TAB_LIST,
  PAGE_LIMIT,
  LAYERS,
  INITIAL_MAP_DETAILS,
  NUMBER_OF_STATIC_LINES,
  IMAGE,
  TEXT,
  LOGO,
  LIB,
  MAP,
  LINE,
  MAP_BOX_LOGO,
  ROUND_RUG,
  NUMBER_OF_CURVED_CIRCLE_BORDER,
  NUMBER_OF_PRINT_ON_SIDE_BORDER,
  LIST,
  JOURNAL,
  RUGS,
  GLOSS,
};
