import React from "react";

import { Typography } from "neetoui";

const FieldInfo = ({ name, value }) => (
  <>
    <Typography style="body1">{name}</Typography>
    <Typography style="body1" className="ml-2">
      {value}
    </Typography>
  </>
);

export default FieldInfo;
