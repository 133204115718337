import * as yup from "yup";

export const VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required("Required"),
  description: yup.string(),
});

export const COLUMNS = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 75,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: 150,
  },
];

export const FIELD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  fieldLabel: yup.string().required("Required"),
});
