import axios from "axios";

const BASE_URL = "/api/v1/printify/catalouges";

const fetch = () => axios.get(BASE_URL);
const fetchById = id => axios.get(`${BASE_URL}/${id}`);

const catalogueApi = {
  fetch,
  fetchById,
};

export default catalogueApi;
