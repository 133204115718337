import React, { useState, useEffect } from "react";

import { allCountries } from "country-region-data";
import { Form, Formik } from "formik";
import { Spinner, Button as NormalButton } from "neetoui";
import { Button } from "neetoui/formik";
import { useHistory, useParams } from "react-router-dom";

import addressBooksApi from "apis/addressBook";
import orderApi from "apis/orders";
import Header from "components/Common/Header";
import SubHeader from "components/Common/SubHeader";

import Address from "./Address";
import { INITIAL_VALUES } from "./constant";
import Recipient from "./Recipient";
import { VALIDATION_SCHEMA } from "./validation";

const createPayload = values => ({
  shipping_method: 1,
  send_shipping_notification: false,
  to_address: {
    first_name: values.firstName,
    last_name: values.lastName,
    region: values.region.value,
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    zip: values.zipCode,
    email: values.email,
    phone: values.phone,
    country: values.country.value,
  },
});

const ProductShipping = () => {
  const [initialValue, setInitialValue] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const getOrderDetails = async () => {
    setLoading(true);
    const {
      data: { to_address },
    } = await orderApi.fetchById(id);

    const selectedCountry = allCountries.find(
      item => item[1] === to_address.country
    );
    const values = {
      firstName: to_address.first_name,
      lastName: to_address.last_name,
      email: to_address.email,
      phone: to_address.phone,
      country: {
        label: selectedCountry[0],
        value: selectedCountry[1],
      },
      region: { label: to_address.region, value: to_address.region },
      address1: to_address.address1,
      address2: to_address.address2,
      city: to_address.city,
      zipCode: to_address.zip,
    };
    setInitialValue(values);
    setLoading(false);
  };

  const getPrimaryAddress = async () => {
    try {
      setLoading(true);
      const { data } = await addressBooksApi.getPrimary();
      const selectedCountry = allCountries.find(
        item => item[1] === data.country
      );
      const values = {
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phone: data.phone_number,
        country: {
          label: selectedCountry && selectedCountry[0],
          value: selectedCountry && selectedCountry[1],
        },
        region: { label: data.region, value: data.region },
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        zipCode: data.zip,
      };
      setInitialValue(values);
      setLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getOrderDetails();
    } else {
      getPrimaryAddress();
    }
  }, []);

  const handleSubmit = async values => {
    if (id) {
      await orderApi.update(id, createPayload(values));
      history.push(`/product-review/${id}`);
    } else {
      const {
        data: { id },
      } = await orderApi.create(createPayload(values));
      history.push(`/product-review/${id}`);
    }
  };

  return (
    <div className="h-full w-full">
      <Header />
      <div className="cart product-shipping">
        {loading && (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        )}
        <div className="px-36">
          <div className="container mx-auto">
            <SubHeader title="Shipping address" showButton />
            <div className="my-10">
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={VALIDATION_SCHEMA}
                enableReinitialize
              >
                {({ isSubmitting }) => (
                  <Form className="flex h-full flex-col items-center">
                    <Recipient />
                    <Address />
                    <div className="flex w-2/3 justify-end gap-2">
                      <Button
                        label="Continue"
                        className="rounded-full"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                      />
                      {id && (
                        <NormalButton
                          label="Cancel"
                          style="secondary"
                          className="rounded-full"
                          to={`/product-review/${id}`}
                        />
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductShipping;
