import React from "react";

import {
  CarouselProvider,
  Slider,
  Slide,
  ImageWithZoom,
  Dot,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Carousel = ({ images = [] }) => (
  <div>
    <CarouselProvider
      visibleSlides={1}
      naturalSlideWidth={100}
      naturalSlideHeight={90}
      totalSlides={images.length || 0}
      step={1}
    >
      <Slider>
        {images.map((image, index) => (
          <Slide index={index} key={index}>
            <ImageWithZoom src={image.src} />
          </Slide>
        ))}
      </Slider>
      {images.map((image, index) => (
        <Dot slide={index} key={index}>
          <Image src={image.src} className="h-28 w-28 p-3" />
        </Dot>
      ))}
    </CarouselProvider>
  </div>
);

export default Carousel;
