import React from "react";

import {
  CarouselProvider,
  Slider,
  Slide,
  ImageWithZoom,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import ArrowSvg from "images/arrow-back.svg";

const Carousel = ({
  images = [],
  visibleSlides = 1,
  width = 10,
  height = 20,
  showDot = false,
  showButton = true,
  isPlaying = false,
  ...props
}) => (
  <div className="carousel-container">
    <CarouselProvider
      className="relative"
      visibleSlides={visibleSlides}
      naturalSlideWidth={width}
      naturalSlideHeight={height}
      totalSlides={images.length}
      step={1}
      hasMasterSpinner
      isPlaying={isPlaying}
      {...props}
    >
      <Slider>
        {images.map((image, index) => (
          <Slide index={index} key={index}>
            <ImageWithZoom src={image.src} />
          </Slide>
        ))}
      </Slider>
      {showButton && (
        <>
          <ButtonBack className="left-btn btn absolute top-1/2">
            <ArrowSvg />
          </ButtonBack>
          <ButtonNext className="right-btn btn absolute top-1/2 rotate-180">
            <ArrowSvg />
          </ButtonNext>
        </>
      )}
      {showDot && <DotGroup />}
    </CarouselProvider>
  </div>
);

export default Carousel;
