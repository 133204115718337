import mbxClient from "@mapbox/mapbox-sdk";
import mxStaticClient from "@mapbox/mapbox-sdk/services/static";

const baseClient = mbxClient({
  accessToken: process.env.MAP_BOX_ACCESS_TOKEN,
});
const staticClient = mxStaticClient(baseClient);

export const getStaticImage = async (coordinates, width, height, zoom = 15) => {
  await staticClient
    .getStaticImage({
      ownerId: "bb-akhil",
      styleId: "cl5kx5oau004y14o13o7lfxkp",
      width,
      height,
      position: {
        coordinates,
        zoom,
      },
    })
    .send();
};

export const getHeightWidth = (pageSize, orientation) => {
  let width = pageSize.value[0];
  let height = pageSize.value[1];
  if (orientation.value === "portrait") {
    width = pageSize.value[1];
    height = pageSize.value[0];
  }

  return { width, height };
};
