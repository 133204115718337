import React from "react";

import { LAYERS } from "common/constants";
import { useMapState, useMapDispatch } from "contexts/map";

import CustomSwitch from "./ManageFields/CustomSwitch";

const AdminLayersList = () => {
  const {
    layers: { plss, naip, naip2 },
    additionalLayers: { showRoads, showLabels, adminBoundaries },
    map,
  } = useMapState();
  const mapDispatch = useMapDispatch();

  const onHandleLayersChange = (checked, type) => {
    const value = checked ? "visible" : "none";
    const layers = {
      plss: checked,
      naip,
      naip2,
    };

    switch (type) {
      case "plss":
        LAYERS.plss?.forEach(lyr =>
          map.setLayoutProperty(lyr, "visibility", value)
        );
        break;
    }
    mapDispatch({
      type: "SET_LAYERS",
      payload: layers,
    });
  };

  const handleAdminLayerChange = (checked, type) => {
    const value = checked ? "visible" : "none";
    let layers;
    switch (type) {
      case "showRoads":
        layers = LAYERS.showRoads;
        break;
      case "showLabels":
        layers = LAYERS.showLabels;
        break;
      case "adminBoundaries":
        layers = LAYERS.adminBoundaries;
        break;
    }

    layers?.forEach(lyr => map.setLayoutProperty(lyr, "visibility", value));
    mapDispatch({
      type: "SET_ADMIN_LAYERS",
      payload: { [type]: checked },
    });
  };

  return (
    <>
      <div className="title mb-3">Additional layers</div>
      <div className="mb-3 ml-3">
        <CustomSwitch
          label="PLSS"
          checked={plss}
          onChange={event => onHandleLayersChange(event.target.checked, "plss")}
          tooltipContent="Public Land Survey System"
        />
        <CustomSwitch
          label="Roads"
          checked={showRoads}
          onChange={event =>
            handleAdminLayerChange(event.target.checked, "showRoads")
          }
          tooltipContent="Road names and descriptions"
        />
        <CustomSwitch
          label="Labels"
          checked={showLabels}
          onChange={event =>
            handleAdminLayerChange(event.target.checked, "showLabels")
          }
          tooltipContent="Country, State, County, City, and Landmark names"
        />
        <CustomSwitch
          label="Borders"
          checked={adminBoundaries}
          onChange={event =>
            handleAdminLayerChange(event.target.checked, "adminBoundaries")
          }
          tooltipContent="Governmental boundaries"
        />
      </div>
    </>
  );
};

export default AdminLayersList;
