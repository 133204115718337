const RGBA = { r: 0, g: 0, b: 0, a: 1 };
const HSV = {
  h: 0,
  s: 0,
  v: 0,
};
const HSL = {
  h: 0,
  s: 0,
  l: 0,
};

const HEX = "000000";

export { HEX, HSL, HSV, RGBA };
