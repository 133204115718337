import React, { useMemo, useState } from "react";

import { Button } from "neetoui";
import { useHistory } from "react-router-dom";

import checkoutSessionApi from "apis/checkoutSession";
import orderApi from "apis/orders";
import Card from "components/Common/Card";
import { MY_ORDERS_PATH } from "components/routeConstants";
import { getFromLocalStorage } from "utils/storage";

const OrderSummary = ({
  orderId,
  couponCode,
  subtotal,
  discount,
  productId,
  order,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const discountAmount = useMemo(() => {
    if (discount) {
      if (discount.amount_off) {
        return discount.amount_off / 100;
      } else if (discount.percent_off) {
        if (productId?.length > 0) {
          let discountValue = 0;
          order
            .filter(item =>
              productId.includes(item.product.active_variant.product_id)
            )
            .forEach(product => {
              discountValue +=
                product.quantity *
                parseFloat(product.product.active_variant.price) *
                (discount.percent_off / 100);
            });
          return discountValue;
        }

        return (discount.percent_off / 100) * subtotal;
      }
    }

    return 0;
  }, [discount, productId]);

  const onHandleProceedToPayment = async () => {
    try {
      setLoading(true);
      const {
        data: {
          session: { url },
        },
      } = await checkoutSessionApi.create({
        coupon_code: couponCode,
        customer_id: getFromLocalStorage("customerId"),
        order_id: orderId,
      });
      window.location.href = url;
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };

  const createPendingOrder = async () => {
    await orderApi.createPendingOrder();
    history.push(MY_ORDERS_PATH);
  };

  return (
    <Card title="Order summary" className="mb-8">
      <div className="mb-3 flex justify-between">
        <span className="text-xs font-semibold text-gray-500">Subtotal</span>
        <span>${subtotal?.toFixed(2)}</span>
      </div>

      {!!discountAmount && (
        <>
          <div className="mb-3 flex justify-between">
            <span className="text-xs font-semibold text-gray-500">
              Discount
            </span>
            <span>${discountAmount?.toFixed(2)}</span>
          </div>
          <div className="mb-3 flex justify-between">
            <span className="text-base font-normal">Order total</span>
            <span>${(subtotal - discountAmount).toFixed(2)}</span>
          </div>
        </>
      )}
      <div className="flex w-full flex-col items-center">
        <Button
          label="Proceed to payments"
          className="rounded-full"
          onClick={onHandleProceedToPayment}
          loading={loading}
          disabled={loading}
        />
        {!orderId && (
          <div
            className="mt-3 cursor-pointer text-blue-500"
            onClick={createPendingOrder}
          >
            Save for later
          </div>
        )}
      </div>
    </Card>
  );
};

export default OrderSummary;
