import axios from "axios";

const BASE_URL = "/api/v1/printify/print_providers";

const fetch = () => axios.get(BASE_URL);
const fetchById = (id = 5) => axios.get(`${BASE_URL}/${id}`);

const bluePrintApi = {
  fetch,
  fetchById,
};

export default bluePrintApi;
