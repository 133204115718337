import React, { useState } from "react";

import { Modal, Typography, Button } from "neetoui";

import fieldApi from "apis/fields";

const FileUpload = ({ isOpen, setShowModal, refetch }) => {
  const [file, setFile] = useState();

  const uploadFile = async () => {
    try {
      await fieldApi.fieldUpload(file);
      closeModal();
      refetch();
    } catch (err) {
      // console.error(err);
    }
  };

  const closeModal = () => setShowModal(false);
  const onFileChange = e => setFile(e.target.files[0]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header className="mb-2">
        <Typography style="h2">Upload file</Typography>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <input required type="file" accept=".zip" onChange={onFileChange} />
        </div>
      </Modal.Body>
      <Modal.Footer className="space-x-2">
        <Button
          label="Save"
          iconPosition="right"
          style="primary"
          onClick={uploadFile}
          disabled={!file}
        />
        <Button label="Cancel" style="secondary" onClick={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default FileUpload;
