import React from "react";

import PropTypes from "prop-types";

import CloseSvg from "images/small-close.svg";

const Title = ({ title, reset }) => (
  <div className="mb-3 flex justify-between">
    <span className="text-base font-medium">{title}</span>
    <CloseSvg className="cursor-pointer" onClick={reset} />
  </div>
);

Title.propTypes = {
  title: PropTypes.string,
  reset: PropTypes.func,
};

export default Title;
