import React, { useState } from "react";

import { Formik, Form } from "formik";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";

import profilesApi from "apis/profiles";
import Illustration from "images/illustration.svg";

import { ADDRESS_BOOK_VALIDATION } from "./constants";

const Details = ({ user = { company_name: "" } }) => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async data => {
    try {
      await profilesApi.updateProfile(data);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        ...user,
        firstName: user ? user.first_name : "",
        lastName: user ? user.last_name : "",
        phoneNumber: user ? user.phone_number : "",
      }}
      onSubmit={handleSubmit}
      validationSchema={ADDRESS_BOOK_VALIDATION}
      validateOnBlur={submitted}
      validateOnChange={submitted}
      enableReinitialize
    >
      {({ dirty, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 p-14">
            <div>
              <Input
                name="firstName"
                className="custom-input mb-7"
                label="first name"
              />
              <Input
                name="lastName"
                className="custom-input mb-7"
                label="Last name"
              />
              <Input name="email" className="custom-input mb-7" label="Email" />
              <Input
                name="phoneNumber"
                className="custom-input"
                label="Phone number"
              />
              <div className="mt-12 flex gap-4">
                <Button
                  type="submit"
                  label="Update"
                  className="rounded-full px-9"
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  onClick={() => setSubmitted(true)}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <Illustration />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Details;
