import { useState } from "react";

const useQuantity = ({ setQuantity, quantity }) => {
  const [value, setValue] = useState(quantity);
  const updateQuantity = option => {
    let updatedQuantity = value;
    if (option === "add") {
      updatedQuantity += 1;
    } else if (value > 1) {
      updatedQuantity -= 1;
    }
    setQuantity(updatedQuantity);
    setValue(updatedQuantity);
  };
  return { updateQuantity };
};

export default useQuantity;
