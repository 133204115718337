import React from "react";

import { Typography } from "neetoui";

import Header from "components/Common/Header";
import image2 from "images/about-us-1.jpg";
import Image1 from "images/about-us-2.svg";
import image4 from "images/about-us-4.png";
import image5 from "images/about-us-5.png";
import image from "images/about-us.jpg";

const AboutUs = () => (
  <div className="h-screen w-full">
    <Header />
    <div className="container mx-auto px-36">
      <div className="mb-8 flex items-center justify-between pt-10">
        <Typography style="h2">About us</Typography>
      </div>
      <div className="mx-10 grid grid-cols-2 gap-10">
        <Image1 className="mx-6" />
        <div className="mx-10 flex items-center justify-center">
          Boundri is a unique digital service that creates printed based
          products using pictures from aerial and satellite photos from areas of
          the world that are special to you.
        </div>
        <div className="relative col-span-2">
          <img src={image} alt="" className="z-50 h-3/4 w-full" />
          <div className="absolute top-1/4 left-10 z-50 h-2/5 w-2/4 bg-white p-5">
            <div className="mb-3 font-semibold">Experience With Maps</div>
            <div className="mb-3 text-gray-500">
              It’s founder Nathan Faleide, has been involved in the mapping
              world for over 25 years using various types of imagery to showcase
              how the world is changing specifically in the Agriculture
              Industry.{" "}
            </div>
            <a className="cursor-pointer text-blue-500" href="/contact-us">
              Contact us
            </a>
          </div>
          <img
            src={image2}
            alt=""
            className="absolute w-1/2"
            style={{ zIndex: -1, left: -73, top: 277 }}
          />
        </div>
        <div className="col-start-2 px-4">
          <div className="mb-3 font-semibold">Natural Harmony</div>
          <div className="mb-3 text-gray-500">
            With this background the one this Nathan realized is that while most
            mapping information has been showcased digitally, there has been a
            missing element to showing the beauty and functionality of
            physically printed mapping products. This is where Boundri came to
            be in allowing anyone to easily find a area of the world that they
            know or are tied to while being able to physically see and share it.
          </div>
          <a className="cursor-pointer text-blue-500" href="/contact-us">
            Select your product
          </a>
        </div>
        <div className="px-10">
          <div className="mb-3 font-semibold">
            A new way to enjoy a birds eye views
          </div>
          <div className="mb-3 text-gray-500">
            The first flagship product came from Nathan’s love for Agriculture
            and Farm Toys in creating kid play rugs of farmland that their
            parents or relatives actually farm and/or own.
          </div>
          <div className="mb-3 text-gray-500">
            The excitement a kid feels when carpet farming their families land
            while learning how it actually looks like from above is something
            you can see in our clients reviews.
          </div>
          <a className="cursor-pointer text-blue-500" href="/catalog">
            Create your own product
          </a>
        </div>
        <img src={image4} />
        <div className="relative col-span-2 mb-5">
          <img src={image5} style={{ zIndex: -1 }} className="mb-10" />
          <div className="absolute px-40" style={{ bottom: -5 }}>
            <div className="mb-3 font-semibold">The Sky Is The Limit!</div>
            <div className="mb-3 text-gray-500">
              Beyond the popular play rugs, Boundri’s plan is to provide many
              other types of printed products from wall maps of a farmers
              fields, special art prints, and even customized map booklets and
              magazines. There really isn’t a limit into what can be printed
              using a map from anywhere in the world.{" "}
            </div>
            <a className="mb-4 cursor-pointer text-blue-500" href="/catalog">
              Browse catalog
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
