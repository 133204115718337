import React from "react";

import { Spinner, Pagination } from "neetoui";

import { PAGE_LIMIT } from "common/constants";
import DeleteSvg from "images/delete-icon.svg";
import NoDataSvg from "images/no-data.svg";

const MyImages = ({
  images,
  onImageClick,
  isLoading,
  handleDelete,
  getData,
  totalCount,
  page,
  setPage,
  isLibrary = false,
}) => (
  <>
    {isLoading ? (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    ) : (
      <div className="h-full p-2">
        {images.length ? (
          <>
            {images.map((image, i) => (
              <div
                key={i}
                className="border-grey-500 m-5 flex h-24 items-center justify-between rounded border bg-white py-4 px-4"
              >
                <div
                  className="align-center flex h-full w-1/5 cursor-pointer pr-3 2xl:w-1/12"
                  onClick={() => {
                    onImageClick({
                      url: image.url,
                      name: image.name,
                      mapId: !isLibrary ? image.id : null,
                      mapDetails: image.map_details,
                      showLoader: isLibrary,
                      isLibrary,
                      libId: isLibrary ? image.id : null,
                    });
                  }}
                >
                  <img
                    className="overflow-hidden object-contain"
                    src={image?.url}
                    crossOrigin="anonymous"
                  />
                </div>
                <div className="w-4/6 text-xs font-normal text-gray-600">
                  <span>{image?.name}</span>
                  <br />
                  <span>
                    {image?.height || image.map_details?.height} x{" "}
                    {image?.width || image.map_details?.width}
                  </span>
                </div>
                <div
                  onClick={() => handleDelete(image.id)}
                  className="flex cursor-pointer items-center justify-center"
                  title="Delete"
                >
                  <DeleteSvg />
                </div>
              </div>
            ))}
            <div className="flex justify-center">
              <Pagination
                className="pagination-component pb-5"
                count={totalCount}
                navigate={value => {
                  setPage(value);
                  getData(value);
                }}
                pageNo={page}
                pageSize={PAGE_LIMIT}
              />
            </div>
          </>
        ) : (
          <div className="flex h-full flex-col items-center justify-center">
            <div className="w-1/3">
              <NoDataSvg />
            </div>
            <br />
            <div>No Images</div>
          </div>
        )}
      </div>
    )}
  </>
);

export default MyImages;
