import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Button, Toastr } from "neetoui";

import fieldsApi from "apis/fields";
import groupsApi from "apis/groups";
import landCategoryApi from "apis/landCategory";
import regionsApi from "apis/regions";
import FileUpload from "components/Common/FileUpload";
import useMaps from "hooks/useMaps";

import BoundingBox from "./BoundingBox";
import { PAGE_SIZE, PAGE_ORIENTATION } from "./constants";
import FieldList from "./Fields/FieldList";
import FieldModal from "./Fields/FieldModal";
import { getStaticImage, getHeightWidth } from "./helper";
import JobStatus from "./JobStatus";
import ScreenShotOption from "./ScreenShotOption";

const Map = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPane, setShowPane] = useState(false);
  const [fields, setFields] = useState([]);
  const [field, setField] = useState(null);
  const [inProgressJobs, setInProgressJobs] = useState([]);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [landCategories, setLandCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [regions, setRegions] = useState([]);
  const [showBoundingBox, setShowBoundingBox] = useState(false);
  const [pageSize, setPageSize] = useState({
    label: "B4",
    value: PAGE_SIZE.B4,
  });
  const [orientation, setOrientation] = useState(PAGE_ORIENTATION[0]);

  const {
    mapContainer,
    fieldDetails: { featureCollection, details, area },
    resetFeatures,
    getCenterCoordinates,
    getZoomLevel,
    setFieldList,
    map,
  } = useMaps();

  useEffect(() => {
    setShowStatus(!!inProgressJobs.length);
  }, [inProgressJobs]);

  useEffect(() => {
    if (featureCollection) setShowModal(true);
  }, [featureCollection]);

  const getFieldList = async () => {
    try {
      const { data } = await fieldsApi.fetch();
      setFields(data);
      setFieldList(data);
    } catch (err) {
      Toastr.error(err);
    }
  };

  const getJobStatus = async () => {
    try {
      const {
        data: { jobs },
      } = await fieldsApi.jobStatus();
      setInProgressJobs(jobs.filter(job => job.status === "active"));
    } catch (err) {
      logger.error(err);
    }
  };

  const loadLandCategories = async () => {
    try {
      const {
        data: { land_categories },
      } = await landCategoryApi.fetch();
      const formattedValues = land_categories.map(category => ({
        value: category.id,
        label: category.name,
        color: category.color,
      }));
      setLandCategories(formattedValues);
    } catch (err) {
      logger.error(err);
    }
  };

  const loadGroupsAndRegions = async () => {
    const { data: groups } = await groupsApi.fetch();
    const { data: regions } = await regionsApi.fetch();
    const formattedRegions = regions.map(region => ({
      value: region.id,
      label: region.title,
    }));
    const formattedGroups = groups.map(group => ({
      value: group.id,
      label: group.title,
    }));
    setGroups(formattedGroups);
    setRegions(formattedRegions);
  };

  useEffect(() => {
    getFieldList();
    getJobStatus();
    loadLandCategories();
    loadGroupsAndRegions();
  }, []);

  const handleEdit = ({
    field_name: fieldName,
    field_shape: shape,
    field_area: area,
    land_category: { id: landCategoryId },
    group_id: groupId,
    region_id: regionId,
    crop_year: cropYear,
    harvest_date: harvestDate,
    planting_date: plantingDate,
    ...rest
  }) => {
    setField({
      fieldName,
      shape,
      area,
      landCategoryId,
      groupId,
      regionId,
      cropYear: dayjs(cropYear),
      harvestDate: dayjs(harvestDate),
      plantingDate: dayjs(plantingDate),
      ...rest,
    });
    setShowModal(true);
    setShowPane(false);
  };

  const generateStaticImage = () => {
    const { width, height } = getHeightWidth(pageSize, orientation);
    const coordinates = getCenterCoordinates();
    const zoom = getZoomLevel();
    getStaticImage(coordinates, width, height, zoom);
  };

  const removeFieldFromMap = field_name => {
    map.removeLayer(field_name);
    map.removeLayer(`${field_name}-outline`);
    map.removeSource(field_name);
  };

  return (
    <div className="w-full">
      <div ref={mapContainer} className="h-full w-full" />
      {showBoundingBox && (
        <BoundingBox pageSize={pageSize} orientation={orientation} />
      )}
      {showModal && (
        <FieldModal
          initialValues={field}
          details={{ ...details, area: area || 0, featureCollection }}
          resetFeatures={resetFeatures}
          setShowModal={setShowModal}
          setField={setField}
          reloadFields={getFieldList}
          landCategories={landCategories}
          regions={regions}
          groups={groups}
        />
      )}
      <div className="fixed top-14 right-3 flex flex-col items-end">
        <Button
          className="m-1 w-24 rounded"
          label="Show Fields"
          onClick={() => setShowPane(true)}
        />
        <Button
          className="m-1 w-24 rounded"
          label="Upload file"
          onClick={() => setShowFileUploadModal(true)}
        />
        {showBoundingBox ? (
          <ScreenShotOption
            generateStaticImage={generateStaticImage}
            handlePageOrientation={option => setOrientation(option)}
            handlePageSize={option => setPageSize(option)}
            cancelScreenShot={() => setShowBoundingBox(false)}
          />
        ) : (
          <Button
            className="m-1 rounded"
            label="Take Screenshot"
            onClick={() => setShowBoundingBox(true)}
          />
        )}
      </div>
      <FileUpload
        isOpen={showFileUploadModal}
        setShowModal={setShowFileUploadModal}
        refetch={getFieldList}
      />
      {showStatus && <JobStatus />}
      <FieldList
        showPane={showPane}
        setShowPane={setShowPane}
        fields={fields}
        handleEdit={handleEdit}
        getFieldList={getFieldList}
        removeFieldFromMap={removeFieldFromMap}
      />
    </div>
  );
};
export default Map;
