import axios from "axios";
import qs from "qs";

const BASE_URL = `/api/v1/printify/shops/${process.env.SHOP_ID}/products`;

const fetch = params => axios.get(`${BASE_URL}?${qs.stringify(params)}`);

const destroy = payload => axios.post(`${BASE_URL}/bulk_delete`, payload);

const destroyPreview = () => axios.post(`${BASE_URL}/delete_preview`);

const fetchById = id => axios.get(`${BASE_URL}/${id}`);

const updateStatus = payload =>
  axios.post(`${BASE_URL}/update_status`, payload);

const addTag = () => axios.get(`${BASE_URL}/add_product_summary_tag`);

const create = formData =>
  axios.post(BASE_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const update = (id, payload) =>
  axios.put(`${BASE_URL}/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
const productApi = {
  create,
  fetch,
  destroy,
  destroyPreview,
  fetchById,
  updateStatus,
  update,
  addTag,
};

export default productApi;
