import React, { useState } from "react";

import { Formik, Form } from "formik";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";

import profilesApi from "apis/profiles";
import Illustration2 from "images/illustration2.svg";

import {
  CHANGE_PASSWORD_FORM_VALIDATION_SCHEMA,
  CHANGE_PASSWORD_FORM_INITIAL_VALUES,
  CHANGE_PASSWORD_FORM_INPUT_ATTRIBUTES,
} from "./constants";

const ChangePassword = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (data, { resetForm }) => {
    try {
      await profilesApi.updatePassword(data);
      resetForm();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Formik
      initialValues={CHANGE_PASSWORD_FORM_INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={CHANGE_PASSWORD_FORM_VALIDATION_SCHEMA}
      validateOnBlur={submitted}
      validateOnChange={submitted}
    >
      {({ dirty, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 p-14">
            <div>
              <div className="mb-3.5 text-base font-semibold">
                Current password
              </div>
              <div className="mb-3.5 text-xs text-gray-600">
                Needed for verificaiton purpose
              </div>
              <Input
                {...CHANGE_PASSWORD_FORM_INPUT_ATTRIBUTES}
                className="custom-input mb-7"
                label="Enter password"
                name="currentPassword"
              />
              <div className="mb-3.5 text-base font-semibold">New password</div>
              <div className="mb-3.5 text-xs text-gray-600">
                Make sure to create a strong password
              </div>
              <Input
                {...CHANGE_PASSWORD_FORM_INPUT_ATTRIBUTES}
                className="custom-input mb-7"
                label="New password"
                name="password"
              />
              <Input
                {...CHANGE_PASSWORD_FORM_INPUT_ATTRIBUTES}
                className="custom-input mb-7"
                label="Repeat new password"
                name="passwordConfirmation"
              />
              <div className="col-span-2 mb-12 mt-3.5 flex gap-4">
                <Button
                  type="submit"
                  label="Update"
                  className="rounded-full px-9"
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                  onClick={() => setSubmitted(true)}
                />
              </div>
            </div>
            <Illustration2 />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
