import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Typography, Spinner, Button } from "neetoui";
import { useParams } from "react-router-dom";

import orderApi from "apis/orders";
import Footer from "components/Common/Footer";
import Header from "components/Common/Header";

import Row from "./Row";

const Details = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({});
  const { id } = useParams();

  const getOrderDetails = async () => {
    const { data } = await orderApi.fetchById(id);
    setDetails(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <div className="h-screen w-full bg-[#fdfdfe]">
      <Header />
      <div className="my-orders">
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="px-36">
            <div className="mx-auto">
              <div className="mb-8 flex items-center justify-between pt-10">
                <Typography style="h2">Order details</Typography>
              </div>
              <div className="border-grey-600 mb-12 rounded border">
                <div className="border-grey-600 border-b px-7 py-5">
                  <span className="text-base font-bold text-[#202020]">
                    Quick info
                  </span>
                </div>
                <div className="grid grid-cols-2 p-7">
                  <div className="grid w-1/2 grid-cols-2 gap-1 px-5">
                    <Row
                      title="Placed order:"
                      value={dayjs(details?.updated_at).format("YYYY.MM.DD")}
                    />
                    <Row
                      title="Order total:"
                      value={`$${(
                        details?.tax_details.amount_total / 100
                      ).toFixed(2)}`}
                    />
                  </div>
                  <div className="grid w-1/2 grid-cols-2 gap-1">
                    <Row title="Status:" value={details?.status} />
                    <Row title="Order ID:" value={details?.tracking_number} />
                  </div>
                </div>
              </div>
            </div>
            <div className="border-grey-600 mb-12 rounded border">
              <div className="border-grey-600 flex justify-between border-b px-7 py-5">
                <span className="text-base font-bold text-[#202020]">
                  Quick info
                </span>
                <Button
                  className="col-start-2"
                  label="Track order"
                  style="link"
                  href={details?.tracking_url}
                />
              </div>
              <div className="p-7a grid w-full grid-cols-2 gap-y-1">
                {details?.line_items.map(lineItem => (
                  <div
                    className="border-grey-600 flex w-full border-b border-dashed"
                    key={lineItem.id}
                  >
                    <img
                      src={lineItem.product.images[0].src}
                      className="h-44"
                    />
                    <div className="flex flex-col justify-center  ">
                      <div className="mb-3 text-sm font-medium">
                        {lineItem.product.title}
                      </div>

                      <div className="grid grid-cols-2 gap-y-1 gap-x-10 px-3">
                        <Row
                          title="SKU:"
                          value={
                            details?.order_items.find(
                              item =>
                                item.product_id ===
                                lineItem.product.printify_product_id
                            )?.sku
                          }
                        />
                        <Row
                          title="Size:"
                          value={lineItem.product.active_variant.title}
                        />
                        <Row title="Quantity:" value={lineItem.quantity} />
                        <Row
                          title="Price:"
                          value={`$${parseFloat(
                            lineItem.product.active_variant.price
                          ).toFixed(2)}`}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-3 flex flex-col items-end">
                <div className="my-2 grid w-1/3 grid-cols-2 gap-2 px-5">
                  <div className="col-span-2 mb-2 text-sm font-bold">
                    Order summary
                  </div>
                  <Row
                    title="SubTotal"
                    value={`$${(details?.tax_details.sub_total / 100).toFixed(
                      2
                    )}`}
                  />
                  <Row
                    title="Tax and service fee"
                    value={`$${(details?.tax_details.tax_amount / 100).toFixed(
                      2
                    )}`}
                  />
                  {!!details?.discount && (
                    <Row
                      title="discount"
                      value={`$${(details.discount / 100).toFixed(2)}`}
                    />
                  )}

                  <div className="mt-3 text-sm font-bold">Total:</div>
                  <div className="mt-3 text-right text-sm font-bold">
                    ${(details?.tax_details.amount_total / 100).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-7 bg-gray-200 p-7">
                  <div className="flex justify-between">
                    <div className="mb-2.5 text-sm font-medium">
                      Personal details
                    </div>
                  </div>
                  <span className="text-xs text-gray-500">{`${details?.to_address.name}`}</span>
                  <br />
                  <span className="text-xs text-gray-500">
                    {details?.to_address.email}
                  </span>
                  <br />
                  <span className="text-xs text-gray-500">
                    {details?.to_address.phone}
                  </span>
                </div>
                <div className="mb-7 bg-gray-200 p-7">
                  <div className="flex justify-between">
                    <div className="mb-3 mb-2.5 text-sm font-medium">
                      Shipping info
                    </div>
                  </div>
                  <span className="text-xs text-gray-500">
                    {details?.to_address.line1}
                  </span>
                  <br />
                  <span className="text-xs text-gray-500">
                    {`${details?.to_address.city}, ${details?.to_address.state} ${details?.to_address.postal_code}, ${details?.to_address.country}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Details;
