import React, { useEffect, useState, useRef } from "react";

import { JOURNAL } from "common/constants";

import Toolbar from "./ToolBar";

const Content = ({
  canvasEl,
  selectedObject,
  updateTextStyles,
  isLoading,
  bluePrintId,
}) => {
  const [showToolbar, setShowToolbar] = useState(false);

  const parentRef = useRef();

  useEffect(() => {
    if (selectedObject?.text) {
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  }, [selectedObject]);

  useEffect(() => {
    const canvas = canvasEl.current;
    canvas.width = parentRef.current.clientWidth;
    canvas.height = parentRef.current.clientHeight;
  }, []);

  return (
    <>
      <div
        id="canvas-container"
        className="relative flex w-8/12 items-center justify-center"
        ref={parentRef}
      >
        <Toolbar
          showToolbar={showToolbar}
          updateTextStyles={updateTextStyles}
        />
        <canvas ref={canvasEl} id="canvas"></canvas>
        {!isLoading && bluePrintId === JOURNAL && (
          <div
            className="position-bottom-text flex justify-around gap-10 font-bold"
            style={{ width: canvasEl?.current?.fabric?.clipPath?.width || 0 }}
          >
            <div>Back</div>
            <div>Front</div>
          </div>
        )}
      </div>
      <div className="hidden-map">
        <canvas id="scaledCanvas"></canvas>
      </div>
    </>
  );
};

Content.propTypes = {};

export default Content;
