import React from "react";

import { Select } from "neetoui/formik";

import useCountryList from "hooks/useCountryList";

const Address = () => {
  const { countryList } = useCountryList();

  return (
    <Select
      className="custom-select"
      name="country"
      label="Country"
      options={countryList}
      strategy="fixed"
    />
  );
};

export default Address;
