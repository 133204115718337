import React, { useState, useEffect } from "react";

import { Typography, Input, Button, Alert } from "neetoui";
import { useHistory } from "react-router-dom";

import lineItemsApi from "apis/lineItems";
import productsApi from "apis/printify/product";
import { PAGE_LIMIT } from "common/constants";
import Header from "components/Common/Header";
import { PRODUCT_CART_PATH } from "components/routeConstants";
import useDebounce from "hooks/useDebounce";
import Search from "images/search.svg";

import ProductTable from "./ProductsTable";

const MyDraft = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();

  const getProducts = async () => {
    const {
      data: { products, total_count },
    } = await productsApi.fetch({
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: searchTerm,
      status: "draft",
    });
    setProducts(products);
    setTotalCount(total_count);
    setIsLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, [debouncedSearchTerm, pageNumber]);

  const onDeleteClick = async id => {
    setSelectedProduct([id]);
    setOpen(true);
  };

  const onMultiDeleteClick = async () => {
    const ids = selected.map(map => map.id);
    setSelectedProduct(ids);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await productsApi.destroy({ ids: selectedProduct });
      getProducts();
      setIsDeleting(false);
      setOpen(false);
    } catch (err) {
      logger.error(err);
    }
  };

  const addToCart = async id => {
    try {
      const productIds = id ? [id] : selected.map(product => product.id);
      await lineItemsApi.bulkCreate({ product_ids: productIds });
      history.push(PRODUCT_CART_PATH);
    } catch (err) {
      //
    }
  };

  return (
    <>
      <div className="my-accounts h-screen w-full bg-gray-100">
        <Header />
        <div className="px-36">
          <div className="container mx-auto">
            <div className="mb-8 flex items-center justify-between pt-10">
              <Typography style="h2">Product drafts</Typography>
            </div>
            <div className="mb-8 w-full">
              <Input
                size="large"
                className="m-auto px-14"
                prefix={<Search className="h-6 w-6" />}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="Search"
              />
            </div>
            <div className="mb-8 flex justify-end gap-4">
              <Button
                label="Add to cart"
                className="rounded-full"
                onClick={() => addToCart()}
              />
              <Button
                label="Delete selected"
                className="rounded-full"
                style="secondary"
                onClick={onMultiDeleteClick}
              />
            </div>
            <ProductTable
              selected={selected}
              setSelected={setSelected}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              products={products}
              onDeleteClick={onDeleteClick}
              isLoading={isLoading}
              totalCount={totalCount}
              addToCart={addToCart}
            />
          </div>
        </div>
      </div>
      <Alert
        isOpen={open}
        title="Delete product"
        message="Are you sure?"
        onClose={() => {
          setOpen(false);
          setSelectedProduct([]);
        }}
        onSubmit={() => handleDelete()}
        isSubmitting={isDeleting}
      />
    </>
  );
};

export default MyDraft;
