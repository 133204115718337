import axios from "axios";

const BASE_URL = "api/v1";
const FIELD_URL = `${BASE_URL}/fields`;
const JOB_STATUS = `${BASE_URL}/shape_file_parse_jobs`;

const fetch = () => axios.get(FIELD_URL);

const jobStatus = () => axios.get(JOB_STATUS);

const create = ({
  fieldLabel,
  area,
  featureCollection,
  color,
  growerId,
  farmId,
  note,
  landCategory: { value: landCategoryId },
}) =>
  axios.post(FIELD_URL, {
    field_name: fieldLabel,
    field_area: area,
    feature_collection: featureCollection,
    land_category_id: landCategoryId,
    group_id: growerId,
    region_id: farmId,
    color,
    description: note,
  });

const update = (
  id,
  {
    fieldLabel,
    landCategoryId,
    color,
    groupId,
    regionId,
    cropYear,
    harvestDate,
    plantingDate,
    description,
  }
) =>
  axios.put(`${FIELD_URL}/${id}`, {
    field_name: fieldLabel,
    land_category_id: landCategoryId,
    group_id: groupId,
    region_id: regionId,
    color,
    crop_year: cropYear,
    harvest_date: harvestDate,
    planting_date: plantingDate,
    description,
  });

const destroy = payload => axios.post(`${FIELD_URL}/bulk_delete`, payload);

const fieldUpload = file => {
  const formData = new FormData();
  const { name, type } = file;

  formData.append("zip[filename]", name);
  formData.append("zip[content_type]", type);
  formData.append("zip[file]", file);

  return axios.post("api/v1/fields_upload", formData);
};

const fieldsApi = {
  fetch,
  create,
  update,
  destroy,
  fieldUpload,
  jobStatus,
};

export default fieldsApi;
