import React from "react";

import { Button } from "neetoui";

import ProductType from "./CheckBoxList";
import Divider from "./Divider";
import PriceComponent from "./PriceComponent";

const DUMMY_DATA_PRODUCTS = ["Carpet", "Cup", "Poster", "Puzzle"];

const Filters = () => (
  <div className="w-1/3 pl-36">
    <PriceComponent />
    <Divider />
    <ProductType list={DUMMY_DATA_PRODUCTS} />
    <div className="flex justify-center">
      <Button className="reset-button my-3" label="Reset all" />
    </div>
  </div>
);

export default Filters;
