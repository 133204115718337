import React, { useEffect } from "react";

import { Typography, Toastr } from "neetoui";

const JobStatus = () => {
  useEffect(() => () => Toastr.success("File upload was successful"));

  return (
    <div className="fixed bottom-6 right-3 rounded-lg border bg-white p-2 shadow-sm">
      <Typography style="body3">Uploading file . . . </Typography>
    </div>
  );
};

export default JobStatus;
