import * as yup from "yup";

export const FIELD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  field: yup.string().required("Required"),
});

export const INITIAL_FORM_VALUES = {
  color: "#ffffff",
  fieldName: "",
  shape: "",
  crop: "",
  cropYear: null,
  area: "",
  harvestDate: null,
};

export const PAGE_SIZE = {
  A2: [594, 420],
  A3: [420, 297],
  A4: [297, 210],
  A5: [210, 148],
  A6: [148, 105],
  B2: [707, 500],
  B3: [500, 353],
  B4: [353, 250],
  B5: [250, 176],
  B6: [176, 125],
};

export const PAGE_ORIENTATION = [
  {
    label: "Landscape",
    value: "landscape",
  },
  {
    label: "Portrait",
    value: "portrait",
  },
];
