import axios from "axios";

const BASE_URL = "api/v1";
const BLUE_PRINT_URL = `${BASE_URL}/printify/blue_prints/`;

const fetch = ({ bluePrintId, printProviderId }) =>
  axios.get(
    `${BLUE_PRINT_URL}/${bluePrintId}/print_providers/${printProviderId}/shippings`
  );

const shippingApi = {
  fetch,
};

export default shippingApi;
