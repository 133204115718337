import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1/public/password_reset";

const sendMail = params =>
  axios.get(`${BASE_URL}/send_mail?${qs.stringify(params)}`);

const resetMail = payload => axios.post(`${BASE_URL}/reset`, payload);

const passwordApi = {
  sendMail,
  resetMail,
};

export default passwordApi;
