import React from "react";

import { Typography, Button } from "neetoui";

import ArrowSvg from "images/arrow-back.svg";

const SubHeader = ({ title, showButton = false, redirectToCart }) => (
  <div className="flex items-center justify-between pt-10">
    <Typography style="h2">{title}</Typography>
    {showButton && (
      <Button
        label="Go to cart"
        style="text"
        icon={ArrowSvg}
        iconPosition="left"
        iconSize={5}
        onClick={redirectToCart}
      />
    )}
  </div>
);

export default SubHeader;
