import axios from "axios";

const BASE_URL = "api/v1/printify/blue_prints";

const fetch = () => axios.get(BASE_URL);
const fetchById = id => axios.get(`${BASE_URL}/${id}`);

const bluePrintApi = {
  fetch,
  fetchById,
};

export default bluePrintApi;
