import React from "react";

import regionsApi from "apis/regions";
import CreatGroupOrRegion from "components/Common/CreatGroupOrRegion";

import { VALIDATION_SCHEMA } from "./validations";

const AddFarm = ({ onClose }) => {
  const handleSubmit = async payload => {
    try {
      await regionsApi.create(payload);
      onClose();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div className="add-grower-area mt-4 mb-5">
      <div className="title my-3">Add farm</div>
      <CreatGroupOrRegion
        onClose={onClose}
        handleSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
        isRegion
      />
    </div>
  );
};

export default AddFarm;
