const PAYMENT_SUCCEEDED = "Payment_succeeded";
const PAYMENT_PROCESSING = "Your payment is processing.";
const TRY_AGAIN = "Your payment was not successful, please try again.";
const ERROR = "Something went wrong.";
const SUCCEEDED = "succeeded";
const PROCESSING = "processing";
const PAYMENT_METHOD = "requires_payment_method";

export {
  PAYMENT_SUCCEEDED,
  PAYMENT_PROCESSING,
  TRY_AGAIN,
  ERROR,
  SUCCEEDED,
  PROCESSING,
  PAYMENT_METHOD,
};
