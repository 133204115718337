import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import ordersApi from "apis/orders";
import { MY_ORDERS_PATH } from "components/routeConstants";

import {
  PAYMENT_SUCCEEDED,
  PAYMENT_PROCESSING,
  TRY_AGAIN,
  ERROR,
  SUCCEEDED,
  PROCESSING,
  PAYMENT_METHOD,
} from "./constants";

export default function CheckoutForm({ orderId }) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case SUCCEEDED:
          setMessage(PAYMENT_SUCCEEDED);
          break;
        case PROCESSING:
          setMessage(PAYMENT_PROCESSING);
          break;
        case PAYMENT_METHOD:
          setMessage(TRY_AGAIN);
          break;
        default:
          setMessage(ERROR);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async e => {
    try {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          await ordersApi.orderFailed();
          history.push(MY_ORDERS_PATH);
          setMessage("An unexpected error occurred.");
        }
      } else {
        setMessage("Payment success");
        await ordersApi.orderSuccess({ id: orderId });
        history.push(`${MY_ORDERS_PATH}/${orderId}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
