import React, { useMemo } from "react";

import { Table } from "neetoui";

import { PAGE_LIMIT } from "common/constants";

import { makeColumns } from "./tableColumns";

const MapList = ({
  selected,
  setSelected,
  setPageNumber,
  pageNumber,
  mapList,
  onDeleteClick,
  isLoading,
  totalCount,
  onEdit,
}) => {
  const COLUMNS = useMemo(() => makeColumns(onDeleteClick, onEdit), []);

  return (
    <div
      style={{
        height: "calc(100vh - 320px)",
      }}
    >
      <Table
        rowSelection
        fixedHeight
        totalCount={totalCount}
        className="bg-white"
        loading={isLoading}
        columnData={COLUMNS}
        rowData={mapList}
        onRowSelect={(selectedRowKeys, selectedRows) =>
          setSelected(selectedRows)
        }
        defaultPageSize={10}
        selectedRowKeys={selected.map(map => map?.id)}
        currentPageNumber={pageNumber}
        handlePageChange={page => setPageNumber(page)}
        paginationProps={{
          pageSize: PAGE_LIMIT,
        }}
      />
    </div>
  );
};

export default MapList;
