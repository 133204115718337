import axios from "axios";

const BASE_URL = "api/v1";
const LAND_CATEGORIES_URL = `${BASE_URL}/land_categories`;

const fetch = () => axios.get(LAND_CATEGORIES_URL);

const landCategoriesApi = {
  fetch,
};

export default landCategoriesApi;
