import React, { useEffect, useState } from "react";

import { Form, Formik, FieldArray } from "formik";
import { Button, Spinner } from "neetoui";
import { Input, Textarea, Select } from "neetoui/formik";

import catalogueApi from "apis/catalogue";
import categoriesApi from "apis/categories";
import subcategoriesApi from "apis/subcategoriesApi";
import useDebounce from "hooks/useDebounce";
import TickSvg from "images/blackTick.svg";
import DeleteSvg from "images/delete-icon.svg";

import { INITIAL_VALUES } from "./constants";
import { formatDataFn } from "./utils";
import { VALIDATION_SCHEMA } from "./validation";

const AddCatalog = ({ id, callBack, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [printProviders, setPrintProviders] = useState([]);
  const [variants, setVariants] = useState([]);
  const [loadingBluePrint, setLoadingBluePrint] = useState(false);
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [catalogLoaded, setCatalogLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [bluePrints, setBluePrints] = useState([]);
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [bluePrintId, setBluePrintId] = useState("");
  const [selectedImage, setSelectedImage] = useState();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const getCategories = async () => {
    const { data } = await categoriesApi.fetch();
    const formatData = data.map(formatDataFn);
    setCategories(formatData);
  };

  const getBluePrints = async () => {
    const {
      data: { blue_prints },
    } = await catalogueApi.searchBluePrints({
      search: searchTerm,
    });
    setBluePrints(blue_prints);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      getBluePrints();
    }
  }, [debouncedSearchTerm]);

  const fetchVariants = async (printProviderId, id = null) => {
    const {
      data: { variants },
    } = await catalogueApi.fetchVariants({
      id: printProviderId,
      bluePrintId: bluePrintId || id,
    });

    const formatData = variants.map(item => ({
      label: item.title,
      value: item.id,
      variant: item,
    }));

    setVariants(formatData);
  };
  const getCatalogDetailsById = async () => {
    try {
      const { data } = await catalogueApi.fetchById(id);
      if (data) {
        const {
          data: { providers },
        } = await catalogueApi.fetchPrintProviders({
          bluePrintId: data.blue_print_id,
        });
        setBluePrintId(data.blue_print_id);
        setPrintProviders(providers.map(formatDataFn));
        fetchVariants(data.print_providers.id, data.blue_print_id);
        data.category?.id && getSubCategories(data.category?.id || "");
        setInitialValues({
          ...INITIAL_VALUES,
          ...data,
          bluePrintId: data.blue_print_id,
          printProvider: {
            value: data.print_providers.id,
            label: data.print_providers.title,
          },
          category: {
            label: data.category?.name || "",
            value: data.category?.id || "",
          },
          subcategory: {
            label: data.subcategory?.name || "",
            value: data.subcategory?.id || "",
          },
          variants: data.print_providers.variants.map(variant => ({
            variant: { label: variant.title, value: variant.id, variant },
            price: variant.price,
          })),
        });
      }
      setSelectedImage(data.images[0]);
      setCatalogLoaded(true);
      setLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCatalogDetailsById();
    }
    getCategories();
  }, []);

  const getCatalogDetails = async ({ bluePrintId, resetForm }) => {
    setLoadingBluePrint(true);
    const {
      data: { details },
    } = await catalogueApi.fetchCatalogDetails({ bluePrintId });

    const {
      data: { providers },
    } = await catalogueApi.fetchPrintProviders({ bluePrintId });

    setPrintProviders(providers.map(formatDataFn));
    resetForm({
      values: {
        ...initialValues,
        bluePrint: details.title,
        bluePrintId: details.id,
        ...details,
      },
    });
    setSelectedImage(details.images[0]);
    setBluePrintId(details.id);
    setCatalogLoaded(true);
    setLoadingBluePrint(false);
  };

  const getSubCategories = async id => {
    try {
      setLoadingSubcategory(true);
      const { data } = await subcategoriesApi.fetchByCategoryId({
        id,
      });
      const formatData = data.map(formatDataFn);
      setSubcategories(formatData);
    } catch (err) {
      logger.error(err);
    } finally {
      setLoadingSubcategory(false);
    }
  };

  const handleCategoryChange = async (value, setValue) => {
    setValue("category", value);
    setValue("subcategory", null);
    getSubCategories(value.value);
  };

  const handleProviderChange = async (value, setValue, bluePrintId) => {
    setLoadingVariants(true);
    setValue("printProvider", value);
    setValue("variants", [{ variant: null, price: null }]);
    fetchVariants(value.value, bluePrintId);
    setLoadingVariants(false);
  };

  const formatValues = values => {
    let minPrice = values.variants[0].price;
    let printOnSides = false;
    const variants = values.variants.map(item => {
      printOnSides = item.variant.variant.options.depth ?? false;
      const isUpdated = !!(
        initialValues?.variants?.find(
          variant => variant?.variant?.value === item?.variant?.value
        )?.price != item.price
      );
      minPrice = item.price < minPrice ? item.price : minPrice;
      return { ...item.variant.variant, price: item.price, isUpdated };
    });

    return {
      blue_print_id: bluePrintId,
      title: values.title,
      description: values.description,
      model: values.model,
      brand: values.brand,
      images: [
        selectedImage,
        ...values.images.filter(item => item !== selectedImage),
      ],
      print_providers: {
        id: values.printProvider.value,
        title: values.printProvider.label,
        variants,
      },
      subcategory_id: values.subcategory.value,
      category_id: values.category.value,
      min_price: minPrice,
      print_on_side: printOnSides,
    };
  };

  const convertFileToBlob = file => {
    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer = reader.result;
      const blob = new Blob([arrayBuffer], { type: file.type });
      setFile(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const onFileChange = e => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        convertFileToBlob(selectedFile);
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async values => {
    try {
      const formattedValues = formatValues(values);
      const formData = new FormData();
      formData.append("catalog_data", JSON.stringify(formattedValues));
      if (file) formData.append("image_data", file);

      if (id) {
        await catalogueApi.editCatalog(id, formData);
      } else {
        await catalogueApi.createCatalog(formData);
      }
      callBack();
      handleClose();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleInputChange = async (e, setFieldValue) => {
    setFieldValue("bluePrint", e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (
    title,
    blue_print_id,
    setFieldValue,
    resetForm
  ) => {
    setFieldValue("bluePrint", title);
    setFieldValue("blue_print_id", blue_print_id);
    setBluePrints([]);
    getCatalogDetails({
      bluePrintId: blue_print_id,
      resetForm,
    });
  };

  const addAllVariant = (allVariant, setFieldValue) => {
    const list = variants
      .filter(
        variant =>
          !allVariant.find(
            item => item?.variant?.value === variant?.variant?.id
          )
      )
      .map(variant => ({
        variant,
        price: null,
      }));

    setFieldValue(
      "variants",
      [...allVariant, ...list].filter(item => item.variant)
    );
  };

  const deleteImage = (url, images, setFieldValue) => {
    setFieldValue(
      "images",
      images.filter(item => item !== url)
    );
  };

  return (
    <>
      {(loading || loadingBluePrint) && (
        <div className="absolute top-1/2 left-1/2 z-10">
          <Spinner />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ resetForm, values, setFieldValue, isSubmitting }) => (
          <Form className="w-full space-y-2 bg-white p-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="relative">
                <Input
                  label="Blue print "
                  name="bluePrint"
                  placeholder="Enter blue print title"
                  onChange={e => handleInputChange(e, setFieldValue)}
                />
                {!!(searchTerm && bluePrints.length) && (
                  <ul className="absolute z-10 max-h-96 w-full overflow-y-scroll border border-gray-300 bg-white">
                    {bluePrints.map(({ title, blue_print_id }) => (
                      <li
                        className="cursor-pointer p-2 hover:bg-gray-200"
                        key={blue_print_id}
                        onClick={() =>
                          handleOptionClick(
                            title,
                            blue_print_id,
                            setFieldValue,
                            resetForm
                          )
                        }
                      >
                        {title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Input
                  className="col-span-2"
                  label="Blue print ID"
                  name="bluePrintId"
                  placeholder="Enter blue print ID"
                />
                <div className="flex items-end">
                  <Button
                    label="Search"
                    onClick={() =>
                      getCatalogDetails({
                        bluePrintId: values.bluePrintId,
                        resetForm,
                      })
                    }
                    loading={loadingBluePrint}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Input
                required
                label="Title"
                name="title"
                placeholder="Enter title"
                disabled={!catalogLoaded}
              />
              <Input
                label="Brand"
                name="brand"
                placeholder="Enter brand"
                disabled={!catalogLoaded}
              />
              <Input
                label="Model"
                name="model"
                placeholder="Enter model"
                disabled={!catalogLoaded}
              />
              <Select
                required
                label="Category"
                name="category"
                options={categories}
                onChange={value => handleCategoryChange(value, setFieldValue)}
                placeholder="Select category"
                isDisabled={!catalogLoaded}
              />
              <Select
                required
                label="Subcategory"
                name="subcategory"
                options={subcategories}
                isLoading={loadingSubcategory}
                placeholder="Select subcategory"
                isDisabled={!catalogLoaded || !values.category}
                helpText={
                  catalogLoaded &&
                  !values.category &&
                  "please select a category"
                }
              />
              <Select
                required
                className="col-start-1"
                label="Print provider"
                name="printProvider"
                options={printProviders}
                onChange={value =>
                  handleProviderChange(value, setFieldValue, bluePrintId)
                }
                placeholder="Select print provider"
                isDisabled={!catalogLoaded}
              />
              <FieldArray name="variants">
                {({ push, remove }) => (
                  <div className="flex flex-col space-y-3">
                    {values.variants.map((_, index) => (
                      <div key={index} className="grid grid-cols-2 gap-4">
                        <Select
                          required
                          name={`variants.${index}.variant`}
                          label="Variant"
                          options={variants}
                          isLoading={loadingVariants}
                          placeholder="Select variant"
                          isDisabled={!catalogLoaded || !values.printProvider}
                          helpText={
                            catalogLoaded &&
                            !values.printProvider &&
                            "please select a Print provider"
                          }
                        />
                        <div className="flex items-end space-x-4">
                          <Input
                            required
                            label="Price"
                            name={`variants.${index}.price`}
                            placeholder="Enter price"
                            disabled={!catalogLoaded || !values.printProvider}
                            helpText={
                              catalogLoaded &&
                              !values.printProvider &&
                              "please select a Print provider"
                            }
                          />
                          {values.variants.length > 1 && (
                            <div>
                              <Button
                                label="Remove"
                                onClick={() => remove(index)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    <div>
                      <Button
                        label="Add more variants"
                        onClick={() => push({ variant: null, price: null })}
                        disabled={!catalogLoaded || !values.printProvider}
                      />
                      <Button
                        className="ml-2"
                        label="Add all variants"
                        onClick={() =>
                          addAllVariant(values.variants, setFieldValue)
                        }
                        disabled={!catalogLoaded || !values.printProvider}
                      />
                    </div>
                  </div>
                )}
              </FieldArray>
              <Textarea
                required
                className="col-span-2"
                label="Description"
                name="description"
                disabled={!catalogLoaded}
              />
              <div className="space-y-6">
                <input
                  type="file"
                  onChange={onFileChange}
                  disabled={!catalogLoaded}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              {previewUrl && (
                <div
                  className="relative"
                  style={{
                    minHeight: 250,
                    minWidth: 250,
                    background: "#fafafa",
                  }}
                >
                  <img src={previewUrl} />
                </div>
              )}
              {values?.images?.map((image, idx) => (
                <div
                  key={idx}
                  className="relative cursor-pointer"
                  style={{
                    minHeight: 250,
                    minWidth: 250,
                    background: "#fafafa",
                  }}
                  onClick={() => setSelectedImage(image)}
                >
                  <div
                    className="absolute right-1 top-1 z-10 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
                    onClick={e => {
                      e.stopPropagation();
                      deleteImage(image, values?.images, setFieldValue);
                    }}
                  >
                    <DeleteSvg size={20} />
                  </div>
                  {selectedImage === image && (
                    <TickSvg className="absolute left-1 top-1 cursor-pointer" />
                  )}
                  <img src={image} />
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <Button type="submit" label="Submit" loading={isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCatalog;
