import React, { useState, useEffect } from "react";

import { Typography } from "neetoui";

import profilesApi from "apis/profiles";
import Header from "components/Common/Header";

import AddressBook from "./AddressBook";
import ChangePassword from "./ChangePassword";
import Details from "./Details";
import MenuBar from "./MenuBar";

const items = ["Personal details", "Address book", "Change password"];

const PersonalDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState();

  const getUser = async () => {
    const {
      data: { user, business_name },
    } = await profilesApi.getUser();
    setUser({ ...user, business_name });
  };

  useEffect(() => {
    getUser();
  }, []);

  const renderComponent = option => {
    switch (option) {
      case 0:
        return <Details user={user} />;
      case 1:
        return <AddressBook />;
      case 2:
        return <ChangePassword />;
      default:
        return null;
    }
  };

  return (
    <div className="h-screen w-full" style={{ background: "#fdfdfe" }}>
      <Header />
      <div className="personal-details">
        <div className="px-36 pb-5">
          <div className="container mx-auto">
            <Typography className="py-10" style="h2">
              Personal details
            </Typography>
            <div className="grid grid-cols-4 gap-4">
              <MenuBar
                items={items}
                activeIndex={activeTab}
                setActiveIndex={setActiveTab}
              />
              <div className="border-grey-600 col-span-3 rounded border bg-white">
                {renderComponent(activeTab)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
