import React from "react";

const InvertedText = ({ title }) => (
  <div className="flex flex-col items-center">
    <div className="reverse-orientation">{title}</div>
    <div className="line" />
    <div className="circle" />
  </div>
);

export default InvertedText;
