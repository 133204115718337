import React, { useEffect, useState, useMemo } from "react";

import { Button } from "neetoui";

import productsApi from "apis/product";
import Header from "components/Common/Header";

import Carousel from "./Carousel";
import Details from "./Details";
import Quantity from "./Quantity";
import RadioButton from "./RadioButton";

const findOptions = (options, value) =>
  (options || []).find(option => option.type === value);

const ProductDetails = () => {
  const [productDetails, setaProductDetails] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState();
  const [depth, setDepth] = useState();

  const getProductDetails = async () => {
    try {
      const { data } = await productsApi.fetchById(
        "0c1aeb00-5f2c-499a-951c-f158e2b62430"
      );

      setaProductDetails(data);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  const sizeOptions = useMemo(() => {
    const sizes = findOptions(productDetails?.options, "size")?.values || [];
    setSize(sizes[0]?.id);
    return sizes;
  }, [productDetails]);

  const depthOptions = useMemo(() => {
    const depth = findOptions(productDetails?.options, "depth")?.values || [];
    setDepth(depth[0]?.id);
    return depth;
  }, [productDetails]);

  const getPrice = useMemo(() => {
    let price = 0;
    if (size) {
      price = (productDetails?.variants || []).find(
        option => option.options[0] === size
      )?.cost;
    } else {
      price = (productDetails?.variants || []).find(
        option => option.options[0] === depth
      )?.cost;
    }

    return price / 100;
  }, [size, depth]);

  return (
    <div className="product-details h-screen w-full">
      <Header />
      <div className="my-14 grid w-full grid-cols-2 gap-4">
        <div className="pl-36">
          <Carousel images={productDetails?.images} />
        </div>
        <div className="pr-36">
          <Details title={productDetails?.title} price={getPrice} />
          {!!sizeOptions.length && (
            <RadioButton
              value={size}
              title="Size"
              onSelect={setSize}
              options={sizeOptions}
            />
          )}
          {!!depthOptions.length && (
            <RadioButton
              value={depth}
              title="Depth"
              onSelect={setDepth}
              options={depthOptions}
            />
          )}
          <Quantity value={quantity} setQuantity={setQuantity} />
          <div className="flex justify-center">
            <Button label="Add to cart" className="m-2 rounded-full" />
            <Button label="Buy it now" className="m-2 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
