import * as yup from "yup";

export const CHANGE_PASSWORD_FORM_VALIDATION_SCHEMA = yup.object({
  currentPassword: yup.string().required("Current password is required"),
  password: yup.string().required("New password is required"),
  passwordConfirmation: yup
    .string()
    .required("Password confirmation is required")
    .oneOf(
      [yup.ref("password"), null],
      "New password and confirmation password must match"
    ),
});

export const CHANGE_PASSWORD_FORM_INITIAL_VALUES = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
};

export const CHANGE_PASSWORD_FORM_INPUT_ATTRIBUTES = {
  type: "password",
  "aria-required": "true",
  placeholder: "******",
};

export const ADDRESS_BOOK_VALIDATION = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
});
