import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { Pane, Typography } from "neetoui";
import { Input, Textarea, Select, ActionBlock } from "neetoui/formik";

import groupsApi from "apis/groups";

const INITIAL_VALUES = {};

const EditGroupOrRegion = ({
  showPane,
  onClose,
  isRegion = false,
  handleSubmit,
  initialValues = null,
  validationSchema,
}) => {
  const [groups, setGroups] = useState([]);

  const getGroupList = async () => {
    try {
      const { data } = await groupsApi.fetch();
      setGroups(data.map(item => ({ label: item.title, value: item.id })));
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);
  return (
    <Formik
      initialValues={initialValues || INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Pane isOpen={showPane} onClose={onClose}>
          <Pane.Header>
            <Typography style="h2" weight="semibold">
              Edit {isRegion ? "Region" : "Group"}
            </Typography>
          </Pane.Header>
          <Pane.Body>
            <Form
              className="grid w-full grid-cols-1 gap-4"
              onSubmit={handleSubmit}
            >
              <Input required name="title" label="Title" placeholder="Title" />
              <Textarea
                name="description"
                label="Description"
                placeholder="Description"
              />
              {isRegion && (
                <Select
                  name="group"
                  label="Group"
                  placeholder="Select"
                  options={groups}
                />
              )}
            </Form>
          </Pane.Body>
          <Pane.Footer className="flex items-center space-x-2">
            <ActionBlock />
          </Pane.Footer>
        </Pane>
      )}
    </Formik>
  );
};

export default EditGroupOrRegion;
