import React from "react";

import { Typography } from "neetoui";

import Header from "components/Common/Header";

import ImageList from "./ImageList";

const Gallery = () => (
  <div className="my-accounts h-full w-full" style={{ background: "#fdfdfe" }}>
    <Header />
    <div className="px-36">
      <div className="container mx-auto">
        <div className="mb-8 flex items-center justify-between pt-10">
          <Typography style="h2">My Library</Typography>
        </div>
        <ImageList />
      </div>
    </div>
  </div>
);

export default Gallery;
