const getSize = item => {
  const options = item.product.options;
  const variantId = item.variant_id;
  const variants = item.product.variants;
  const sizes = options.find(option => option.type === "size")?.values || [];
  const variant = variants.find(v => v.id === parseInt(variantId));
  return sizes.find(s => variant.options.includes(s.id))?.title || "";
};

const getPrice = item => {
  const variantId = item.variant_id;
  const variants = item.product.variants;
  const variant = variants.find(v => v.id === parseInt(variantId));
  return variant.price / 100;
};

export { getSize, getPrice };
