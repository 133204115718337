import React from "react";

import classNames from "classnames";

const MenuBar = ({ items, activeIndex, setActiveIndex }) => (
  <ul className="vertical-tab">
    {items?.map((item, index) => (
      <li
        key={index}
        className={classNames({ active: activeIndex === index })}
        onClick={() => setActiveIndex(index)}
      >
        {item}
      </li>
    ))}
  </ul>
);

export default MenuBar;
