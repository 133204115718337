import mbxClient from "@mapbox/mapbox-sdk";
import mxStaticClient from "@mapbox/mapbox-sdk/services/static";
import * as turf from "@turf/turf";
import { fabric } from "fabric";
import { v4 as uuid } from "uuid";

import {
  NUMBER_OF_STATIC_LINES,
  NUMBER_OF_CURVED_CIRCLE_BORDER,
  ROUND_RUG,
  LINE,
  IMAGE,
  MAP,
  LIB,
  TEXT,
  JOURNAL,
  RUGS,
  GLOSS,
} from "common/constants";

const OFFSET_VALUE_FOUR = 4;
const OFFSET_VALUE_SIX = 6;

const baseClient = mbxClient({
  accessToken: process.env.MAP_BOX_ACCESS_TOKEN,
});

const staticClient = mxStaticClient(baseClient);

const calculateBleedEdges = (
  showBleedEdges,
  canvasSize,
  top,
  left,
  objects,
  canvas,
  bluePrintId
) => {
  if (objects.length > 0) {
    objects.forEach(item => {
      if (item.type === "line") {
        canvas.remove(item);
      }
    });
  }

  return createBorders(
    canvas,
    canvasSize.length,
    canvasSize.height,
    bluePrintId,
    showBleedEdges
  );
};

export const calculatePolygonArea = coordinates => {
  const polygon = turf.polygon(coordinates);
  const area = Math.round(turf.area(polygon) * 100) / 100;
  return area;
};

export const editImage = (activeObject, option, canvas, canvasSize) => {
  const left = canvas.clipPath.left;
  const top = canvas.clipPath.top;
  switch (option) {
    case "flipX":
      activeObject.set("flipX", !activeObject.flipX);
      break;
    case "flipY":
      activeObject.set("flipY", !activeObject.flipY);
      break;
    case "alignVCenter":
      activeObject.centerV();
      break;
    case "alignHCenter":
      activeObject.centerH();
      break;
    case "alignCenter":
      activeObject.center();
      break;
    case "alignLeft":
      activeObject.set({
        left,
      });
      break;
    case "alignRight":
      activeObject.set({
        left:
          left + canvasSize.length - activeObject.width * activeObject.scaleX,
      });
      break;
    case "alignTop":
      activeObject.set({
        top,
      });
      break;
    case "alignBottom":
      activeObject.set({
        top:
          top + canvasSize.height - activeObject.height * activeObject.scaleY,
      });
      break;
    case "scale": {
      activeObject.set({
        top: 0,
        left: 0,
        scaleX: canvasSize.length / activeObject.width,
        scaleY: canvasSize.height / activeObject.height,
      });
      canvas?.centerObject(activeObject);
      break;
    }
    case "originalSize":
      {
        const width = (450 * canvasSize.length) / canvasSize.height;
        activeObject.scaleToWidth(width / 2);
        activeObject.center();
      }
      break;

    default:
      return;
  }
};

export const getStaticImage = async (coordinates, width, height, zoom = 15) => {
  try {
    const request = staticClient.getStaticImage({
      ownerId: "boundridevaccount",
      styleId: "cl8bnao96000p15o217abk7lk",
      width,
      height,
      position: {
        coordinates,
        zoom,
      },
    });
    const { body } = await request.send();
    return body || null;
  } catch (err) {
    logger.error(err);
  }
  return null;
};

export const getHeightWidth = (pageSize, orientation) => {
  let width = pageSize.value[0];
  let height = pageSize.value[1];
  if (orientation.value === "portrait") {
    width = pageSize.value[1];
    height = pageSize.value[0];
  }

  return { width, height };
};

export const getSubTotal = lineItems => {
  let subTotal = 0;
  lineItems?.forEach(item => {
    const quantity = parseInt(item.quantity);
    const price = parseFloat(item.product.active_variant.price) * 100;
    subTotal += parseFloat(price) * quantity;
  });
  return subTotal / 100;
};

export const dataURItoBlob = dataURI => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const createBorders = (
  canvas,
  width,
  height,
  bluePrintId,
  showBleedEdges = false
) => {
  const left = canvas.width / 2 - width / 2;
  const top = canvas.height / 2 - height / 2;
  if (bluePrintId === ROUND_RUG) {
    const circle = new fabric.Circle({
      type: "line",
      stroke: "black",
      fill: "transparent",
      selectable: false,
      radius: width / 2,
      strokeDashArray: [5, 5],
      left,
      top,
    });

    canvas.add(circle);
  } else {
    const line = new fabric.Line([0, 0, width - 10, 0], {
      strokeDashArray: [5, 5],
      stroke: "black",
      left: left + (showBleedEdges ? OFFSET_VALUE_SIX : 0),
      top: top + (showBleedEdges ? OFFSET_VALUE_SIX : 0),
      type: "line",
      selectable: false,
    });

    const line1 = new fabric.Line([0, 0, width, 0], {
      strokeDashArray: [5, 5],
      stroke: "black",
      left: left - (showBleedEdges ? OFFSET_VALUE_FOUR : 0),
      top: top + height - (showBleedEdges ? OFFSET_VALUE_SIX : 0),
      type: "line",
      selectable: false,
    });

    const line2 = new fabric.Line([0, 0, 0, height], {
      strokeDashArray: [5, 5],
      stroke: "black",
      left: left + (showBleedEdges ? OFFSET_VALUE_SIX : 0),
      top: top - (showBleedEdges ? OFFSET_VALUE_FOUR : 0),
      type: "line",
      selectable: false,
    });

    const line3 = new fabric.Line([0, 0, 0, height], {
      strokeDashArray: [5, 5],
      stroke: "black",
      left: left + width - (showBleedEdges ? OFFSET_VALUE_SIX : 0),
      top: top - (showBleedEdges ? OFFSET_VALUE_FOUR : 0),
      type: "line",
      selectable: false,
    });
    const line4 = new fabric.Line([0, 0, 0, height], {
      strokeDashArray: [5, 5],
      stroke: "black",
      left: canvas.width / 2,
      top,
      type: "line",
      selectable: false,
      id: "journal",
    });

    canvas.add(line);
    canvas.add(line1);
    canvas.add(line2);
    canvas.add(line3);
    if (bluePrintId === JOURNAL) canvas.add(line4);
  }

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const centerX = canvasWidth / 2;
  const centerY = canvasHeight / 2;

  const hLine = new fabric.Line([centerX, 0, centerX, canvasHeight], {
    stroke: "red",
    selectable: false,
    evented: false,
    strokeWidth: 1,
    visible: false,
  });

  // Create vertical center line
  const vLine = new fabric.Line([0, centerY, canvasWidth, centerY], {
    stroke: "red",
    selectable: false,
    evented: false,
    strokeWidth: 1,
    visible: false,
  });
  canvas.add(hLine, vLine);

  return { hLine, vLine };
};

export const resizeCanvas = (canvas, canvasSize, bluePrintId) => {
  const objects = canvas.getObjects();
  const top = canvas.height / 2 - canvasSize.height / 2;
  const left = canvas.width / 2 - canvasSize.length / 2;

  canvas.clipPath.left = left;
  canvas.clipPath.top = top;
  canvas.clipPath.width = canvasSize.length;
  canvas.clipPath.height = canvasSize.height;
  const showBleedEdges = bluePrintId === RUGS || bluePrintId === GLOSS;
  const { hLine, vLine } = calculateBleedEdges(
    showBleedEdges,
    canvasSize,
    top,
    left,
    objects,
    canvas,
    bluePrintId
  );

  canvas.renderAll();

  return { hLine, vLine };
};

export const getLength = (item, printOnSide) => {
  let length = NUMBER_OF_STATIC_LINES;

  if (item === ROUND_RUG) length = NUMBER_OF_CURVED_CIRCLE_BORDER;
  else if (printOnSide) length += 4;
  else if (item === JOURNAL) length += 1;

  return length;
};
export const layerObjects = (canvas, item) => {
  const objects = canvas?.getObjects();
  let index = 0;
  objects.forEach((ele, idx) => {
    if (item.imageType === "lib" && ele.type === "image") index = idx;
    else if (item.imageType === "map" && ele.imageType === "map") index = idx;
  });
  return { index, objects };
};

export const getBoundary = (width, height, canvas, bluePrintId) => {
  if (bluePrintId === ROUND_RUG) {
    return new fabric.Circle({
      radius: width / 2,
      left: canvas.width / 2 - width / 2,
      top: canvas.height / 2 - height / 2,
    });
  }

  return new fabric.Rect({
    width,
    height,
    left: canvas.width / 2 - width / 2,
    top: canvas.height / 2 - height / 2,
  });
};

export const checkAllLoaded = (
  length,
  canvas,
  callback,
  bluePrintId,
  printOnSide
) => {
  const canvasItems = canvas.getObjects();
  const listLength = getLength(bluePrintId, printOnSide);

  if (Math.abs(canvasItems.length - listLength) === length) {
    const showLinesOnTop = [];
    canvasItems
      .filter(item => {
        if (item.type === "line") {
          showLinesOnTop.push(item);
        }

        return item.type !== LINE;
      })
      .sort((a, b) => a.layer - b.layer)
      .forEach(item => item.bringToFront());
    if (showLinesOnTop.length > 0) {
      showLinesOnTop.forEach(item => {
        canvas.remove(item);
        canvas.insertAt(item, canvasItems.length);
      });
    }
    callback();
  }
};

export const addCanvasImage = (
  { url, top, left, name, id, details, mapId, layer, scale_y, scale_x },
  object,
  cCanvasObjectList,
  list,
  callBack,
  canvasRef,
  bluePrintId,
  printOnSide,
  setCanvasObjectList
) => {
  const uniqueId = uuid();
  const imageObject = new Image();
  imageObject.crossOrigin = "Anonymous";
  imageObject.src = url;
  imageObject.onload = () => {
    object = new fabric.Image(imageObject);
    object.set({
      name,
      cornerSize: 8,
      transparentCorners: false,
      top: parseFloat(top) || 0,
      left: parseFloat(left) || 0,
      id: uniqueId,
      type: IMAGE,
      layer,
      imageType: mapId ? MAP : LIB,
      scaleX: scale_x,
      scaleY: scale_y,
    });

    cCanvasObjectList.push({
      id: uniqueId,
      object,
      type: IMAGE,
      url,
      name,
      attachmentId: id || null,
      mapDetails: details,
      mapId,
      layer,
    });
    canvasRef.add(object);
    if (cCanvasObjectList.length === list.length) {
      setCanvasObjectList(cCanvasObjectList);
      canvasRef?.renderAll();
      checkAllLoaded(
        list.length,
        canvasRef,
        callBack,
        bluePrintId,
        printOnSide
      );
    }
  };
};

export const renderText = (item, canvasRef, id) => {
  const object = new fabric.IText(item.text, {
    id,
    cornerSize: 8,
    transparentCorners: false,
    top: parseFloat(item.top) || 0,
    left: parseFloat(item.left) || 0,
    fill: item.color,
    type: TEXT,
    layer: item.layer,
  });
  object.scaleToWidth(item.scale_x);
  object.scaleToHeight(item.scale_y);
  canvasRef.add(object);
  return object;
};

export const mouseDownHandler = (
  evt,
  canvas,
  bluePrintId,
  setSelectedObject
) => {
  if (evt.target) {
    const target = evt.target;
    if (evt.target.type === IMAGE) {
      const { index } = layerObjects(canvas, target);
      canvas?.remove(target);
      canvas?.insertAt(target, index);
      canvas?.setActiveObject(target);
      setSelectedObject(target);
      canvas?.renderAll();
    } else if (evt.target.type === TEXT) {
      setSelectedObject(target);
    }
  }
};

export const getTargetDimension = value => (value > 3000 ? value / 6 : value);

export const checkCenterAlignment = (target, canvas, centerLinesRef) => {
  const canvasWidth = canvas.getWidth();
  const canvasHeight = canvas.getHeight();

  const centerX = canvasWidth / 2;
  const centerY = canvasHeight / 2;

  const objectCenterX = target.left + target.getScaledWidth() / 2;
  const objectCenterY = target.top + target.getScaledHeight() / 2;

  const isCenteredX = Math.abs(objectCenterX - centerX) < 5;
  const isCenteredY = Math.abs(objectCenterY - centerY) < 5;

  centerLinesRef.current.hLine.set({ visible: isCenteredY });
  centerLinesRef.current.vLine.set({ visible: isCenteredX });

  canvas.renderAll();
};
