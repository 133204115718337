import React, { useState } from "react";

import { Typography, Input } from "neetoui";

import Footer from "components/Common/Footer";
import Header from "components/Common/Header";
import useDebounce from "hooks/useDebounce";
import Search from "images/search.svg";

import OrderList from "./OrderList";

const MyOrders = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm);

  return (
    <div
      className="my-accounts h-full w-full"
      style={{ background: "#fdfdfe" }}
    >
      <Header />
      <div className="px-36 pb-5">
        <div className="container mx-auto">
          <div className="mb-8 flex items-center justify-between pt-10">
            <Typography style="h2">My Orders</Typography>
          </div>
          <div className="mb-8 w-full">
            <Input
              size="large"
              className="m-auto px-14"
              prefix={<Search className="h-6 w-6" />}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search"
            />
          </div>
          <OrderList searchTerm={debouncedSearchTerm} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyOrders;
