import React from "react";

const Card = ({ children, title, className = "" }) => (
  <div className={`border-grey-500  rounded border bg-white ${className}`}>
    <div className="py-7 px-5 text-base font-bold">{title}</div>
    <div className="border-grey-500 border-t p-7">{children}</div>
  </div>
);

export default Card;
