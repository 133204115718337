import React, { useState } from "react";

import { Modal, Button, Tab } from "neetoui";
import PropTypes from "prop-types";

import CropLabel from "./CropLabel";
import CustomColor from "./CustomColors";
import UsdaColors from "./UsdaColors";

const CropInput = ({ landCategories, landCategory, updatedLandCategory }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedCrop, setSelectedCrop] = useState({
    color: "",
    label: "",
    value: "",
  });

  const handleColorClick = item => setSelectedCrop(item);

  return (
    <>
      <div className="land-category-picker col-span-2 my-2">
        <div className="label">Field Purpose</div>
        <div className="flex items-center justify-between">
          <CropLabel label={landCategory?.label} color={landCategory?.color} />
          <div className="select-crop" onClick={() => setShowModal(true)}>
            Select crop
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeButton={false}
        size="large"
        className="land-category-picker rounded"
      >
        <Modal.Header>
          <Tab>
            <Tab.Item
              active={activeTab === 1}
              className="tab-item"
              onClick={() => setActiveTab(1)}
            >
              USDA Crop Categories
            </Tab.Item>
            <Tab.Item
              active={activeTab === 2}
              className="tab-item"
              onClick={() => setActiveTab(2)}
            >
              Custom colors
            </Tab.Item>
          </Tab>
        </Modal.Header>
        <Modal.Body className="space-y-2">
          {activeTab === 1 && (
            <UsdaColors
              landCategories={landCategories}
              handleColorClick={handleColorClick}
              selectedCrop={selectedCrop}
            />
          )}
          {activeTab === 2 && (
            <CustomColor
              color={selectedCrop.color}
              handleColorChange={color =>
                setSelectedCrop({ ...selectedCrop, color })
              }
            />
          )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-center space-x-2">
          <Button
            style="secondary"
            label="Cancel"
            className="rounded-full"
            onClick={() => setShowModal(false)}
          />
          <Button
            label="Ok"
            onClick={() => {
              updatedLandCategory(selectedCrop);
              setShowModal(false);
            }}
            className="rounded-full"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

CropInput.propTypes = {
  landCategories: PropTypes.array,
};

export default CropInput;
