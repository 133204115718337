import axios from "axios";

const login = payload => axios.post("api/v1/login", { user: payload });

const logout = () => axios.delete("api/v1/logout");

const signup = ({
  email,
  firstName: first_name,
  lastName: last_name,
  password,
  passwordConfirmation: password_confirmation,
  phone: phone_number,
  city,
  country: { label: country_label, value: country_code },
  region: { label: region_label, value: region_code },
  zipCode: zip_code,
  address1: address_one,
  address2: address_two,
}) =>
  axios.post("api/v1/users", {
    user: {
      email,
      first_name,
      last_name,
      password,
      password_confirmation,
      phone_number,
      address: {
        country_label,
        country_code,
        region_label,
        region_code,
        zip_code,
        address_one,
        address_two,
        city,
      },
    },
  });

const authenticationApi = {
  login,
  logout,
  signup,
};

export default authenticationApi;
