import axios from "axios";

const BASE_URL = "api/v1";
const CARTS_URL = `${BASE_URL}/checkout_session`;

const create = payload => axios.post(CARTS_URL, { payload });

const checkoutSession = {
  create,
};

export default checkoutSession;
