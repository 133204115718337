import axios from "axios";

const BASE_URL = "api/v1/address_books";

const fetch = () => axios.get(BASE_URL);

const destroy = id => axios.delete(`${BASE_URL}/${id}`);

const create = payload => axios.post(BASE_URL, { address: payload });

const setAsPrimary = id => axios.post(`${BASE_URL}/set_primary`, { id });

const getPrimary = () => axios.get(`${BASE_URL}/get_primary`);

const addressBooksApi = { fetch, create, destroy, setAsPrimary, getPrimary };

export default addressBooksApi;
