import React, { useState, useEffect } from "react";

import { Spinner, Pagination, Alert } from "neetoui";

import libraryImagesApi from "apis/libraryImagesApi";
import { PAGE_LIMIT } from "common/constants";
import DeleteSvg from "images/delete-icon.svg";

const ImageList = () => {
  const [images, setImages] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  const getImages = async pageNumber => {
    try {
      setIsImageLoading(true);
      const {
        data: { library_images, total_count },
      } = await libraryImagesApi.fetch({
        page: pageNumber,
        limit: PAGE_LIMIT,
      });
      setImages(library_images);
      setTotalCount(total_count);
      setIsImageLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    getImages(pageNumber);
  }, []);

  const deleteImage = id => {
    setSelectedImage(id);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await libraryImagesApi.destroy(selectedImage);
      setOpen(false);
      setIsDeleting(false);
    } catch (err) {
      logger.error(err);
    }
  };
  return (
    <>
      {isImageLoading ? (
        <div className="flex h-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="content-height grid grid-cols-4 gap-6">
            {images.map(({ id, url }) => (
              <div key={id} className="relative h-60 bg-gray-200">
                <img src={url} className="h-full w-full object-contain" />
                <div
                  className="absolute bottom-2.5 right-2.5 flex h-8 w-8 items-center justify-center rounded-full bg-white"
                  onClick={() => deleteImage(id)}
                >
                  <DeleteSvg />
                </div>
              </div>
            ))}
          </div>
          <Pagination
            className="pagination-component my-5"
            count={totalCount}
            navigate={value => {
              setPageNumber(value);
              setIsImageLoading(true);
              getImages(value);
            }}
            pageNo={pageNumber}
            pageSize={PAGE_LIMIT}
          />
        </div>
      )}
      <Alert
        isOpen={open}
        title="Delete Image"
        message="Are you sure?"
        onClose={() => {
          setOpen(false);
          setSelectedImage();
        }}
        onSubmit={() => handleDelete()}
        isSubmitting={isDeleting}
      />
    </>
  );
};

export default ImageList;
