import React from "react";

import AlignBottomSvg from "images/align-bottom.svg";
import AlignLeftSvg from "images/align-left.svg";
import AlignRightSvg from "images/align-right.svg";
import AlignTopSvg from "images/align-top.svg";
import CropSvg from "images/crop.svg";
import FlipXSvg from "images/flip-x.svg";
import FlipYSvg from "images/flip-y.svg";
import AlignHCenterSvg from "images/horizontal-center.svg";
import ReSizeSvg from "images/resize-original.svg";
import ScaleSvg from "images/scale.svg";
import AlignVCenterSvg from "images/vertical-center.svg";

export const editOptions = handleObjectPosition => [
  {
    left: {
      icon: <AlignTopSvg />,
      onClick: () => handleObjectPosition("alignTop"),
      label: "Align top",
    },
    center: {
      icon: <AlignHCenterSvg />,
      onClick: () => handleObjectPosition("alignVCenter"),
      label: "Align horizontal center",
    },
    right: {
      icon: <AlignBottomSvg />,
      onClick: () => handleObjectPosition("alignBottom"),
      label: "Align bottom",
    },
  },
  {
    left: {
      icon: <AlignLeftSvg />,
      onClick: () => handleObjectPosition("alignLeft"),
      label: "Align left",
    },
    center: {
      icon: <AlignVCenterSvg />,
      onClick: () => handleObjectPosition("alignHCenter"),
      label: "Align vertical center",
    },
    right: {
      icon: <AlignRightSvg />,
      onClick: () => handleObjectPosition("alignRight"),
      label: "Align right",
    },
  },
  {
    left: {
      icon: <ScaleSvg />,
      onClick: () => handleObjectPosition("scale"),
      label: "Scale",
    },
    center: {
      icon: <CropSvg />,
      onClick: () => handleObjectPosition("crop"),
      label: "Crop",
    },
    right: {
      icon: <ReSizeSvg />,
      onClick: () => handleObjectPosition("originalSize"),
      label: "Resize image",
    },
  },
  {
    left: {
      icon: <FlipXSvg />,
      onClick: () => handleObjectPosition("flipX"),
      label: "Flip along x-axis",
    },
    center: {
      icon: <FlipYSvg />,
      onClick: () => handleObjectPosition("flipY"),
      label: "Flip along y-axis",
    },
    right: { icon: null },
  },
];
