import React from "react";

import classNames from "classnames";

const SideHeader = ({ productName, handleProductNameChange, error }) => (
  <div className="flex w-4/12 items-center justify-between border-r border-[#E4E5E6] px-4 py-4">
    <div>
      <input
        className={classNames(
          "outline-none w-full px-5 py-3 font-semibold text-blue-500",
          {
            "ring-offset-slate-50 dark:ring-offset-slate-900 ring ring-red-500 ring-offset-2":
              error,
          }
        )}
        placeholder="Product name"
        onChange={e => handleProductNameChange(e.target.value)}
        value={productName}
      />
    </div>
  </div>
);

SideHeader.propTypes = {};

export default SideHeader;
