import React, { useMemo } from "react";

import { Table } from "neetoui";

import { PAGE_LIMIT } from "common/constants";
import NoDataSvg from "images/no-data.svg";

import { makeColumns } from "./tableColumns";

const ProductsTable = ({
  onDeleteClick,
  products,
  setSelected,
  isLoading,
  selected,
  pageNumber,
  setPageNumber,
  totalCount,
  addToCart,
}) => {
  const COLUMNS = useMemo(() => makeColumns(onDeleteClick, addToCart), []);

  return (
    <div
      style={{
        height: "calc(100vh - 367px)",
      }}
    >
      {products.length > 0 ? (
        <Table
          className="bg-white"
          columnData={COLUMNS}
          rowData={products}
          onRowSelect={(selectedRowKeys, selectedRows) =>
            setSelected(selectedRows)
          }
          loading={isLoading}
          rowSelection
          fixedHeight
          defaultPageSize={PAGE_LIMIT}
          totalCount={totalCount}
          selectedRowKeys={selected.map(map => map?.id)}
          currentPageNumber={pageNumber}
          handlePageChange={page => setPageNumber(page)}
          paginationProps={{
            pageSize: PAGE_LIMIT,
          }}
        />
      ) : (
        <>
          {!isLoading && (
            <div className="flex h-full flex-col items-center justify-center">
              <div className="w-1/3">
                <NoDataSvg />
              </div>
              <br />
              <div>No Products</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductsTable;
