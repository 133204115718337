import React from "react";

import { Modal, Typography } from "neetoui";

const MobileView = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} size="small" onClose={onClose}>
    <Modal.Header>
      <Typography id="dialog1Title" style="h2">
        Warning!!
      </Typography>
    </Modal.Header>
    <Modal.Body className="py-2">
      <Typography lineHeight="normal" style="body2">
        Product builder is not suited for mobile applications beyond certain
        tablets. Select continue to proceed.
      </Typography>
    </Modal.Body>
  </Modal>
);

export default MobileView;
