import axios from "axios";

const BASE_URL = "api/v1";
const REGIONS_URL = `${BASE_URL}/regions`;

const fetch = () => axios.get(REGIONS_URL);
const create = ({ title, description, group: { value } }) =>
  axios.post(REGIONS_URL, { region: { title, description, group_id: value } });

const update = (id, { title, description, group: { value } }) =>
  axios.put(`${REGIONS_URL}/${id}`, {
    region: { title, description, group_id: value },
  });

const regionsApi = {
  fetch,
  create,
  update,
};

export default regionsApi;
