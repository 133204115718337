import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1/maps";

const fetch = params => axios.get(`${BASE_URL}?${qs.stringify(params)}`);

const destroy = payload => axios.post(`${BASE_URL}/bulk_delete`, payload);

const update = (id, payload) => axios.put(`${BASE_URL}/${id}`, payload);

const fetchById = id => axios.get(`${BASE_URL}/${id}`);

const fetchByCatalogId = params =>
  axios.get(`${BASE_URL}/get_maps_by_catalog_id?${qs.stringify(params)}`);

const create = formData =>
  axios.post(`${BASE_URL}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const createDuplicate = id =>
  axios.post(`${BASE_URL}/create_duplicate`, { id });

const mapsApi = {
  fetch,
  create,
  destroy,
  fetchById,
  update,
  fetchByCatalogId,
  createDuplicate,
};

export default mapsApi;
