import React, { useEffect, useState } from "react";

import { Pagination, Spinner } from "neetoui";
import { useHistory, useLocation } from "react-router-dom";

import catalogueApi from "apis/catalogue";
import { PAGE_LIMIT } from "common/constants";
import { useProductDispatch } from "contexts/product";
import NoDataSvg from "images/no-data.svg";

import ProductCard from "./ProductCard";

const Content = () => {
  const [loading, setLoading] = useState(true);
  const [catalogues, setCatalogues] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category_id = params.get("category");
  const subcategory_id = params.get("subcategory");
  const history = useHistory();
  const productDispatch = useProductDispatch();

  const getCatalogues = async pageNumber => {
    const {
      data: { catalogues, total_count },
    } = await catalogueApi.fetch({
      category_id: params.get("category"),
      subcategory_id: params.get("subcategory"),
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: "",
    });
    setTotalCount(total_count);
    setCatalogues(catalogues);
    setLoading(false);
  };

  useEffect(() => {
    getCatalogues(page);
  }, [category_id, subcategory_id]);

  const handleOnClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    productDispatch({ type: "INITIALIZE_PRODUCT" });
    history.push(`/product-design/${id}`);
  };

  return (
    <div className="relative px-36">
      {loading && (
        <div className="absolute top-1/3 left-1/2 z-10">
          <Spinner />
        </div>
      )}
      <div className="content-height grid grid-cols-3 gap-4">
        {catalogues.length > 0 ? (
          catalogues.map(
            ({
              id,
              title,
              print_providers: { variants },
              min_price,
              images,
            }) => (
              <ProductCard
                key={id}
                title={title}
                size={variants.length}
                minPrice={min_price?.toFixed(2)}
                images={images}
                handleOnClick={e => handleOnClick(e, id)}
              />
            )
          )
        ) : (
          <>
            {!loading && (
              <div className="col-span-3 flex h-full flex-col items-center justify-center">
                <div className="w-1/3">
                  <NoDataSvg />
                </div>
                <br />
                <div>No Data</div>
              </div>
            )}
          </>
        )}
      </div>
      <Pagination
        className="pagination-component my-5"
        count={totalCount}
        navigate={value => {
          setPage(value);
          setLoading(true);
          getCatalogues(value);
        }}
        pageNo={page}
        pageSize={PAGE_LIMIT}
      />
    </div>
  );
};

export default Content;
