import React from "react";

import { Checkbox } from "neetoui";

const ProductSelect = ({ selected, onSelect }) => (
  <Checkbox
    className="mb-5"
    checked={selected}
    id="checkbox_name"
    label="Select Product"
    onChange={() => onSelect(!selected)}
  />
);

export default ProductSelect;
