import React, { useEffect, useState } from "react";

import { Button, Checkbox } from "neetoui";

import { useMapDispatch, useMapState } from "contexts/map";

const Footer = ({ printMap, handleRedirect }) => {
  const dispatch = useMapDispatch();

  const {
    options: { showCanvasEdges },
  } = useMapState();

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    dispatch({
      type: "SET_SHOW_CANVAS_EDGES",
      payload: true,
    });
    return () =>
      dispatch({
        type: "SET_SHOW_CANVAS_EDGES",
        payload: false,
      });
  }, []);

  const onCheckboxClick = event => {
    setChecked(event.target.checked);
    dispatch({ type: "SET_SHOW_CANVAS_EDGES", payload: event.target.checked });
  };

  return (
    <div className="flex">
      <div className="flex w-3/12 items-center justify-between justify-around border-t border-r border-[#E4E5E6] bg-white py-2">
        <Button
          label="Cancel"
          style="secondary"
          className="rounded-full px-6"
          to={handleRedirect}
        />
        <Button
          label="Save map"
          className="rounded-full px-6"
          disabled={!showCanvasEdges}
          onClick={printMap}
        />
      </div>
      <div className="flex grid w-9/12 grid-cols-3 items-center gap-4 border-t border-[#E4E5E6] bg-white p-2">
        <Checkbox
          className="col-start-2 items-center"
          label="Show product canvas edges"
          checked={checked}
          onChange={onCheckboxClick}
        />
      </div>
    </div>
  );
};

export default Footer;
