import React from "react";

import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImagePreviews = ({ images, previewImage, setPreviewImage }) => (
  <div className="grid grid-cols-3 gap-6 p-6">
    {images.map((image, index) => (
      <div
        key={index}
        className={classNames(
          "border-grey-400 h-28 w-28 cursor-pointer border",
          {
            "border-2 border-green-500": previewImage === image.src,
          }
        )}
        onClick={() => setPreviewImage(image.src)}
      >
        <LazyLoadImage
          alt={image.alt}
          height={image.height}
          src={image.src}
          width={image.width}
        />
      </div>
    ))}
  </div>
);

export default ImagePreviews;
