import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Form from "./Form";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe = loadStripe(process.env.STRIPE_KEY);

const AddressForm = () => {
  const options = {
    loader: "always",
    appearance: {
      theme: "stripe",
      rules: {
        ".Label": {
          color: "#202020",
          fontWeight: 500,
          fontSize: "12px",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
          marginBottom: "8px",
        },
        ".Input": {
          padding: "5px 8px",
          minHeight: "30px",
          borderRadius: 0,
          boxShadow: "none",
          borderColor: "rgb(194,200,204)",
          fontSize: "14px",
          fontWeight: "normal",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
          marginBottom: "12px",
        },
        ".Input--selected": {
          borderColor: "rgb(194,200,204)",
          boxShadow: "none",
        },
      },
    },
  };

  return (
    <Elements stripe={stripe} options={options}>
      <Form />
    </Elements>
  );
};

export default AddressForm;
