import React from "react";

const SideBarNote = () => (
  <div className="rounded rounded-sm bg-gray-200 p-3">
    <span className="text-sm font-medium">Print file requirement</span>
    <ul className="list-disc px-4 py-2 text-xs font-normal text-gray-500">
      <li>JPG, PNG and SVG file types supported</li>
      <li>Maximum 50 MB (JPG, PNG) or 20 MB (SVG)</li>
      <li>
        Print area size{" "}
        <strong className="text-black">7200 x 10800 px (300 DPI)</strong>
      </li>
      <li>Maximum resolution 23000 x 23000px</li>
    </ul>
  </div>
);

export default SideBarNote;
