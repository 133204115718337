import axios from "axios";

const BASE_URL = "api/v1/printify/uploads";

const fetch = () => axios.get(BASE_URL);
const create = payload => axios.post(BASE_URL, { payload });
const destroy = id => axios.delete(`${BASE_URL}/${id}`);

const uploadsApi = {
  fetch,
  create,
  destroy,
};

export default uploadsApi;
