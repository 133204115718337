import { Globe } from "neetoicons";

export const APP_NAME = "Boundri Maps";

export const PASSWORD_PATH = "/my/password/edit";
export const PROFILE_PATH = "/my/profile";
export const LOGOUT_PATH = "/logout";

export const SIDENAV_LINKS = [
  {
    label: "Catalogs",
    to: "/admin/catalogs",
    icon: Globe,
  },
];
