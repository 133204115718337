import React, { useState, useEffect, useMemo } from "react";

import { Spinner, Dropdown, Typography, Checkbox, Tooltip } from "neetoui";
import { useHistory, useParams, useLocation } from "react-router-dom";

import libraryImagesApi from "apis/libraryImagesApi";
import mapApi from "apis/maps";
import { PAGE_LIMIT } from "common/constants";
import Tab from "components/Common/Tab";
import { ADD_MAP_PATH } from "components/routeConstants";
import { useMapDispatch } from "contexts/map";
import { useProductDispatch } from "contexts/product";
import TickSvg from "images/blackTick.svg";
import BoxSvg from "images/box.svg";
import MapSvg from "images/map.svg";

import AddObject from "./AddObject";
import ListCanvasObject from "./ListCanvasObject";
import MyImages from "./MyImages";

const { Menu, MenuItem } = Dropdown;

const Sidebar = ({
  isLoggedIn,
  fileUploadRef,
  onAddTextClick,
  sideBarItems,
  selectCanvasObject,
  handleObjectPosition,
  setImageSize,
  canvas,
  onMapsClick,
  removeCanvasObject,
  background,
  setBackground,
  selectedVariants,
  handleVarianceClick,
  activeVariant,
  imageUploading,
  allVariants = [],
  setSelectedVariants,
  catalogId,
  setImageUploading,
  isLoading,
  imageLoading,
  isPrintOnSide,
  onPrintOnSide,
  printOnSide,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [showAddObjectMenu, setShowAddObjectMenu] = useState(true);
  const [maps, setMaps] = useState([]);
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [mapPage, setMapPage] = useState(1);
  const [mapTotalCount, setMapTotalCount] = useState();
  const [libraryPage, setLibraryPage] = useState(1);
  const [libraryTotalCount, setLibraryTotalCount] = useState();
  const [imageFile, setImageFile] = useState(null);

  const dispatch = useProductDispatch();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mapDispatch = useMapDispatch();

  useEffect(() => {
    if (imageFile) uploadLibraryImages(imageFile);
  }, [imageFile]);

  const onFileChange = event => setImageFile(event.target.files[0]);

  const uploadLibraryImages = async file => {
    try {
      setImageUploading(true);
      const formData = new FormData();
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = async () => {
        formData.append("height", img.naturalHeight);
        formData.append("width", img.naturalWidth);
        formData.append("file", file);
        formData.append("name", file.name);
        const {
          data: { id, image, name },
        } = isLoggedIn
          ? await libraryImagesApi.create(formData)
          : {
              data: {
                id: null,
                image: window.URL.createObjectURL(file),
                name: file.name,
              },
            };
        if (isLoggedIn) getImages(libraryPage);

        onMapsClick(
          {
            url: image,
            name,
            isLibrary: true,
            libId: id,
            file,
            blob: isLoggedIn ? null : file,
          },
          () => {
            setImageUploading(false);
          }
        );

        window.URL.revokeObjectURL(img.src);
      };
    } catch (err) {
      logger.error(err);
    }
  };

  const getMaps = async pageNumber => {
    try {
      setIsMapLoading(true);
      const {
        data: { maps, total_count },
      } = await mapApi.fetchByCatalogId({
        page: pageNumber,
        limit: PAGE_LIMIT,
        catalog_id: catalogId,
      });
      setMaps(maps);
      setMapTotalCount(total_count);
      setIsMapLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  const getImages = async pageNumber => {
    try {
      setIsImageLoading(true);
      const {
        data: { library_images, total_count },
      } = await libraryImagesApi.fetch({
        page: pageNumber,
        limit: PAGE_LIMIT,
      });
      setImages(library_images);
      setIsImageLoading(false);
      setLibraryTotalCount(total_count);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (catalogId && isLoggedIn) {
      getMaps(mapPage);
      getImages(libraryPage);
    }
  }, [catalogId]);

  const sideBarLength = useMemo(() => {
    const length = sideBarItems.filter(item => !item.isDeleted).length;
    if (length) setShowAddObjectMenu(false);
    else setShowAddObjectMenu(true);

    return sideBarItems.filter(item => !item.isDeleted).length;
  }, [sideBarItems.length]);

  useEffect(() => {
    if (sideBarItems.filter(item => !item.isDeleted).length) {
      setShowAddObjectMenu(false);
    } else setShowAddObjectMenu(true);
  }, [sideBarItems]);

  const addDesignClick = () => setShowAddObjectMenu(true);

  const handleMapClick = url => onMapsClick(url, () => setActiveTab(1));

  const handleLibraryImageDelete = async id => {
    try {
      setIsMapLoading(true);
      await mapApi.destroy({ ids: [id] });
      getMaps();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleMapsDelete = async id => {
    try {
      setIsImageLoading(true);
      await libraryImagesApi.destroy(id);
      getImages();
    } catch (err) {
      logger.error(err);
    }
  };

  const gotToMaps = (isEdit = false, mapId, details, name, attachmentId) => {
    const bluePrintId = params.get("bluePrintId");
    const canvasHeight = activeVariant.placeholders[0].height;
    const canvasLength = activeVariant.placeholders[0].width;
    const canvasContainer = document.getElementById("canvas-container");
    const srcHeight = canvasContainer?.clientHeight / 1.5;
    const srcWidth = canvasContainer?.clientWidth / 1.5;

    const scaleFactor = Math.min(
      srcHeight / canvasHeight,
      srcWidth / canvasLength
    );

    const height = Math.round(canvasHeight * scaleFactor);
    const width = Math.round(canvasLength * scaleFactor);

    const canvasJson = canvas.toJSON(["id", "imageType"]);

    const payload = {
      canvasObjects: {
        ...canvasJson,
        sideBarItems,
        printOnSide,
      },
      canvasWidth: width,
      activeVariant,
      selectedVariants,
      canvasHeight: height,
    };

    if (bluePrintId) {
      payload.bluePrintId = bluePrintId;
      payload.productId = id;
    } else {
      payload.catalogId = id;
    }

    if (isEdit) {
      const { center, zoom, width, height, bearing } = details;
      mapDispatch({
        type: "SET_MAP_DETAILS",
        payload: {
          center,
          zoom,
          mapId,
          mapName: name,
          canvasWidth: width,
          canvasHeight: height,
          fromProductDesign: true,
          attachmentId,
          bearing,
        },
      });
    } else {
      mapDispatch({
        type: "SET_MAP_DETAILS",
        payload: {
          fromProductDesign: true,
        },
      });
    }

    dispatch({
      type: "SET_PRODUCT_CANVAS_OBJECT",
      payload,
    });

    history.push(`${ADD_MAP_PATH}/${id}`);
  };

  const getContent = option => {
    switch (option) {
      case 1:
        return (
          <>
            {showAddObjectMenu ? (
              <AddObject
                fileUploadRef={fileUploadRef}
                onAddTextClick={onAddTextClick}
                showCancelButton={!!sideBarLength}
                onCancelClick={() => setShowAddObjectMenu(false)}
              />
            ) : (
              <ListCanvasObject
                sideBarItems={sideBarItems}
                selectCanvasObject={selectCanvasObject}
                addDesignClick={addDesignClick}
                handleObjectPosition={handleObjectPosition}
                setImageSize={setImageSize}
                canvas={canvas}
                removeCanvasObject={removeCanvasObject}
                background={background}
                setBackground={setBackground}
                editImage={(id, details, name, attachmentId) =>
                  gotToMaps(true, id, details, name, attachmentId)
                }
              />
            )}
          </>
        );
      case 2:
        return (
          <MyImages
            images={maps}
            onImageClick={handleMapClick}
            isLoading={isMapLoading}
            handleDelete={handleLibraryImageDelete}
            totalCount={mapTotalCount}
            page={mapPage}
            setPage={setMapPage}
            getData={pageNumber => getMaps(pageNumber)}
          />
        );
      case 3:
        return (
          <MyImages
            isLibrary={true}
            images={images}
            onImageClick={handleMapClick}
            isLoading={isImageLoading}
            handleDelete={handleMapsDelete}
            page={libraryPage}
            totalCount={libraryTotalCount}
            setPage={setLibraryPage}
            getData={pageNumber => getImages(pageNumber)}
          />
        );
      default:
        return null;
    }
  };

  const handleVariantSelect = (e, variant) => {
    e.stopPropagation();
    setSelectedVariants([variant]);
    handleVarianceClick(variant);
  };

  return (
    <>
      <div className="sidebar-content flex w-4/12 border-r border-[#E4E5E6]">
        <div className="w-full">
          <Tab className="sidebar-tab justify-around border-b border-[#E4E5E6]">
            <Tab.Item
              active={activeTab === 1}
              className="tab-item"
              onClick={() => setActiveTab(1)}
              tooltipContent="Main Product Tools"
            >
              Product Design
            </Tab.Item>
            {isLoggedIn && (
              <>
                <Tab.Item
                  active={activeTab === 2}
                  className="tab-item"
                  onClick={() => setActiveTab(2)}
                  tooltipContent="Where saved maps are stored"
                >
                  My Maps
                </Tab.Item>
                <Tab.Item
                  active={activeTab === 3}
                  className="tab-item"
                  onClick={() => setActiveTab(3)}
                  tooltipContent="Where uploaded data is stored"
                >
                  My Library
                </Tab.Item>
              </>
            )}
          </Tab>
          <div className="tab-content">
            {isLoading && (imageUploading || imageLoading) ? (
              <div className="flex h-full flex-col items-center justify-center">
                <Spinner />
                <br />
                {imageUploading && <span>Uploading...</span>}
                {imageLoading && <span>Loading...</span>}
              </div>
            ) : (
              <>
                {activeTab === 1 && (
                  <>
                    <Tooltip
                      content="See product sizes and costs"
                      followCursor="horizontal"
                      position="top"
                    >
                      <>
                        <Typography className="mx-3 mt-4 text-base font-semibold">
                          Step 1 (Choose Sizes)
                        </Typography>
                        <Typography className="mx-3 py-1 text-xs font-normal text-gray-400">
                          Shipping is Included.
                        </Typography>
                        <Typography className="mx-3 py-1 text-xs font-normal text-gray-400">
                          Promo Codes entered in Payment Page.
                        </Typography>
                      </>
                    </Tooltip>
                    <div className="border-gray mx-3 mt-4 rounded border bg-white py-4 px-4 text-xs font-normal text-gray-500">
                      <div className="mb-3 mt-1 flex items-center justify-between">
                        <Dropdown
                          className="shadow"
                          buttonProps={{
                            className: "custom-dropdown-button",
                          }}
                          buttonStyle="text"
                          label="Choose Sizes"
                          closeOnOutsideClick
                        >
                          <Menu>
                            {allVariants.map((item, idx) => {
                              const isSelected =
                                selectedVariants[0]?.title === item.title;

                              return (
                                <MenuItem.Button
                                  key={idx}
                                  onClick={e => handleVariantSelect(e, item)}
                                >
                                  {isSelected ? <TickSvg /> : <BoxSvg />}
                                  {`${item.title} : $${item.price}`}
                                </MenuItem.Button>
                              );
                            })}
                          </Menu>
                        </Dropdown>
                      </div>
                      <div className="flex flex-wrap">
                        {selectedVariants.map(variant => (
                          <div
                            key={variant.id}
                            className="my-2 mx-3 flex h-9 cursor-pointer items-center justify-center bg-gray-100 p-2"
                            onClick={() => handleVarianceClick(variant)}
                          >
                            <BoxSvg />
                            <span className="ml-2">{`${variant.title} : $${variant.price}`}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Tooltip
                      content="Goes to our mapping page"
                      followCursor="horizontal"
                      position="top"
                    >
                      <Typography className="mx-3 mt-4 text-base font-semibold">
                        Step 2 (Create Map)
                      </Typography>
                    </Tooltip>
                    <div
                      className="border-gray active-blue mx-3 my-4 flex cursor-pointer items-center rounded border bg-white p-3 text-xs font-medium"
                      onClick={() => gotToMaps()}
                    >
                      <div className="mr-2">
                        <MapSvg />
                      </div>
                      Create a map
                    </div>
                    {isPrintOnSide && (
                      <div className="border-gray mx-3 my-4 bg-white p-3">
                        <Checkbox
                          label="Print on side"
                          checked={printOnSide}
                          onChange={onPrintOnSide}
                        />
                      </div>
                    )}
                  </>
                )}
                {activeTab === 1 && (
                  <Typography className="mx-3 my-4 text-base font-semibold">
                    Step 3 - (Optional)
                  </Typography>
                )}
                {getContent(activeTab)}
              </>
            )}
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileUploadRef}
        onChange={onFileChange}
        onClick={event => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
        accept="image/*"
      />
    </>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
