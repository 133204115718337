import React from "react";
import PropTypes from "prop-types";

const MapCount = ({ count }) => (
  <div className="ml-2 rounded-full bg-white px-2.5 text-xs font-semibold text-gray-500">
    {count}
  </div>
);

MapCount.propTypes = { count: PropTypes.number };

export default MapCount;
