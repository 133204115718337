import React, { useRef, useState } from "react";

import { Form, Formik } from "formik";
import { Typography, Button } from "neetoui";
import { Input, Textarea } from "neetoui/formik";

import Header from "components/Common/Header";
import ImagePng from "images/illustration5.png";
import UploadSvg from "images/upload1.svg";

import { CUSTOM_REQUEST_FORM_VALIDATION_SCHEMA } from "./constant";

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);

  const fileUploadRef = useRef(null);

  return (
    <div className="h-screen w-full">
      <Header />
      <div className="contact-us container m-auto py-2 pl-36">
        <Formik
          initialValues={{
            file: "",
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            question: "",
          }}
          validateOnChange={submitted}
          validateOnBlur={submitted}
          validationSchema={CUSTOM_REQUEST_FORM_VALIDATION_SCHEMA}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="grid w-full grid-cols-2 gap-5">
                <div>
                  <div className="mb-4 flex items-center justify-between pt-10">
                    <Typography style="h2">Online Request</Typography>
                  </div>
                  <span>
                    If you are looking to create a special map based printed
                    product, look no further. Boundri is here to help make what
                    you are looking for become a reality. Let us know below what
                    you are looking for and we will get back to you as soon as
                    we can.
                  </span>
                  <div className="mt-4 grid grid-cols-2 gap-5">
                    <Input
                      className="custom-input"
                      name="firstName"
                      label="First name"
                    />
                    <Input
                      className="custom-input"
                      name="lastName"
                      label="Last name"
                    />
                    <Input
                      className="custom-input"
                      name="email"
                      label="Email"
                    />
                    <Input
                      className="custom-input"
                      name="phoneNumber"
                      label="Phone number"
                    />
                    <Input
                      className="custom-input col-span-2"
                      name="address"
                      label="Address"
                    />
                    <Textarea
                      className="custom-input col-span-2"
                      name="question"
                      label="Question"
                    />
                    <div
                      className="border-gray col-span-2 mr-1 flex cursor-pointer items-center justify-center rounded border-2 border-dashed bg-white py-3 text-xs font-medium text-gray-500"
                      onClick={() => fileUploadRef?.current.click()}
                    >
                      {values.file ? (
                        <span className="ml-3">{values.file.name}</span>
                      ) : (
                        <>
                          <UploadSvg />
                          <span className="ml-3">attach file</span>
                        </>
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileUploadRef}
                      onChange={e => setFieldValue("file", e.target.files[0])}
                      onClick={event => (event.target.value = null)}
                      className="hidden"
                      accept=".png,.jpg"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <Button
                      type="submit"
                      label="Submit"
                      className="mt-4 h-8 rounded-full"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => setSubmitted(true)}
                    />
                  </div>
                </div>
                <img src={ImagePng} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
