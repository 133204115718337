import React from "react";

import { Tooltip } from "neetoui";

const ImageEditOptions = ({ options }) => (
  <>
    {options?.map((item, index) => (
      <div
        className="border-gray flex items-center justify-around rounded border"
        key={index}
      >
        <Tooltip content={item.left.label} position="top">
          <div
            className="m-auto flex w-2/6	justify-center py-2"
            onClick={item.left.onClick}
          >
            {item.left.icon}
          </div>
        </Tooltip>
        <Tooltip content={item.center.label} position="top">
          <div
            className="border-gray flex w-2/6 justify-center border-x border-l border-r py-2"
            onClick={item.center.onClick}
          >
            {item.center.icon}
          </div>
        </Tooltip>
        <Tooltip content={item.right.label} position="top">
          <div
            className="flex w-2/6 justify-center py-2"
            onClick={item.right.onClick}
          >
            {item.right.icon}
          </div>
        </Tooltip>
      </div>
    ))}
  </>
);

export default ImageEditOptions;
