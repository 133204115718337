import React from "react";

// import { INITIAL_FIELD_DETAILS } from "common/constants";
// import { useMapState, useMapDispatch } from "contexts/map";
// import PenSvg from "images/pen.svg";
// import PlusSvg from "images/plus.svg";
// import UnionSvg from "images/Union.svg";

// import AddFarm from "./AddFarm";
// import AddFieldModal from "./AddFieldModal";
// import AddGrower from "./AddGrower";
// import AddNew from "./AddNew";
// import CustomButtons from "./CustomButtons";
// import CustomOverlay from "./CustomOverlay";
import LayersDropDown from "./LayersDropDown";

const ManageFields = () => (
  // const [showOverLay, setShowOverlay] = useState("");

  // const { draw, showFieldModal } = useMapState();
  // const dispatch = useMapDispatch();

  // const popOverSize = showOverLay === "new" ? "small" : "large";

  // const handleClick = option => setShowOverlay(option);

  // const onPopOverClose = () => {
  //   setShowOverlay("");
  //   if (showFieldModal) {
  //     draw.deleteAll().getAll();
  //     dispatch({
  //       type: "SET_FIELD_DETAILS",
  //       payload: {
  //         fieldDetails: INITIAL_FIELD_DETAILS,
  //         showFieldModal: false,
  //         options: {
  //           isDrawPolygon: false,
  //         },
  //       },
  //     });
  //   }
  // };

  // const onHandleDraw = () => {
  //   dispatch({
  //     type: "SET_DRAW_POLYGON",
  //     payload: true,
  //   });
  //   draw.changeMode("draw_polygon");
  // };

  // const showDetails = option => {
  //   switch (option) {
  //     case "layers":
  //       return <LayersDropDown />;
  //     case "new":
  //       return <AddNew handleClick={option => setShowOverlay(option)} />;
  //     case "grower":
  //       return <AddGrower onClose={onPopOverClose} />;
  //     case "farm":
  //       return <AddFarm onClose={onPopOverClose} />;
  //     default:
  //       return null;
  //   }
  // };

  // const closeOnOutsideClick =
  //   !(showOverLay === "grower" || showOverLay === "farm" || showFieldModal) &&
  //   onPopOverClose;

  <div className="relative px-3 py-2">
    <LayersDropDown />
    {/* <div className="relative grid grid-cols-2 gap-4 py-2">
        <CustomButtons
          name="Layers"
          icon={<UnionSvg />}
          onClick={() => handleClick("layers")}
        />
        <CustomButtons
          name="Add new"
          icon={<PlusSvg />}
          onClick={() => handleClick("new")}
        />
        <CustomButtons
          name="Draw shape"
          icon={<PenSvg />}
          onClick={onHandleDraw}
          showDropDown={false}
        />
      </div>
      {(!!showOverLay || showFieldModal) && (
        <CustomOverlay onClose={closeOnOutsideClick} size={popOverSize}>
          {showDetails(showOverLay)}
          {showFieldModal && <AddFieldModal onClose={onPopOverClose} />}
        </CustomOverlay>
      )} */}
  </div>
);
ManageFields.propTypes = {};

export default ManageFields;
