import React, { useState } from "react";

import { Typography, Input } from "neetoui";

import DeleteSvg from "images/delete-icon.svg";

import ProductSelect from "./ProductSelect";
import Quantity from "./Quantity";

const ProductCard = ({
  productDetails,
  productQuantity = 1,
  updateLineItemQuantity,
  deleteLineItem,
  selected,
  onSelect,
}) => {
  const [quantity, setQuantity] = useState(productQuantity);

  const updateQuantity = quantity => {
    setQuantity(quantity);
    updateLineItemQuantity(quantity);
  };

  return (
    <div className="border-grey-500 product-card mb-8 w-2/3 rounded border bg-white">
      <div className="flex py-5 px-7">
        <div className="border-grey-500	h-36 w-36 rounded border">
          <img src={productDetails.images[0].src} />
        </div>
        <div className="ml-8 w-3/4">
          <div className="mb-7 flex justify-between">
            <Typography style="h5">{productDetails.title}</Typography>
            <div className="flex">
              <DeleteSvg
                className="mr-3 cursor-pointer"
                onClick={deleteLineItem}
              />
            </div>
          </div>
          <ProductSelect selected={selected} onSelect={onSelect} />
          <Input
            value={productDetails?.active_variant.title}
            label="Size"
            className="custom-input w-1/2"
            disabled={true}
          />
        </div>
      </div>
      <div className="flex items-center justify-between bg-gray-100 py-4 px-8">
        <div className="flex">
          <div className="footer-text">
            Price: $
            <strong>
              {parseFloat(productDetails?.active_variant.price).toFixed(2)}
            </strong>
          </div>
        </div>
        <Quantity setQuantity={updateQuantity} quantity={quantity} />
        <div className="footer-text flex justify-end">
          <div className="w-28 rounded-full bg-white px-3 py-2">
            cost: $
            <strong>
              {(
                parseFloat(productDetails?.active_variant.price) * quantity
              ).toFixed(2)}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
