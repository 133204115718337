import React from "react";

import { Pane, Typography } from "neetoui";

import fieldsApi from "apis/fields";

import FieldCards from "./FieldCard";

const FieldList = ({
  showPane,
  setShowPane,
  fields,
  handleEdit,
  getFieldList,
  removeFieldFromMap,
}) => {
  const handleDelete = async ({ id: fieldId, field_name }) => {
    try {
      await fieldsApi.destroy({ ids: [fieldId] });
      getFieldList();
      removeFieldFromMap(field_name);
    } catch (err) {
      // console.log(err)
    }
  };

  return (
    <Pane isOpen={showPane} onClose={() => setShowPane(false)} size="sm">
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          Field List
        </Typography>
      </Pane.Header>
      <Pane.Body>
        {fields.length > 0 ? (
          fields.map(field => (
            <FieldCards
              key={field.id}
              field={field}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          <Typography
            className="flex h-full w-full items-center justify-center"
            style="body1"
          >
            No Fields
          </Typography>
        )}
      </Pane.Body>
    </Pane>
  );
};
export default FieldList;
