import React, { useState, useMemo, useEffect } from "react";

import { CustomPicker } from "react-color";
import {
  Saturation,
  Hue,
  Alpha,
  EditableInput,
} from "react-color/lib/components/common";
import tinycolor from "tinycolor2";

import { HEX, HSL, HSV, RGBA } from "./constant";

const CustomPointer = () => <div className="custom-pointer"></div>;

const CustomColors = ({ handleColorChange, color }) => {
  const [hex, setHex] = useState(HEX);
  const [hsl, setHsl] = useState(HSL);
  const [hsv, setHsv] = useState(HSV);
  const [rgba, setRgba] = useState(RGBA);

  useEffect(() => {
    if (color) {
      const colorCode = tinycolor(color);
      setHex(colorCode.toHex());
      setHsl(colorCode.toHsl());
      setHsv(colorCode.toHsv());
      setRgba(colorCode.toRgb());
    }
  }, []);

  const getRgba = () => {
    const rgbaColor = tinycolor(hex).toRgb();
    return `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b},${rgba.a})`;
  };

  useMemo(() => {
    handleColorChange(getRgba());
  }, [hex, rgba]);

  const handleHueChange = hue => {
    setHsl(hue);
  };

  const handleSaturationChange = hsv => {
    const color = tinycolor(hsv);
    setHex(color.toHex());
    setHsv(hsv);
  };

  const handleInputChange = hex => {
    const color = tinycolor(hex);
    setHsv(color.toHsv());
    setHsl(color.toHsl());
    setHex(color.toHex());
  };

  const onAlphaChange = hsl => {
    const color = tinycolor(hsl);
    setHsl(hsl);
    setRgba(color.toRgb());
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="custom-color-picker m-auto flex flex-col items-center">
        <div className="saturation-container">
          <Saturation hsl={hsl} hsv={hsv} onChange={handleSaturationChange} />
        </div>
        <div className="p-4">
          <div className="hue-container m-3">
            <Hue hsl={hsl} pointer={CustomPointer} onChange={handleHueChange} />
          </div>
          <div className="alpha-container m-3">
            <Alpha
              rgb={rgba}
              hsl={hsl}
              pointer={CustomPointer}
              onChange={onAlphaChange}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="color-selector-container flex items-center justify-around bg-gray-100 px-3 py-3">
          <div className="title">Selected color</div>
          <div className="flex items-center">
            <div
              className="color-block mr-2"
              style={{ background: getRgba() }}
            ></div>
            <EditableInput
              style={{
                input: {
                  border: "1px solid #E4E5E6",
                  padding: "5px 10px",
                  color: "#777B81",
                  width: 100,
                  outline: "none",
                },
                label: {
                  display: "none",
                },
              }}
              value={hex}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(CustomColors);
