import React, { useEffect, useState } from "react";

import { Toastr, Spinner, Typography } from "neetoui";
import { useHistory, useParams } from "react-router-dom";
import { isPresent } from "utils";

import mapApi from "apis/maps";
import { CATALOGUE_PATH, MY_MAPS_PATH } from "components/routeConstants";
import { useAuthState } from "contexts/auth";
import { useMapDispatch, useMapState } from "contexts/map";
import { useProductState, useProductDispatch } from "contexts/product";
import { useUserState } from "contexts/user";
import useMaps from "hooks/useMaps";
import useProduct from "hooks/useProduct";

import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AddMap = () => {
  const [angle, setAngle] = useState(0);
  const [mapName, setMapName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [size, setSize] = useState();
  const [progress, setProgress] = useState("");

  const history = useHistory();
  const { id } = useParams();
  const productDispatch = useProductDispatch();
  const {
    bluePrintId,
    canvasObjects,
    canvasHeight,
    activeVariant,
    canvasWidth,
  } = useProductState();
  const { authToken } = useAuthState();
  const { user } = useUserState();

  const isLoggedIn = isPresent(authToken) && isPresent(user);

  const {
    mapDetails: {
      center,
      zoom,
      mapId,
      mapName: mapname,
      fromProductDesign,
      attachmentId,
    },
  } = useMapState();

  useEffect(() => {
    if (mapname) setMapName(mapname);
  }, [mapname]);

  const dispatch = useMapDispatch();

  const { isApiLoading, catalogueDetails, getProduct, catalogueData } =
    useProduct({});

  useEffect(() => {
    if (!activeVariant) history.push(CATALOGUE_PATH);

    if (catalogueData) {
      const edgeHeight = activeVariant.placeholders[0].height;
      const edgeLength = activeVariant.placeholders[0].width;

      setSize({
        label: activeVariant.title.split("/")[0],
        value: {
          width: canvasWidth,
          height: canvasHeight,
          originalWidth: edgeLength,
          originalHeight: edgeHeight,
        },
      });
    }
  }, [catalogueData]);

  const initializer = () => {
    if (bluePrintId) {
      getProduct(bluePrintId);
    } else {
      catalogueDetails();
    }
  };

  useEffect(() => {
    initializer();
    return () => {
      dispatch({ type: "REMOVE_MAP_DETAILS" });
    };
  }, []);

  const { mapContainer, map: mainMap, generateImage } = useMaps(center, zoom);

  const handleMapNameChange = value => {
    setMapName(value);
    setError(false);
  };

  const editMap = async ({ formData, newDetails }) => {
    if (attachmentId) formData.append("attachment_id", attachmentId);
    const {
      data: { map, name },
    } = await mapApi.update(mapId, formData);
    const cCanvasObjects = { ...canvasObjects };
    if (cCanvasObjects && Object.keys(cCanvasObjects).length !== 0) {
      let objectId = "";

      cCanvasObjects.sideBarItems.map(item => {
        if (item.mapId === mapId && !item.isDeleted) {
          objectId = item.id;
          item.url = map;
          item.name = name;
          item.mapDetails = newDetails;
        }

        return item;
      });

      cCanvasObjects.objects.map(item => {
        if (item.id === objectId) {
          item.src = map;
        }

        return item;
      });
      productDispatch({
        type: "UPDATE_CANVAS_OBJECTS",
        payload: cCanvasObjects,
      });
    } else {
      history.push(MY_MAPS_PATH);
    }
    const path = handleRedirect();
    history.push(path);

    setIsUploading(false);
  };

  const createMap = async ({ formData }) => {
    const {
      data: { id: mapId },
    } = await mapApi.create(formData);

    dispatch({
      type: "SET_MAP_ID",
      payload: mapId,
    });
    const path = handleRedirect();
    history.push(path);

    setIsUploading(false);
  };

  const generateStaticImage = async () => {
    try {
      if (!mapName) {
        Toastr.error(Error("Map name cannot be empty."));
        setError(true);
      } else {
        setIsUploading(true);
        const details = {
          height: size?.value?.height,
          width: size?.value?.width,
          catalogueId: catalogueData?.id,
          originalWidth: size?.value?.originalWidth,
          originalHeight: size?.value?.originalHeight,
        };
        generateImage(
          details,
          mapName,
          setProgress,
          (formData, newDetails, { blob, name }) => {
            setProgress("Uploading image...");
            if (isLoggedIn) {
              if (mapId) {
                editMap({ formData, newDetails });
              } else {
                createMap({ formData });
              }
            } else {
              dispatch({
                type: "SET_MAP_URL",
                payload: { blob, name, details: newDetails },
              });
              const path = handleRedirect();
              history.push(path);

              setIsUploading(false);
            }
          }
        );
      }
    } catch {
      // console.log({ err });
    }
  };

  const handleRedirect = () => {
    let path = !fromProductDesign ? MY_MAPS_PATH : `/product-design/${id}`;
    if (bluePrintId) {
      path = `${path}?bluePrintId=${bluePrintId}`;
    }

    return path;
  };

  return (
    <div className="map-design">
      {(isApiLoading || isUploading) && (
        <div className="fixed z-50 flex h-screen w-full items-center justify-center bg-gray-200 bg-opacity-50">
          {isUploading && (
            <div className="flex w-1/4 flex-col items-center rounded bg-white px-5 py-10">
              <Spinner />
              <div className="mt-5">{progress}</div>
              <Typography className="py-2 text-center text-xs font-normal text-gray-400">
                This could take up to 1 minute depending on the area of interest
                and your internet connection.
              </Typography>
            </div>
          )}
          {isApiLoading && <Spinner />}
        </div>
      )}

      <Header
        handleMapNameChange={handleMapNameChange}
        mapName={mapName}
        error={error}
        catalogueData={catalogueData}
      />
      <div className="flex">
        <Sidebar />
        <Content
          mapRef={mapContainer}
          map={mainMap}
          angle={angle}
          setAngle={setAngle}
          size={size}
        />
      </div>
      <Footer printMap={generateStaticImage} handleRedirect={handleRedirect} />
      {/* To render the screenshot area to get dataUrl */}
    </div>
  );
};

AddMap.propTypes = {};

export default AddMap;
