import React, { useState, useEffect } from "react";

import { Form, Formik } from "formik";
import { Button as CancelButton } from "neetoui";
import { Input, Textarea, Button, Select } from "neetoui/formik";

import groupsApi from "apis/groups";

const INITIAL_VALUES = {
  title: "",
  description: "",
};

const CreatGroupOrRegion = ({
  initialValues = null,
  onClose,
  handleSubmit,
  isRegion = false,
  validationSchema,
}) => {
  const [, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  const getGroupList = async () => {
    try {
      setIsLoading(true);
      const { data } = await groupsApi.fetch();
      setGroups(data.map(item => ({ label: item.title, value: item.id })));
      setIsLoading(false);
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    if (isRegion) getGroupList();
  }, []);

  return (
    <Formik
      initialValues={initialValues || INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          <Input name="title" label="Title" placeholder="Title" />
          <Textarea
            name="description"
            label="Description"
            placeholder="Description"
          />
          {isRegion && (
            <Select
              name="group"
              label="Group"
              placeholder="Select"
              options={groups}
            />
          )}
          <div className="mt-1 flex justify-end">
            <CancelButton
              label="Cancel"
              style="secondary"
              className="mr-2 rounded-full"
              onClick={onClose}
            />
            <Button label="Ok" className="rounded-full" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreatGroupOrRegion;
