import axios from "axios";

const BASE_URL = "api/v1";
const PRODUCTS_URL = `${BASE_URL}/create_payment_intent`;

const create = payload => axios.post(PRODUCTS_URL, { order: payload });

const paymentApi = {
  create,
};

export default paymentApi;
