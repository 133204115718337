import * as yup from "yup";

export const VALIDATION_SCHEMA = yup.object().shape({
  // bluePrint: yup.string().required("Required"),
  bluePrintId: yup.string().required("Required"),
  description: yup.string().required("Required"),
  title: yup.string().required("Required"),
  printProvider: yup.object().required("Required").nullable(),
  category: yup.object().required("Required").nullable(),
  subcategory: yup.object().required("Required").nullable(),
  variants: yup.array().of(
    yup.object().shape({
      variant: yup.object().required("Required").nullable(),
      price: yup.string().required("Required").nullable(),
    })
  ),
});
