import React, { createContext, useReducer, useEffect } from "react";

import PropTypes from "prop-types";

import productReducer from "reducers/product";

const ProductStateContext = createContext();
const ProductDispatchContext = createContext();

const INITIAL_STATE = {
  canvasObjects: null,
  bluePrintId: null,
  catalogueId: null,
  productId: null,
  canvasWidth: null,
  activeVariant: null,
  selectedVariants: null,
  attachmentId: null,
  name: "",
  printOnSide: false,
  hasSavedProduct: false,
  locationPath: "",
};

const ProductProvider = ({ children }) => {
  const localState = JSON.parse(localStorage.getItem("product-state")) || {};

  const [state, dispatch] = useReducer(productReducer, {
    ...INITIAL_STATE,
    ...localState,
  });

  useEffect(() => {
    if (state) {
      localStorage.setItem(
        "product-state",
        JSON.stringify({
          ...state,
        })
      );
    }
  }, [
    state.canvasObjects,
    state.activeVariant,
    state.selectedVariants,
    state.name,
    state.printOnSide,
    state.hasSavedProduct,
  ]);
  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  );
};

const useProductState = () => {
  const context = React.useContext(ProductStateContext);
  if (context === undefined) {
    throw new Error("useProductState must be used within a ProductProvider");
  }

  return context;
};

const useProductDispatch = () => {
  const context = React.useContext(ProductDispatchContext);
  if (context === undefined) {
    throw new Error("useProductDispatch must be used within a ProductProvider");
  }

  return context;
};

const useProduct = () => [useProductState(), useProductDispatch()];

ProductProvider.propTypes = {
  children: PropTypes.node,
};

export { ProductProvider, useProductState, useProductDispatch, useProduct };
