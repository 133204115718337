import { useState, useEffect } from "react";

import { Toastr } from "neetoui";
import { useParams } from "react-router-dom";

import catalogueApi from "apis/catalogue";
import mapsApi from "apis/maps";
import productApi from "apis/printify/product";
import {
  createProductPayload,
  formatPayload,
  getImage,
  getCatalogDetails,
} from "components/ProductDesign/helpers";
import { useMapState, useMapDispatch } from "contexts/map";
import { useProductDispatch, useProductState } from "contexts/product";

const useProduct = ({
  renderJson,
  onMapsClick,
  renderImages,
  printOnSide,
  canvasObjectList,
}) => {
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [activeVariant, setActiveVariant] = useState();
  const [catalogueData, setCatalogueData] = useState();
  const [isMapImageLoading, setIsMapImageLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [imageId, setImageId] = useState();
  const [background, setBackground] = useState("");
  const [allVariants, setAllVariants] = useState(null);

  // @ts-ignore
  const { id } = useParams();
  const {
    canvasObjects,
    activeVariant: activeProductVariant,
    selectedVariants: activeSelectedVariants,
    name,
  } = useProductState();

  const { uploadedMapId, mapWithoutLogin } = useMapState();
  const productDispatch = useProductDispatch();
  const dispatch = useMapDispatch();
  useEffect(() => {
    setProductName(name);
  }, []);

  useEffect(() => {
    // Persist the product name.
    productDispatch({ type: "UPDATE_PRODUCT_NAME", payload: productName });
  }, [productName]);

  const getMapImage = async (
    canvas,
    SidebarItems = [],
    canvasSize,
    bluePrintId
  ) => {
    const {
      data: { name, map, details },
    } = uploadedMapId
      ? await mapsApi.fetchById(uploadedMapId)
      : { data: { name: null, map: null, details: null } };

    onMapsClick(
      {
        url: map || URL.createObjectURL(mapWithoutLogin?.blob),
        name: name || mapWithoutLogin?.name,
        mapId: uploadedMapId,
        mapDetails: details || mapWithoutLogin?.details,
        canvasSize,
        blob: mapWithoutLogin?.blob || null,
      },
      () => {
        dispatch({ type: "REMOVE_ID" });
        setIsMapImageLoading(false);
      },
      canvas,
      SidebarItems,
      bluePrintId
    );
  };

  const catalogueDetails = async (canvas, callBack) => {
    setIsApiLoading(true);
    const { data } = await catalogueApi.fetchById(id);
    const variant = activeProductVariant || data.print_providers.variants[0];
    setSelectedVariants(activeSelectedVariants || [variant]);
    setActiveVariant(variant);
    setCatalogueData(data);

    if (canvas) {
      const bluePrintId = data.blue_print_id;
      const printOnSide = !!variant?.options?.depth;
      const { height, width } = getCatalogDetails(variant);

      callBack({
        height,
        width,
        bluePrintId,
        printOnSide,
      });

      if (canvasObjects?.objects.length) {
        // @ts-ignore
        renderJson(canvas, canvasObjects, SidebarItems => {
          productDispatch({ type: "REMOVE_CANVAS_OBJECTS" });
          if (uploadedMapId || mapWithoutLogin?.blob) {
            setIsMapImageLoading(true);
            getMapImage(canvas, SidebarItems, { width, height }, bluePrintId);
          }
          setIsApiLoading(false);
        });
      } else if (uploadedMapId || mapWithoutLogin?.url) {
        productDispatch({ type: "REMOVE_CANVAS_OBJECTS" });
        setIsMapImageLoading(true);
        getMapImage(canvas, [], { width, height }, bluePrintId);
        setIsApiLoading(false);
      } else {
        productDispatch({ type: "REMOVE_CANVAS_OBJECTS" });
        setIsApiLoading(false);
      }
    } else {
      setIsApiLoading(false);
    }
  };

  const getProduct = async (bluePrintId, canvas, callBack) => {
    setIsApiLoading(true);
    const { data } = await catalogueApi.fetchByBluePrint({ bluePrintId });
    const variants = data.print_providers.variants;

    setCatalogueData(data);

    if (canvas) {
      const {
        data: {
          attachments,
          print_areas: PrintAreas,
          title,
          variants: variantsList,
          active_variant,
          background,
        },
      } = await productApi.fetchById(id);
      const printOnSide = data.print_on_side;

      const { height, width } = getCatalogDetails(active_variant);

      callBack({ height, width, bluePrintId, printOnSide });

      const imageId = PrintAreas[0].placeholders[0].images[0].id;
      const varianceIds = variantsList
        .filter(variant => variant.is_enabled)
        .map(variant => variant.id);

      const selectedVariantList = variants.filter(variant =>
        varianceIds.includes(variant.id)
      );
      setActiveVariant(activeProductVariant || active_variant);
      setSelectedVariants(activeSelectedVariants || selectedVariantList);
      setProductName(title);
      setImageId(imageId);
      setBackground(background);
      setAllVariants(variantsList);
      if (canvasObjects?.objects.length) {
        // @ts-ignore
        renderJson(canvas, canvasObjects, SidebarItems => {
          productDispatch({ type: "REMOVE_CANVAS_OBJECTS" });
          if (uploadedMapId) {
            setIsMapImageLoading(true);
            getMapImage(canvas, SidebarItems, { width, height });
          }
          setIsApiLoading(false);
        });
      } else {
        // @ts-ignore
        renderImages(
          attachments,
          bluePrintId,
          printOnSide,
          () => {
            productDispatch({ type: "REMOVE_CANVAS_OBJECTS" });
            setIsApiLoading(false);
          },
          canvas,
          { height, width }
        );
      }
    } else {
      setIsApiLoading(false);
    }
  };

  const getImagePayload = (
    canvas,
    bluePrintId,
    originalWidth,
    originalHeight,
    background,
    callBack
  ) => {
    getImage(
      canvas,
      bluePrintId,
      originalWidth,
      originalHeight,
      background,
      (image, sizeInBytes) => {
        callBack(
          {
            file_name: productName || "akhil-file",
            blob: image,
          },
          sizeInBytes
        );
      }
    );
  };

  const createProduct = async ({
    status,
    isEdit = false,
    imageId = null,
    callBack = () => {},
  }) => {
    try {
      let variantList = selectedVariants;
      if (isEdit) {
        const selectedVarianceIds = selectedVariants.map(variant => variant.id);
        variantList = allVariants?.map(variant => ({
          ...variant,
          price: variant.price / 100,
          is_enabled: !!selectedVarianceIds.includes(variant.id),
        }));
      }

      const originalWidth = activeVariant?.placeholders[0].width;
      const originalHeight = activeVariant?.placeholders[0].height;

      const bluePrintId = parseInt(catalogueData?.blue_print_id);
      const canvas = document.getElementById("canvas")?.fabric;
      getImagePayload(
        canvas,
        bluePrintId,
        originalWidth,
        originalHeight,
        background,
        async (imagePayload, sizeInBytes) => {
          try {
            const createPayload = createProductPayload({
              productName: !productName.length
                ? "preview-product"
                : productName,
              bluePrintId,
              printProviderId: parseInt(catalogueData?.print_providers.id),
              variants: variantList,
              status,
              activeVariant,
              background,
              printOnSide,
            });
            if (sizeInBytes > 100000000) {
              throw new Error("File size limit exceeded.");
            }

            const payload = formatPayload(
              canvasObjectList,
              canvas,
              imagePayload,
              createPayload,
              imageId
            );

            if (isEdit) {
              const {
                data: { product },
              } = await productApi.update(id, payload);
              callBack(product);
            } else {
              const {
                data: { product },
              } = await productApi.create(payload);
              callBack(product);
            }
          } catch (err) {
            callBack();
            Toastr.error(err);
          }
        }
      );
    } catch (err) {
      callBack();
    }
  };

  return {
    isApiLoading,
    selectedVariants,
    activeVariant,
    catalogueData,
    isMapImageLoading,
    productName,
    imageId,
    background,
    catalogueDetails,
    getProduct,
    setActiveVariant,
    setProductName,
    setBackground,
    setSelectedVariants,
    createProduct,
    allVariants,
  };
};

export default useProduct;
