import React from "react";

import { Typography, Button } from "neetoui";

import DeleteSvg from "images/delete-icon.svg";

export const makeColumns = (onDelete, addToCart) => [
  {
    title: "Product",
    dataIndex: "map",
    key: "map",
    render: (_, row) => {
      const price = parseFloat(row.active_variant.price) || 0;
      return (
        <div className="flex">
          <div className="border-grey-500 flex h-32 w-32 items-center justify-center border">
            <img src={row.images[0].src} className="h-30" />
          </div>
          <div className="flex flex-col justify-center gap-2 px-4">
            <Typography style="h5" className="mb-5 font-medium">
              {row.title}
            </Typography>
            <div className="text-xs text-gray-400">Price: ${price}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    align: "center",
    render: (_, row) => (
      <div className="flex items-center justify-center gap-2">
        <Button
          label="Add to Cart"
          className="rounded-full"
          onClick={() => addToCart(row.id)}
        />
        <Button
          label="Edit"
          className="rounded-full"
          to={`/product-design/${row.id}?bluePrintId=${row.blueprint_id}`}
        />
        <div
          className="flex cursor-pointer items-center justify-center"
          onClick={() => onDelete(row.id)}
        >
          <DeleteSvg />
        </div>
      </div>
    ),
  },
];
