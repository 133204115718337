const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  country: "",
  region: "",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
};

export { INITIAL_VALUES };
