import React, { useMemo } from "react";

import ColorLabel from "./CropLabel";

const UsdaColors = ({ landCategories, handleColorClick, selectedCrop }) => {
  const { agriculture, nonAgriculture } = useMemo(() => {
    const agriculture = [];
    const nonAgriculture = [];
    if (landCategories.length) {
      landCategories.forEach(element => {
        if (element.type === "agriculture") {
          agriculture.push(element);
        } else {
          nonAgriculture.push(element);
        }
      });
    }

    return { agriculture, nonAgriculture };
  }, [landCategories]);

  return (
    <div className="usda-container">
      <div className="title my-3 w-full">Agriculture</div>
      {!!agriculture.length &&
        agriculture?.map(({ color, label, value }, index) => (
          <ColorLabel
            selected={!!(selectedCrop?.value === value)}
            key={`${label}+${index}`}
            color={color}
            label={label}
            handleColorClick={() => handleColorClick({ color, label, value })}
          />
        ))}
      <div className="title my-3 w-full">Non Agriculture</div>
      {!!nonAgriculture.length &&
        nonAgriculture?.map(({ color, label, value }, index) => (
          <ColorLabel
            selected={!!(selectedCrop?.value === value)}
            key={`${label}+${index}`}
            color={color}
            label={label}
            handleColorClick={() => handleColorClick({ color, label, value })}
          />
        ))}
    </div>
  );
};

export default UsdaColors;
