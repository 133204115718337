import axios from "axios";

const BASE_URL = "api/v1";
const LINE_ITEMS_URL = `${BASE_URL}/line_items`;

const fetch = () => axios.get(LINE_ITEMS_URL);

const destroy = id => axios.delete(`${LINE_ITEMS_URL}/${id}`);

const bulkDelete = payload =>
  axios.post(`${LINE_ITEMS_URL}/bulk_delete`, payload);

const bulkUpdate = payload =>
  axios.post(`${LINE_ITEMS_URL}/bulk_update`, { payload });

const create = payload => axios.post(`${LINE_ITEMS_URL}`, payload);

const bulkCreate = payload =>
  axios.post(`${LINE_ITEMS_URL}/bulk_create`, payload);

const lineItemsApi = {
  fetch,
  destroy,
  bulkDelete,
  bulkUpdate,
  create,
  bulkCreate,
};

export default lineItemsApi;
