import React from "react";

import { Radio } from "neetoui";

const RadioButton = ({ title, onSelect, options, value }) => (
  <Radio
    label={title}
    containerClassName="custom-radio mb-8"
    onChange={event => onSelect(parseInt(event.target.value))}
    value={value}
  >
    {options.map((option, index) => (
      <Radio.Item
        key={index}
        label={option.title}
        name={title}
        value={option.id}
      />
    ))}
  </Radio>
);

export default RadioButton;
