import axios from "axios";

const BASE_URL = "api/v1";
const CARTS_URL = `${BASE_URL}/carts`;

const fetch = () => axios.get(CARTS_URL);

const cartsApi = {
  fetch,
};

export default cartsApi;
