import React from "react";

import {
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const Form = () => {
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js hasn't yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return <>Loading...</>;
  }

  return (
    <form>
      <AddressElement
        options={{
          mode: "billing",
          autocomplete: {
            mode: "automatic",
          },
          allowedCountries: ["US", "CA"],
          blockPoBox: true,
          display: {
            name: "split",
          },
          fields: {
            phone: "always",
          },
        }}
      />
    </form>
  );
};

export default Form;
