import React from "react";

import { Checkbox } from "neetoui";

import Card from "components/Common/Card";

const ShippingMethod = ({
  shippingMethod,
  setShippingMethod,
  // expressShipping,
}) => {
  const onClick = value => setShippingMethod(value);
  return (
    <Card title="Shipping included" className="mb-8">
      <div className="mb-4 flex justify-between text-xs font-semibold text-gray-500">
        <Checkbox
          label="Standard shipping"
          checked={shippingMethod === 1}
          onChange={() => onClick(1)}
        />
      </div>
      {/* {!!expressShipping && (
        <div className="mb-4 flex justify-between text-xs font-semibold text-gray-500">
          <Checkbox
            label="Express shipping"
            checked={shippingMethod === 2}
            onChange={() => onClick(2)}
          />
          <span>${(expressShipping / 100).toFixed(2)}</span>
        </div>
      )} */}
    </Card>
  );
};

export default ShippingMethod;
