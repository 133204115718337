import axios from "axios";

const BASE_URL = "/api/v1/public/contacts";

const create = ({ firstName, lastName, email, phone }) =>
  axios.post(BASE_URL, {
    contact: {
      firstName,
      lastName,
      email,
      phone,
    },
  });

const statusUpdate = ({ email }) =>
  axios.post(`${BASE_URL}/status_update`, {
    contact: {
      email,
    },
  });

const contactApi = { create, statusUpdate };

export default contactApi;
