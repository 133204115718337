import React from "react";

import classNames from "classnames";
import { Button } from "neetoui";

import { CATALOGUE_PATH } from "components/routeConstants";
import ArrowSvg from "images/arrow-back.svg";

const Header = ({ handleMapNameChange, mapName, error, catalogueData }) => (
  <div className="sidebar-header flex w-full border border-[#E4E5E6]">
    <div className="flex w-3/12 items-center border-r border-[#E4E5E6] px-4 py-4">
      <div className="w-full">
        <input
          className={classNames(
            "outline-none w-full px-5 py-3 font-semibold text-blue-500",
            {
              "ring-offset-slate-50 dark:ring-offset-slate-900 ring ring-red-500 ring-offset-2":
                error,
            }
          )}
          placeholder="Map name"
          onChange={e => handleMapNameChange(e.target.value)}
          value={mapName}
        />
      </div>
    </div>
    <div className="flex w-9/12 justify-between px-4 py-4">
      <div className="flex items-center">
        <Button
          icon={ArrowSvg}
          iconPosition="left"
          className="px-2.5 text-xs font-normal text-gray-500"
          style="link"
          to={CATALOGUE_PATH}
          label="Back to Catalog"
        />
        <div className="px-2.5">
          <div className="text-sm font-bold text-black">
            {catalogueData?.title}
          </div>
          <div className="text-xs font-normal text-gray-400">
            {catalogueData?.brand}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {};

export default Header;
