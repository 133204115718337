import React from "react";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "rc-slider/assets/index.css";

import { AuthProvider } from "contexts/auth";
import { MapProvider } from "contexts/map";
import { ProductProvider } from "contexts/product";
import { UserProvider } from "contexts/user";

import Main from "./components/Main";

const App = props => (
  <AuthProvider>
    <UserProvider>
      <MapProvider>
        <ProductProvider>
          <Main {...props} />
        </ProductProvider>
      </MapProvider>
    </UserProvider>
  </AuthProvider>
);

export default App;
