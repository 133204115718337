import React from "react";

// import DarkMapSvg from "images/dark-map.svg";
// import DefaultMapSvg from "images/default-map.svg";
// import LightMapSvg from "images/lightMap.svg";
// import SatelliteMapSvg from "images/satelliteMap.svg";

import LayersList from "./LayersList";

import AdminLayersList from "../AdminLayersList";

const LayersDropDown = () => (
  <>
    <LayersList />
    <AdminLayersList />
    {/* <div className="title mb-2">Background map</div>
    <div className="mb-2 flex ">
      <div className="mx-3 flex cursor-pointer flex-col items-center text-gray-500">
        <SatelliteMapSvg />
        <span className="mt-1 text-xs">Satellite</span>
      </div>
      <div className="mx-3 flex cursor-pointer flex-col items-center text-gray-500">
        <DefaultMapSvg />
        <span className="mt-1 text-xs">Default</span>
      </div>
      <div className="mx-3 flex cursor-pointer flex-col items-center text-gray-500">
        <DarkMapSvg />
        <span className="mt-1 text-xs">Dark</span>
      </div>
      <div className="mx-3 flex cursor-pointer flex-col items-center text-gray-500">
        <LightMapSvg />
        <span className="mt-1 text-xs">Light</span>
      </div>
    </div> */}
  </>
);

export default LayersDropDown;
