import React from "react";

import { Typography } from "neetoui";

const Details = ({ title, price }) => {
  return (
    <>
      <Typography style="h1" className="mb-8">
        {title}
      </Typography>
      <Typography style="h2" weight="normal" className="mb-8">
        ${price} USD
      </Typography>
      <div className="mb-8 inline-block">
        <Typography style="body3">
          <u>Shipping</u>{" "}
          <span className="text-gray-500">calculated at checkout.</span>
        </Typography>
      </div>
    </>
  );
};

export default Details;
