import React from "react";

import { Switch, Tooltip } from "neetoui";

const CustomSwitch = ({ label, onChange, checked, tooltipContent }) => (
  <div className="custom-switch text-gray-500">
    <Tooltip content={tooltipContent} followCursor="horizontal" position="top">
      <span>
        <Switch
          className="py-1"
          label={label}
          onChange={onChange}
          checked={checked}
        />
      </span>
    </Tooltip>
  </div>
);

export default CustomSwitch;
