import React, { useState } from "react";

import { LeftArrow } from "@bigbinary/neeto-icons";
import { Form, Formik } from "formik";
import { Button, Typography } from "neetoui";
import { Input } from "neetoui/formik";
import PropTypes from "prop-types";

import authenticationApi from "apis/authentication";
import {
  SIGNUP_PATH,
  SEND_RESET_PASSWORD_PATH,
  CATALOGUE_PATH,
} from "components/routeConstants";
import { useAuthDispatch } from "contexts/auth";
import { useProductState } from "contexts/product";
import { useUserDispatch } from "contexts/user";
import LogoSvg from "images/boundri-logo-black.svg";
import slider1 from "images/slider-1.png";

import {
  LOGIN_FORM_INITIAL_VALUES,
  LOGIN_FORM_VALIDATION_SCHEMA,
} from "./constants";

const Login = ({ history, location }) => {
  const [submitted, setSubmitted] = useState(false);
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();
  const { hasSavedProduct, locationPath } = useProductState();

  const handleSubmit = async ({ email, password }) => {
    try {
      const {
        data: { auth_token, user, is_admin, cart_id, customer_id, contact_id },
      } = await authenticationApi.login({ email, password });
      authDispatch({
        type: "LOGIN",
        payload: {
          auth_token,
          email,
          is_admin,
          cartId: cart_id,
          customerId: customer_id,
          contactId: contact_id,
        },
      });
      userDispatch({ type: "SET_USER", payload: { user } });
      const fromState = hasSavedProduct ? locationPath : location?.state?.from;
      history.push(fromState || CATALOGUE_PATH);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div className="login flex h-screen w-screen flex-row items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-100 p-6">
      <div className="mx-auto flex w-full items-center justify-center rounded-md border bg-white shadow sm:max-w-3xl">
        <div className="w-1/2">
          <Button
            style="text"
            icon={LeftArrow}
            to={CATALOGUE_PATH}
            className="ml-5"
            label="Back to catalog"
            iconPosition="left"
          />
          <Formik
            initialValues={LOGIN_FORM_INITIAL_VALUES}
            validateOnBlur={submitted}
            validateOnChange={submitted}
            onSubmit={handleSubmit}
            validationSchema={LOGIN_FORM_VALIDATION_SCHEMA}
          >
            {({ isSubmitting }) => (
              <Form className="w-full space-y-8 p-8">
                <LogoSvg className="mb-28" />
                <Typography style="h1" className="mb-10">
                  Login
                </Typography>
                <Input
                  className="custom-input"
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="oliver@example.com"
                  data-cy="login-email-text-field"
                />
                <Input
                  className="custom-input"
                  name="password"
                  label="Password"
                  type="password"
                  data-cy="login-password-text-field"
                />
                <Button
                  fullWidth
                  type="submit"
                  label="Login"
                  data-cy="login-submit-button"
                  className="mb-8 h-8 rounded-full"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={() => setSubmitted(true)}
                />
                <Button
                  className="text-xs"
                  label="Forgot password?"
                  style="link"
                  to={SEND_RESET_PASSWORD_PATH}
                  data-cy="forgot-password-link"
                />

                <div className="flex flex-row items-center justify-start space-x-1">
                  <p className="text-xs font-normal text-gray-600">
                    Do not have an account yet?
                  </p>
                  <Button
                    className="text-xs"
                    label="Register here"
                    style="link"
                    to={SIGNUP_PATH}
                    from={location?.state?.from}
                    data-cy="sign-up-link"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="w-1/2">
          <img src={slider1} className="rounded-r" />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
