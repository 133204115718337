import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import paymentApi from "apis/payments";
import { getFromLocalStorage } from "utils/storage";

import CheckoutForm from "./checkoutForm";

const stripePromise = loadStripe(process.env.STRIPE_KEY);

const StripePayment = ({ orderId, setIsLoading }) => {
  const [clientSecret, setClientSecret] = useState("");

  const createPaymentIntent = async () => {
    const response = await paymentApi.create({
      id: orderId,
      customer_id: getFromLocalStorage("customerId"),
    });
    setClientSecret(response?.data?.payment_intent.client_secret);
    setIsLoading(false);
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    createPaymentIntent();
  }, []);

  const options = {
    clientSecret,
  };

  if (!clientSecret) {
    return <div></div>;
  }

  return (
    <div id="stripe">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm orderId={orderId} />
      </Elements>
    </div>
  );
};

export default StripePayment;
