import { COORDINATE_REGEX } from "common/constants";

const coordinateFeature = (lng, lat) => ({
  center: [lng, lat],
  geometry: {
    type: "Point",
    coordinates: [lng, lat],
  },
  place_name: `Lat: ${lat} Lng: ${lng}`,
  place_type: ["coordinate"],
  properties: {},
  type: "Feature",
});

const coordinatesGeocoder = query => {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(COORDINATE_REGEX);
  if (!matches) {
    return null;
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geoCodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geoCodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geoCodes.push(coordinateFeature(coord2, coord1));
  }

  if (geoCodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geoCodes.push(coordinateFeature(coord1, coord2));
    geoCodes.push(coordinateFeature(coord2, coord1));
  }

  return geoCodes;
};

export { coordinatesGeocoder };
