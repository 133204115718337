import { INITIAL_FIELD_DETAILS } from "common/constants";
import { calculatePolygonArea } from "common/helpers";

const getCoOrdinates = draw => {
  const { features } = draw.getAll();
  let area = 0;
  if (features.length > 0) {
    area = calculatePolygonArea(features[0].geometry.coordinates);
  }

  return {
    ...INITIAL_FIELD_DETAILS,
    featureCollection: features[0] || null,
    area,
  };
};

// const handleMapClick = (
//   { target, point },
//   draw,
//   map,
//   isDrawPolygon,
//   callBack
// ) => {
//   const zoom = target.getZoom();
//   if (!isDrawPolygon && zoom > 9 && draw.getMode() !== "draw_polygon") {
//     const bbox = [
//       [point.x - 5, point.y - 5],
//       [point.x + 5, point.y + 5],
//     ];
//     const selectedFeatures = map.queryRenderedFeatures(bbox, {
//       layers: [
//         "New mexico",
//         "Colorado",
//         "Idaho",
//         "Kansas",
//         "Arizona",
//         "counties",
//       ],
//     });
//     const area = calculatePolygonArea(selectedFeatures[0].geometry.coordinates);
//     const details = {
//       county: selectedFeatures[1].properties.NAME,
//       townShipLabel: selectedFeatures[0].properties.TWNSHPLAB,
//       state: selectedFeatures[0].layer.id,
//       section: "",
//     };

//     callBack({
//       featureCollection: selectedFeatures[0],
//       area,
//       details,
//     });
//   }
// };

const showFieldsOnMap = (fields, map) => {
  if (map && fields.length) {
    fields.map(({ feature_collection: { geometry }, field_name, color }) => {
      const mapLayer = map.getLayer(field_name);
      if (typeof mapLayer !== "undefined") {
        // Remove map layer & source.
        map.removeLayer(field_name);
        map.removeLayer(`${field_name}-outline`);
        map.removeSource(field_name);
      }

      // Add a data source containing GeoJSON data.
      map.addSource(field_name, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry,
        },
      });

      // Add a new layer to visualize the polygon.
      map.addLayer({
        id: field_name,
        type: "fill",
        source: field_name, // reference the data source
        layout: {},
        paint: {
          "fill-color": color, // blue color fill
          "fill-opacity": 0.5,
        },
      });
      // Add a black outline around the polygon.
      map.addLayer({
        id: `${field_name}-outline`,
        type: "line",
        source: field_name,
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 1,
        },
      });
    });
  }
};

export { getCoOrdinates, showFieldsOnMap };
