import React, { Fragment } from "react";

import PlusSvg from "images/bluePlus.svg";
import TextSvg from "images/text.svg";

import Card from "./Card";

import ColorPicker from "../ColorPicker";

const ListCanvasObject = ({
  sideBarItems,
  selectCanvasObject,
  handleObjectPosition,
  setImageSize,
  canvas,
  addDesignClick,
  removeCanvasObject,
  background,
  setBackground,
  editImage,
}) => (
  <div className="px-6">
    <div className="text-base font-semibold">Layers</div>
    <ColorPicker background={background} setBackground={setBackground} />{" "}
    {sideBarItems.map((item, index) => (
      <Fragment key={item.type + index}>
        {!item.isDeleted &&
          (item.type === "image" ? (
            <Card
              onSelect={() => selectCanvasObject(item.object, index)}
              title={item.name}
              subTitle="Image layer"
              icon={TextSvg}
              handleObjectPosition={handleObjectPosition}
              setImageSize={setImageSize}
              canvas={canvas}
              index={index}
              removeCanvasObject={() => removeCanvasObject(index, item.object)}
              image={item.url}
              targetObject={item.object}
              isMapImage={item.mapId}
              editImage={() =>
                editImage(
                  item.mapId,
                  item.mapDetails,
                  item.name,
                  item.attachmentId
                )
              }
            />
          ) : (
            <Card
              title="Text layer"
              subTitle=""
              onSelect={() => selectCanvasObject(item.object, index)}
              icon={TextSvg}
              handleObjectPosition={handleObjectPosition}
              setImageSize={setImageSize}
              canvas={canvas}
              index={index}
              targetObject={item.object}
              removeCanvasObject={() => removeCanvasObject(index, item.object)}
            />
          ))}
      </Fragment>
    ))}
    <Card
      title="Add design"
      subTitle="Print area"
      onSelect={addDesignClick}
      showDot={false}
      icon={PlusSvg}
      showOptions={false}
      borderStyleDashed
    />
  </div>
);

export default ListCanvasObject;
