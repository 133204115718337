import React from "react";

import { Input, Button } from "neetoui";

import Card from "components/Common/Card";

const PromoCode = ({
  promoCode,
  onPromoCodeChange,
  applyPromoCode,
  isLoadingPromoCode,
}) => (
  <Card title="Promo code" className="mb-8">
    <div className="flex">
      <Input
        className="custom-input mr-3 w-2/3"
        value={promoCode}
        onChange={onPromoCodeChange}
      />
      <Button
        className="rounded-full"
        label="Apply"
        onClick={applyPromoCode}
        loading={isLoadingPromoCode}
        disabled={promoCode.length === 0}
      />
    </div>
  </Card>
);

export default PromoCode;
