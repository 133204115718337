import { useMemo, useEffect, useState } from "react";

import { allCountries } from "country-region-data";
import { useFormikContext } from "formik";

const useCountryList = () => {
  const [initial, setInitial] = useState(true);
  const {
    values: { country },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (country) {
      if (!initial) {
        setFieldValue("region", null);
      } else {
        setInitial(false);
      }
    }
  }, [country]);

  const countryList = useMemo(
    () =>
      allCountries
        .filter(country => country[1] === "US" || country[1] === "CA")
        .map(country => ({
          label: country[0],
          value: country[1],
        })),
    []
  );

  const regionList = useMemo(() => {
    const selectedCountry = allCountries.find(
      item => item[1] === (country.value || country)
    );

    return selectedCountry
      ? selectedCountry[2].map(region => ({
          label: region[0],
          value: region[1],
        }))
      : [];
  }, [country]);

  return {
    countryList,
    regionList,
  };
};

export default useCountryList;
