import React, { useState, useEffect } from "react";

import { Form, Formik } from "formik";
import { Button as CancelButton } from "neetoui";
import { Input, Button, Select, Textarea } from "neetoui/formik";
import PropTypes from "prop-types";

import fieldsApi from "apis/fields";
import groupsApi from "apis/groups";
import landCategoryApi from "apis/landCategory";
import regionsApi from "apis/regions";
import { useMapState } from "contexts/map";

import CropInput from "./ColorPicker/CropInput";
import { FIELD_FORM_VALIDATION_SCHEMA } from "./validations";

const INITIAL_VALUES = {
  filedLabel: "",
  state: "",
  county: "",
  townshipLabel: "",
  section: "",
  growerId: "",
  farmId: "",
  color: "#ffffff",
  note: "",
  landCategory: null,
};

const AddFieldModal = ({ onClose }) => {
  const [growers, setGrowers] = useState([]);
  const [farms, setFarms] = useState([]);
  const [landCategories, setLandCategories] = useState([]);

  const {
    options: { isDrawPolygon },
    fieldDetails: { area, featureCollection, details },
  } = useMapState();

  const loadLandCategories = async () => {
    try {
      const {
        data: { land_categories },
      } = await landCategoryApi.fetch();

      const formattedValues = land_categories.map(category => ({
        value: category.id,
        label: category.name,
        color: category.color,
        type: category.land_type,
      }));
      setLandCategories(formattedValues);
    } catch (err) {
      logger.error(err);
    }
  };

  const getInitialData = async () => {
    try {
      const { data: grower } = await groupsApi.fetch();
      const { data: farms } = await regionsApi.fetch();
      setGrowers(grower.map(item => ({ label: item.title, value: item.id })));
      setFarms(farms.map(item => ({ label: item.title, value: item.id })));
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    loadLandCategories();
    getInitialData();
  }, []);

  const handleSubmit = async values => {
    try {
      const payload = {
        ...values,
      };
      await fieldsApi.create(payload);
      onClose();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div className="mt-3 mb-3">
      <div className="title mb-5">Filed info</div>
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
          area,
          featureCollection,
          ...details,
        }}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        validationSchema={FIELD_FORM_VALIDATION_SCHEMA}
      >
        {({ handleSubmit, setFieldValue, values: { landCategory, color } }) => (
          <Form className="grid grid-cols-2 gap-2" onSubmit={handleSubmit}>
            <Input
              className="col-span-2"
              label="Field label"
              name="fieldLabel"
            />
            {!isDrawPolygon && (
              <>
                <Input
                  className="col-span-2"
                  label="State"
                  name="state"
                  disabled
                />
                <Input
                  className="col-span-2"
                  label="County"
                  name="county"
                  disabled
                />
                <Input label="Township label" name="townShipLabel" disabled />
                <Input label="Section" name="section" disabled />
              </>
            )}
            <Select
              className="col-span-2"
              label="Grower"
              name="growerId"
              options={growers}
              strategy="fixed"
              placeholder="Select"
              onChange={option => setFieldValue("growerId", option.value)}
            />
            <Select
              className="col-span-2"
              label="Farm"
              name="farmId"
              options={farms}
              strategy="fixed"
              placeholder="Select"
              onChange={option => setFieldValue("farmId", option.value)}
            />
            <CropInput
              landCategory={landCategory}
              landCategories={landCategories}
              updatedLandCategory={landCategory => {
                setFieldValue("color", color);
                setFieldValue("landCategory", landCategory);
              }}
            />
            <Textarea className="col-span-2" label="Note" name="note" />
            <div className="s col-span-2 mt-1 flex items-center justify-center">
              <CancelButton
                label="Cancel"
                style="secondary"
                className="mr-2 rounded-full"
                onClick={onClose}
              />
              <Button label="Ok" className="rounded-full" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

AddFieldModal.propTypes = { onClose: PropTypes.func };

export default AddFieldModal;
