import React from "react";

import { Button } from "@bigbinary/neetoui";
import PropTypes from "prop-types";

import dummyImage from "images/catalogue-image.jpg";

const ProductCard = ({ title, minPrice, size, images, handleOnClick }) => (
  <div onClick={handleOnClick}>
    <div
      className="relative"
      style={{ minHeight: 300, minWidth: 300, background: "#fafafa" }}
    >
      <img src={images[0]} alt={dummyImage} />
    </div>
    <div className="p-4">
      <div className="mb-1 text-xs font-normal text-gray-600">{title}</div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="mb-1 text-sm">
            From <strong>USD {minPrice}</strong>
          </div>
          <div className="text-xs font-normal text-gray-400">
            {size} {size > 1 ? "sizes" : "size"}
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button label="Build" className="rounded-full" />
        </div>
      </div>
    </div>
  </div>
);

ProductCard.props = {
  title: PropTypes.string,
  size: PropTypes.number,
  minPrice: PropTypes.number,
  handleOnClick: PropTypes.func,
};

export default ProductCard;
