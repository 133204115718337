import axios from "axios";

const BASE_URL = "api/v1/printify/shops";

const fetch = () => axios.get(BASE_URL);

const shopApi = {
  fetch,
};

export default shopApi;
