import React from "react";

import Carousel from "components/Common/Carousel";
import comingSoon from "images/coming soon.jpg";
import image1 from "images/comingSoon1";
import image2 from "images/comingSoon2";
import image3 from "images/comingSoon3";
import image4 from "images/comingSoon4";

import InvertedText from "./InvertedText";

const ComingSoon = () => (
  <div
    className="coming-soon py-24"
    style={{ background: `url("${comingSoon}")` }}
  >
    <div className="flex pl-16">
      <InvertedText title="How it works" />
      <div className="m-auto w-3/4 pr-14">
        <div className="flex flex-col items-center">
          <div className="mb-14 text-3xl font-semibold">Coming Soon</div>
          <div className="mb-5 text-center text-xs text-gray-500">
            Browse our online store to select some of available products with
            maps. Cups, rugs, wall art, pillows, lamps, blankets and other items
            will bring a unique touch to your space.
          </div>
        </div>
      </div>
    </div>
    <div className="m-auto w-3/4 px-20">
      <Carousel
        images={[
          {
            src: image1,
          },
          {
            src: image2,
          },
          {
            src: image3,
          },
          {
            src: image4,
          },
        ]}
        width={300}
        height={400}
        visibleSlides={4}
      />
    </div>
  </div>
);

export default ComingSoon;
