import React from "react";

import { useHistory } from "react-router-dom";

import { PRODUCT_SHIPPING_PATH } from "components/routeConstants";

const RecipientInfo = ({ address, orderId }) => {
  const history = useHistory();

  const redirectToShipping = () => {
    history.push(`${PRODUCT_SHIPPING_PATH}/${orderId}`);
  };
  return (
    <div className="recipient-info p-7">
      <div className="mb-7">
        <div className="mb-7 text-base font-bold">Recipient info</div>
        <div className="flex justify-between">
          <div className="mb-2.5 text-sm font-medium">Personal details</div>
          <div className="cursor-pointer" onClick={redirectToShipping}>
            Edit
          </div>
        </div>
        <span className="text-xs text-gray-500">{`${address?.first_name} ${address?.last_name}`}</span>
        <br />
        <span className="text-xs text-gray-500">{address?.email}</span>
        <br />
        <span className="text-xs text-gray-500">{address?.phone}</span>
      </div>
      <div>
        <div className="flex justify-between">
          <div className="mb-3 mb-2.5 text-sm font-medium">Shipping info</div>
          <div className="cursor-pointer" onClick={redirectToShipping}>
            Edit
          </div>
        </div>
        <span className="text-xs text-gray-500">{address?.address1}</span>
        <br />
        <span className="text-xs text-gray-500">
          {`${address?.city}, ${address?.region} ${address?.zip}, ${address?.country}`}
        </span>
      </div>
    </div>
  );
};

export default RecipientInfo;
