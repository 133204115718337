import React from "react";

import { Route, Switch } from "react-router-dom";

import Header from "components/Common/Header";
import Sidebar from "components/Common/Sidebar";
import { DASHBOARD_ROUTES } from "components/routeConstants";

const Dashboard = () => (
  <div className="h-screen w-full">
    <Header />
    <div className="flex">
      <Sidebar />
      <Switch>
        {DASHBOARD_ROUTES.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
      </Switch>
    </div>
  </div>
);

export default Dashboard;
