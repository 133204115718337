import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  country: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required("Required")
    .nullable(),
  region: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required("Required")
    .nullable(),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  zipCode: yup.string().required("Required"),
});

export { VALIDATION_SCHEMA };
