import * as yup from "yup";

const VALIDATION_SCHEMA = yup.object().shape({
  country: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required("Required")
    .nullable(),
  region: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required("Required")
    .nullable(),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  zip: yup.string().required("Required"),
});

export { VALIDATION_SCHEMA };
