import React from "react";

import classNames from "classnames";
import { Dropdown, Input } from "neetoui";

import DropDownSvg from "images/dropDownArrow.svg";

import { DEFAULT_COLORS } from "./constant";

const ColorPicker = ({
  background,
  setBackground,
  showLabel = true,
  noBorder = false,
}) => (
  <Dropdown
    position="bottom"
    closeOnSelect={false}
    customTarget={
      <div
        className={classNames(
          "my-4 flex cursor-pointer items-center justify-between rounded  bg-white px-5",
          {
            "border-gray border py-4 ": !noBorder,
          }
        )}
      >
        <div className="flex items-center">
          <div
            className="color-block mr-2"
            style={{ background: `#${background}` }}
          ></div>
          {showLabel && <div>Background</div>}
        </div>
        <DropDownSvg />
      </div>
    }
    label="Custom Dropdown"
  >
    <div className="flex w-96 flex-wrap rounded-md p-3">
      {DEFAULT_COLORS.map(color => (
        <div
          key={color.color}
          className="color-block mr-2 cursor-pointer"
          style={{ background: `#${color.color}` }}
          onClick={() => setBackground(color.color)}
        ></div>
      ))}
      <Input
        value={background}
        prefix={<div>#</div>}
        className="custom-input mt-2"
        onChange={e => setBackground(e.target.value)}
      />
    </div>
  </Dropdown>
);

export default ColorPicker;
