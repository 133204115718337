import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1/library_images";

const fetch = params => axios.get(`${BASE_URL}?${qs.stringify(params)}`);

const create = formData =>
  axios.post(`${BASE_URL}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const destroy = id => axios.delete(`${BASE_URL}/${id}`);

export default { create, fetch, destroy };
