import React from "react";

import { Edit, Delete } from "@bigbinary/neeto-icons";
import { Typography, Button } from "neetoui";

import FieldInfo from "./FieldInfo";

const FieldCards = ({ field, handleEdit, handleDelete }) => (
  <div className="mb-4 w-full space-y-6 rounded-lg border bg-white p-8 shadow-sm">
    <div className="flex justify-between">
      <Typography style="h2">{field.field_name}</Typography>
      <div>
        <Button
          className="rounded"
          icon={() => <Edit size={24} />}
          style="text"
          onClick={() => handleEdit(field)}
        />
        <Button
          className="rounded"
          icon={() => <Delete size={23} />}
          style="text"
          onClick={() => handleDelete(field)}
        />
      </div>
    </div>
    <div className="grid grid-cols-2 gap-4">
      {field.crop && <FieldInfo name="Crop" value={field.crop} />}
      {field.field_area && <FieldInfo name="Area" value={field.field_area} />}
      {field.field_shape && (
        <FieldInfo name="Shape" value={field.field_shape} />
      )}
      <FieldInfo name="Color" value={field.color} />
    </div>
  </div>
);

export default FieldCards;
