import React from "react";

import backgroundImage from "images/footer-bg.jpg";
import FbIcon from "images/ftr-icon-fb.svg";
import InIcon from "images/ftr-icon-in.svg";
import PnIcon from "images/ftr-icon-pn.svg";
import XIcon from "images/ftr-icon-tw.svg";
import FooterLogo from "images/logo-footer.svg";
import StripLogo from "images/stripe-logo.svg";

const Footer = () => (
  <div className="common">
    <footer
      style={{
        background: `#f5efe6 url(${backgroundImage}) repeat-x top center`,
      }}
    >
      <div className="container">
        <div className="ftr-menus flex">
          <div className="w-1/4">
            <h4>Browse</h4>
            <ul className="ftr-menu">
              <li>
                <a href="https://builder.boundri.com/">Product builder</a>
              </li>
              <li>
                <a
                  href="https://boundri.com/shop/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Online store
                </a>
              </li>
            </ul>
          </div>

          <div className="w-1/4">
            <h4>Mapping license</h4>
            <ul className="ftr-menu">
              <li>
                <a
                  href="https://www.mapbox.com/about/maps/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mapbox
                </a>
              </li>
              <li>
                <a
                  href="https://www.openstreetmap.org/copyright/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Open Street Map
                </a>
              </li>
            </ul>
          </div>

          <div className="w-1/4">
            <h4>Quick links</h4>
            <ul className="ftr-menu">
              <li>
                <a
                  href="https://boundri.com/basic-rules/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Basic Rules
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/faq/"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/about-us/"
                  target="_blank"
                  rel="noreferrer"
                >
                  About us
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </li>
            </ul>
          </div>

          <div className="w-1/4">
            <h4>Legal</h4>
            <ul className="ftr-menu">
              <li>
                <a
                  href="https://boundri.com/data-protection-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Data protection policy
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/refund_returns/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Return Policy
                </a>
              </li>
              <li>
                <a
                  href="https://boundri.com/shipping-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Shipping Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="ftr-info flex">
          <div className="ftr-logo w-1/4">
            <FooterLogo />
          </div>
          <div className="w-3/4">
            <p>
              We get mapping and printing. Based out of North Dakota, we’ve
              spent 20 plus years working with maps of all types, especially
              satellite imagery. We have a rich history of Agriculture coming
              from the farm and also providing technical products to all in Ag
              Business for years. We strive to provide unique products all will
              enjoy. Visit our social sites to learn more.
            </p>
          </div>
        </div>

        <div className="ftr-copy flex">
          <div className="w-1/4">
            <div className="ftr-social">
              <a
                className="ftr-icon-fb"
                href="https://www.facebook.com/boundri"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FbIcon />
              </a>
              <a
                className="ftr-icon-tw"
                href="https://twitter.com/boundrimaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <XIcon />
              </a>
              <a
                className="ftr-icon-pn"
                href="https://www.pinterest.com/BoundriMaps/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PnIcon />{" "}
              </a>
              <a
                className="ftr-icon-in"
                href="https://www.instagram.com/boundrimaps/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InIcon />
              </a>
            </div>
          </div>
          <div className="ftr-powered w-1/4">
            <p>
              Powered by: <StripLogo />
            </p>
          </div>
          <div className="w-2/4">
            <p>© 2022-2023, Boundri. All rights reserverd.</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
);
export default Footer;
