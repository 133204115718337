import React, { useState } from "react";

import { Form, Formik } from "formik";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";
import { useParams, useLocation, useHistory } from "react-router-dom";

import passwordApi from "apis/password";
import { LOGIN_PATH, SIGNUP_PATH } from "components/routeConstants";

import {
  RESET_FORM_INITIAL_VALUES,
  PASSWORD_RESET_VALIDATION,
} from "./constants";

const ResetPasswordForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const { id: token } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  const resetPassword = async ({ password }) => {
    const email = params.get("email");
    await passwordApi.resetMail({ email, password, token });
    history.push(LOGIN_PATH);
  };

  return (
    <div className="flex h-screen w-screen flex-row items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-100 p-6">
      <div className="mx-auto flex h-full w-full flex-col items-center justify-center sm:max-w-md">
        <h2 className="mb-5 text-center text-3xl font-extrabold text-gray-800">
          Reset your password
        </h2>
        <Formik
          initialValues={RESET_FORM_INITIAL_VALUES}
          validateOnBlur={submitted}
          validateOnChange={submitted}
          onSubmit={resetPassword}
          validationSchema={PASSWORD_RESET_VALIDATION}
        >
          {({ isSubmitting }) => (
            <Form
              className="w-full space-y-6 rounded-md border bg-white p-8 shadow"
              id="new_user"
            >
              <Input
                name="password"
                label="Password"
                type="password"
                required
              />
              <Input
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                required
              />
              <div className="flex flex-col items-center justify-center space-y-2">
                <Button
                  fullWidth
                  className="h-8"
                  type="submit"
                  label="Reset password"
                  data-disable-with="Reset password"
                  onClick={() => setSubmitted(true)}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
                <Button label="Back" style="link" to={LOGIN_PATH} />
              </div>
            </Form>
          )}
        </Formik>
        <div className="mt-4 flex flex-row items-center justify-start space-x-1">
          <p className="font-normal text-gray-600">{`Don't have an account?`}</p>
          <Button label="Signup" style="link" to={SIGNUP_PATH} />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
