const userReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_PRODUCT_CANVAS_OBJECT": {
      return {
        ...state,
        ...payload,
      };
    }
    case "REMOVE_CANVAS_OBJECTS": {
      return {
        ...state,
        canvasObjects: null,
        bluePrintId: null,
        catalogueId: null,
        productId: null,
        activeVariant: null,
        selectedVariants: null,
        attachmentId: null,
      };
    }
    case "UPDATE_CANVAS_OBJECTS": {
      return {
        ...state,
        canvasObjects: payload,
      };
    }

    case "UPDATE_PRODUCT_NAME": {
      return {
        ...state,
        name: payload,
      };
    }

    case "INITIALIZE_PRODUCT": {
      return {
        ...state,
        canvasObjects: null,
        bluePrintId: null,
        catalogueId: null,
        productId: null,
        canvasWidth: null,
        activeVariant: null,
        selectedVariants: null,
        attachmentId: null,
        name: "",
      };
    }

    case "REMOVE_SAVED_PRODUCT":
      return {
        ...state,
        hasSavedProduct: false,
        locationPath: null,
      };

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default userReducer;
