import axios from "axios";

const BASE_URL = "api/v1";
const GROUPS_URL = `${BASE_URL}/groups`;

const fetch = () => axios.get(GROUPS_URL);

const create = payload => axios.post(GROUPS_URL, { group: payload });

const update = (id, payload) =>
  axios.put(`${GROUPS_URL}/${id}`, { group: payload });

const groupsApi = {
  fetch,
  create,
  update,
};

export default groupsApi;
