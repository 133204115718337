import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import CheckSvg from "images/check.svg";

const CropLabel = ({ color, label, handleColorClick, selected }) => (
  <div
    className={classNames("item flex items-center py-1 px-2 py-1", {
      "item-active": selected,
    })}
    onClick={handleColorClick}
  >
    <div
      className="color-block mr-2 flex items-center justify-center"
      style={{ background: color }}
    >
      {selected && <CheckSvg />}
    </div>
    <div
      className={classNames("name", {
        inactive: !label,
      })}
    >
      {label || "Select crop"}
    </div>
  </div>
);

CropLabel.defaultProps = {
  color: "#fff",
  label: null,
  handleColorClick: () => {},
  selected: false,
};

CropLabel.propTypes = {
  color: PropTypes.string,
  label: PropTypes.oneOf(["string", null]),
  handleColorClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default CropLabel;
