import * as yup from "yup";

export const VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required("Required"),
  description: yup.string(),
  group: yup.object().shape({
    value: yup.string().required("Required"),
  }),
});

export const COLUMNS = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 75,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: 150,
  },
  {
    title: "Group",
    dataIndex: "group",
    key: "group",
    width: 150,
    render: (_, row) => row.group.title,
  },
];
