import React from "react";

import { getHeightWidth } from "./helper";

const BoundingBox = ({ pageSize, orientation }) => {
  const { width, height } = getHeightWidth(pageSize, orientation);
  return (
    <div
      className="border-sky-300 pointer-events-none fixed rounded border"
      style={{
        width,
        height,
        left: `calc(50% - ${width / 2}px)`,
        top: `calc(50% - ${height / 2}px)`,
      }}
    />
  );
};

export default BoundingBox;
