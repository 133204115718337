import AboutUs from "components/AboutUs";
import AddMap from "components/AddMap";
import AddCatalog from "components/Admin/AddCatalog";
import Catalogs from "components/Admin/Catalogs";
import AdminDashboard from "components/Admin/Dashboard";
import Login from "components/Authentication/Login";
import PasswordReset from "components/Authentication/ResetPassword";
import PasswordResetForm from "components/Authentication/ResetPasswordForm";
import Signup from "components/Authentication/Signup";
import Catalogue from "components/Catalogue";
import ContactUs from "components/ContactUs";
import CustomRequest from "components/CustomRequest";
import Dashboard from "components/Dashboard";
import Gallery from "components/Gallery";
import MyDraft from "components/MyDraft";
import MyMaps from "components/MyMaps";
import MyOrders from "components/MyOrders";
import Details from "components/MyOrders/Details";
import OrderSuccess from "components/OrderSuccess";
import Payment from "components/Payment";
import PersonalDetails from "components/PersonalDetails";
import ProductCarts from "components/ProductCart";
import ProductDesign from "components/ProductDesign";
import ProductDetails from "components/ProductDetails";
import ProductReview from "components/ProductReview";
import ProductShipping from "components/ProductShipping";

export const MAP_PATH = "/maps";
export const CHANGE_PASSWORD_PATH = "/settings?tab=password";
export const PROFILE_PATH = "/settings?tab=profile";
export const SETTINGS_PATH = "/settings";
export const GROUP_PATH = "/groups";
export const REGION_PATH = "/regions";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const SEND_RESET_PASSWORD_PATH = "/my/password/new";
export const PRODUCT_DESIGN_PATH = "/product-design/:id";
export const ADD_MAP_PATH = "/add-map";
export const ADD_MAP_PATH_WITH_ID = "/add-map/:id";
export const CATALOGUE_PATH = "/catalog";
export const PRODUCT_DETAILS_PATH = "/product-details";
export const PRODUCT_CART_PATH = "/product-carts";
export const PRODUCT_SHIPPING_PATH = "/product-shipping";
export const PRODUCT_REVIEW_PATH = "/product-review";
export const PRODUCT_REVIEW_PATH_WITH_ID = "/product-review/:id";

export const PAYMENT_PATH = "/payment";
export const MY_MAPS_PATH = "/my-maps";
export const MY_ORDERS_PATH = "/my-orders";
export const MY_DRAFT_PATH = "/my-draft";
export const PERSONAL_DETAILS = "/personal-details";
export const GALLERY = "/my-library";
export const RESET_PASSWORD_PATH = "/reset-password/:id";
export const CONTACT_US = "/contact-us";
export const CUSTOM_REQUEST = "/custom-request";
export const ABOUT_US = "/about-us";
export const ORDER_SUCCESS = "/order/success";
export const ORDER_FAILED = "/order/failed";
export const ADMIN_PATH = "/admin";
export const CATALOGS = "/admin/catalogs";
export const ADD_CATALOG = "/admin/add-catalog";
export const EDIT_CATALOG = "/admin/edit-catalog/:id";

export const AUTH_ROUTES = [
  {
    path: SEND_RESET_PASSWORD_PATH,
    component: PasswordReset,
  },
  {
    path: RESET_PASSWORD_PATH,
    component: PasswordResetForm,
  },
  {
    path: SIGNUP_PATH,
    component: Signup,
  },
  {
    path: CONTACT_US,
    component: ContactUs,
  },
  {
    path: CUSTOM_REQUEST,
    component: CustomRequest,
  },
  {
    path: LOGIN_PATH,
    component: Login,
  },
  {
    path: CATALOGUE_PATH,
    component: Catalogue,
  },
  {
    path: PRODUCT_DETAILS_PATH,
    component: ProductDetails,
  },
  {
    path: ABOUT_US,
    component: AboutUs,
  },
  {
    path: ORDER_SUCCESS,
    component: OrderSuccess,
  },
  {
    path: PRODUCT_DESIGN_PATH,
    component: ProductDesign,
  },
  {
    path: ADD_MAP_PATH_WITH_ID,
    component: AddMap,
  },
];

export const PRIVATE_ROUTES = [
  {
    path: GALLERY,
    component: Gallery,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: PERSONAL_DETAILS,
    component: PersonalDetails,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: MY_DRAFT_PATH,
    component: MyDraft,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: `${MY_ORDERS_PATH}/:id`,
    component: Details,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: MY_ORDERS_PATH,
    component: MyOrders,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: MY_MAPS_PATH,
    component: MyMaps,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: `${PAYMENT_PATH}/:id`,
    component: Payment,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: `${PRODUCT_SHIPPING_PATH}/:id`,
    component: ProductShipping,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: PRODUCT_SHIPPING_PATH,
    component: ProductShipping,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: PRODUCT_CART_PATH,
    component: ProductCarts,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: PRODUCT_REVIEW_PATH_WITH_ID,
    component: ProductReview,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: PRODUCT_REVIEW_PATH,
    component: ProductReview,
    redirectRoute: LOGIN_PATH,
  },
  {
    path: ADMIN_PATH,
    component: AdminDashboard,
    redirectRoute: LOGIN_PATH,
  },
  { path: "/", component: Dashboard },
];

export const DASHBOARD_ROUTES = [
  {
    path: CATALOGS,
    component: Catalogs,
  },
  {
    path: ADD_CATALOG,
    component: AddCatalog,
  },
  {
    path: EDIT_CATALOG,
    component: AddCatalog,
  },
];
