import { isPresent } from "utils";

import { useAuthState } from "contexts/auth";
import { useUserState } from "contexts/user";

const useUserAuthenticated = () => {
  const { authToken } = useAuthState();
  const { user } = useUserState();

  const isLoggedIn = isPresent(authToken) && isPresent(user);
  return isLoggedIn;
};
export default useUserAuthenticated;
