import React from "react";

import { Button, Tooltip } from "neetoui";

import ImageSvg from "images/image.svg";
import TextSvg from "images/text.svg";

import SideBarNote from "./SideBarNote";

const AddObject = ({
  fileUploadRef,
  onAddTextClick,
  showCancelButton,
  onCancelClick,
}) => (
  <div className="flex flex-col justify-between px-3 py-2">
    <div>
      <div>
        <span className="font-semibold text-black">Add objects</span>
        <div className="my-4 flex justify-around">
          <Tooltip
            content="Add your own images from logos to other maps"
            followCursor="horizontal"
            position="top"
          >
            <div
              className="border-gray mr-1 flex w-1/2 cursor-pointer items-center justify-center rounded border bg-white py-4 text-xs font-medium text-gray-500"
              onClick={() => fileUploadRef.current.click()}
            >
              <ImageSvg />
              <span className="ml-3">Upload images</span>
            </div>
          </Tooltip>
          <Tooltip
            content="Add customized text fonts and size"
            followCursor="horizontal"
            position="top"
          >
            <div
              className="border-gray ml-1 flex w-1/2 cursor-pointer items-center justify-center rounded border bg-white py-4 text-xs font-medium text-gray-500"
              onClick={onAddTextClick}
            >
              <TextSvg />
              <span className="ml-3">Add text</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
    <SideBarNote />
    {showCancelButton && (
      <div className="flex justify-center pt-5">
        <Button
          label="Go back"
          onClick={onCancelClick}
          className="rounded-full"
          style="secondary"
        />
      </div>
    )}
  </div>
);

export default AddObject;
