import React from "react";

import groupsApi from "apis/groups";
import CreatGroupOrRegion from "components/Common/CreatGroupOrRegion";

import { VALIDATION_SCHEMA } from "./validations";

const AddArea = ({ onClose }) => {
  const handleSubmit = async payload => {
    try {
      await groupsApi.create(payload);
      onClose();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div className="add-grower-area mt-4 mb-5">
      <div className="title my-3">Add grower</div>
      <CreatGroupOrRegion
        onClose={onClose}
        handleSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      />
    </div>
  );
};

export default AddArea;
