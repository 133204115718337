import { resetAuthTokens, setAuthHeaders } from "apis/axios";
import { setToLocalStorage } from "utils/storage";

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case "LOGIN": {
      setToLocalStorage("authToken", payload.auth_token);
      setToLocalStorage("authEmail", payload.email);
      setToLocalStorage("cartId", payload.cartId);
      setToLocalStorage("customerId", payload.customerId);
      setToLocalStorage("contactId", payload.contactId);
      setToLocalStorage("is_admin", payload.is_admin);
      setAuthHeaders();
      return {
        isLoggedIn: true,
        authToken: payload.auth_token,
        authEmail: payload.email,
        is_admin: payload.is_admin,
      };
    }
    case "LOGOUT": {
      setToLocalStorage("authToken", null);
      setToLocalStorage("authEmail", null);
      setToLocalStorage("customerId", null);
      setToLocalStorage("contactId", null);
      setToLocalStorage("cartId", null);
      setToLocalStorage("is_admin", null);

      resetAuthTokens();
      return { isLoggedIn: false, authToken: null, authEmail: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default authReducer;
