import React from "react";

const Row = ({ title, value }) => (
  <>
    <div className="text-sm font-medium text-[#202020]">{title}</div>
    <div className="text-right text-xs text-gray-500">{value}</div>
  </>
);

export default Row;
