import React, { useState } from "react";

// import { Tab } from "neetoui";

import LoadFields from "./LoadFields";
import ManageFields from "./ManageFields";
// import Count from "./MapCount";

const Sidebar = () => {
  const [activeTab] = useState(1);

  return (
    <div className="sidebar-content flex w-3/12 border-r border-[#E4E5E6]">
      <div className="w-full">
        {/* <Tab className="sidebar-tab justify-around border-b border-[#E4E5E6]">
          <Tab.Item
            active={activeTab === 1}
            className="tab-item"
            onClick={() => setActiveTab(1)}
          >
            Manage fields
            <Count count={0} />
          </Tab.Item>
          <Tab.Item
            active={activeTab === 2}
            className="tab-item"
            onClick={() => setActiveTab(2)}
          >
            Load fields
            <Count count={253} />
          </Tab.Item>
        </Tab> */}
        <div className="tab-content map-design flex flex-col justify-between bg-white ">
          {activeTab === 1 && <ManageFields />}
          {activeTab === 2 && <LoadFields />}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
