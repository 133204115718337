import React, { useState } from "react";

import { Sidebar as NeetoUISidebar } from "neetoui/layouts";

import { SIDENAV_LINKS } from "./constants";

const Sidebar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  return (
    <NeetoUISidebar
      isCollapsed={isSidebarCollapsed}
      navLinks={SIDENAV_LINKS}
      onCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
    />
  );
};

export default Sidebar;
