import React from "react";

import { Input } from "neetoui";

import useQuantity from "hooks/useQuantity";

const Quantity = ({ value, setQuantity }) => {
  const { updateQuantity } = useQuantity({ setQuantity, quantity: value });
  return (
    <div className="mb-8">
      <div className="neeto-ui-label mb-3">Quantity:</div>
      <div className="flex items-center">
        <div
          className="border-grey-600 mx-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border pb-1.5 text-2xl font-medium"
          onClick={() => updateQuantity("sub")}
        >
          -
        </div>
        <div className="w-1/3">
          <Input
            value={value}
            onChange={e => setQuantity(parseInt(e.target.value))}
          />
        </div>
        <div
          className="border-grey-600 mx-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border pb-1.5 text-2xl font-medium"
          onClick={() => updateQuantity("add")}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default Quantity;
