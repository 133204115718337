import axios from "axios";

const BASE_URL = "api/v1/categories";

const fetch = () => axios.get(BASE_URL);

const categoriesApi = {
  fetch,
};

export default categoriesApi;
