import React, { useEffect, useState } from "react";

import { Spinner, Button } from "neetoui";
import { useLocation } from "react-router-dom";

import ordersApi from "apis/orders";
import { MY_ORDERS_PATH } from "components/routeConstants";
import CheckSvg from "images/blueTick.svg";

const OrderSuccess = () => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const createOrder = async (sessionId, orderId) => {
    setLoading(true);
    const {
      data: { order },
    } = await ordersApi.create({
      session_id: sessionId,
      status: "success",
      order_id: orderId,
    });

    setOrder(order);
    setLoading(false);
  };

  useEffect(() => {
    const sessionId = params.get("session_id");
    const orderId = params.get("order_id");
    createOrder(sessionId, orderId);
  }, []);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      {loading ? (
        <Spinner />
      ) : (
        <div className="border-grey-600 mb-12 flex w-1/4 flex-col items-center rounded border	px-3 py-5 shadow-lg">
          <CheckSvg />
          <div className="pt-3	text-xl font-bold">
            Order successfully created
          </div>
          <Button
            className="py-2 text-sm font-normal text-gray-500"
            label="View details"
            style="link"
            to={`${MY_ORDERS_PATH}/${order?.id}`}
          />
        </div>
      )}
    </div>
  );
};

export default OrderSuccess;
