import React, { useEffect, useState } from "react";

import { Button, Table } from "neetoui";
import { Container, Header, Scrollable } from "neetoui/layouts";

import groupsApi from "apis/groups";
import CreatGroupOrRegion from "components/Common/CreatGroupOrRegion";
import EditGroupOrRegion from "components/Common/EditGroupOrRegion";

import { VALIDATION_SCHEMA, COLUMNS } from "./constant";

const Groups = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [group, setGroup] = useState();

  const getGroupList = async () => {
    try {
      setIsLoading(true);
      const { data } = await groupsApi.fetch();
      setTableData(data);
      setIsLoading(false);
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  const onHandleCreate = async payload => {
    try {
      await groupsApi.create(payload);
      setShowModal(false);
      getGroupList();
    } catch (err) {
      logger.error(err);
    }
  };

  const onHandleUpdate = async payload => {
    try {
      await groupsApi.update(group?.id, payload);
      setGroup(undefined);
      getGroupList();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Container>
      <Header
        actionBlock={
          <Button
            label="Add Groups"
            size="large"
            onClick={() => setShowModal(true)}
          />
        }
        title="Groups"
      />
      <Scrollable className="w-full">
        <Table
          isLoading={isLoading}
          columnData={COLUMNS}
          rowData={tableData}
          defaultPageSize={10}
          currentPageNumber={pageNumber}
          handlePageChange={page => setPageNumber(page)}
          fixedHeight
          rowSelection={false}
          onRowClick={(event, record) => setGroup(record)}
        />
      </Scrollable>
      <CreatGroupOrRegion
        showModal={showModal}
        onClose={() => setShowModal(false)}
        handleSubmit={onHandleCreate}
        validationSchema={VALIDATION_SCHEMA}
      />
      {!!group && (
        <EditGroupOrRegion
          showPane={!!group}
          onClose={() => setGroup(undefined)}
          handleSubmit={onHandleUpdate}
          validationSchema={VALIDATION_SCHEMA}
          initialValues={group}
        />
      )}
    </Container>
  );
};

export default Groups;
