import React from "react";

import { Modal, Button, Typography, Table } from "neetoui";
import PropTypes from "prop-types";

const COLUMNS = [
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    render: (_, { options: { size } }) => size,
  },
  {
    title: "Cost",
    dataIndex: "price",
    key: "price",
    render: (_, { price }) => `$${price}`,
  },
];

const VarianceModal = ({
  showModal,
  setShowModal,
  printProviders,
  setSelectedVariants,
  selectedVariants,
}) => (
  <Modal
    isOpen={showModal}
    onClose={() => setShowModal(false)}
    size="large"
    className="rounded"
  >
    <Modal.Header>
      <Typography style="h2" id="dialog1Title">
        Product variants
      </Typography>
    </Modal.Header>
    <Modal.Body className="space-y-2">
      <div style={{ height: "50vh" }}>
        <Table
          columnData={COLUMNS}
          rowData={
            printProviders?.variants.map(variant => ({
              ...variant,
              provider: printProviders.title,
              printProviderId: printProviders.id,
            })) || []
          }
          onRowSelect={(selectedRowKeys, selectedRows) =>
            setSelectedVariants(selectedRows)
          }
          rowSelection
          fixedHeight
          defaultPageSize={printProviders?.variants.length || 0}
          selectedRowKeys={selectedVariants.map(variant => variant.id)}
        />
      </div>
    </Modal.Body>
    <Modal.Footer className="flex justify-end">
      <Button
        className="rounded-3xl px-8"
        label="close"
        onClick={() => setShowModal(false)}
      />
    </Modal.Footer>
  </Modal>
);

VarianceModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  printProviders: PropTypes.array,
};

export default VarianceModal;
