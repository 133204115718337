import React from "react";

import { useMapState } from "contexts/map";
import RedoSvg from "images/redo.svg";
import UndoSvg from "images/undo.svg";

const Content = ({ mapRef, map, angle, setAngle, size }) => {
  const {
    options: { showCanvasEdges },
  } = useMapState();

  const rotateMap = option => {
    const rotateToAngle = angle + (option === "right" ? 15 : -15);
    map.rotateTo(rotateToAngle, { duration: 2000 });
    setAngle(rotateToAngle);
  };

  return (
    <div className="relative w-9/12">
      <div className="map-container absolute top-20 left-3  z-10 opacity-50">
        <div
          className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-t bg-black"
          onClick={() => rotateMap("right")}
        >
          <UndoSvg className="fill-white" />
        </div>
        <div className="border border-gray-600" />
        <div
          className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-b bg-black"
          onClick={() => rotateMap("left")}
        >
          <RedoSvg className="fill-white" />
        </div>
      </div>
      {showCanvasEdges && (
        <div
          id="bbox-01"
          className="pointer-events-none absolute rounded border border-dashed border-sky-300"
          style={{
            width: size?.value?.width,
            height: size?.value?.height,
            left: `calc(50% - ${size?.value?.width / 2}px)`,
            top: `calc(50% - ${size?.value?.height / 2}px)`,
            zIndex: 1,
          }}
        />
      )}
      <div ref={mapRef} className="h-full w-full" />
    </div>
  );
};

export default Content;
