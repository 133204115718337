import React, { useRef } from "react";

import classNames from "classnames";

import { useOutSideClick } from "hooks/useOutSideClick";

const CustomOverlay = ({ onClose, children, size }) => {
  const ref = useRef(null);

  useOutSideClick({ ref, onClose });

  return (
    <div className="custom-map-sidebar-overlay">
      <div
        className={classNames(
          "content mx-auto my-1 overflow-auto rounded-lg bg-white px-5 shadow-lg",
          {
            "w-4/5": size === "large",
            "w-2/5": size === "small",
          }
        )}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
};

CustomOverlay.defaultProps = {
  onClose: () => {},
  size: "small",
};

export default CustomOverlay;
