import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import categoriesApi from "apis/categories";
import { CATALOGUE_PATH } from "components/routeConstants";
import menuTwo from "images/menu-2.jpg";

const ProductTab = () => {
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const getCategories = async () => {
    const { data } = await categoriesApi.fetch();
    setCategories(data);
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <li className="active">
      <a href="/">Product Builder</a>
      <div className="sub-menu-inn builder-instr catalogs">
        <div className="flex w-2/3 gap-4">
          {categories.map(category => (
            <div key={category?.id} className="w-1/3">
              <h3
                onClick={() =>
                  history.push(`${CATALOGUE_PATH}?category=${category?.id}`)
                }
              >
                {category?.name}
              </h3>
              <ul>
                {category?.subcategories.map(subcategory => (
                  <li
                    className="cursor-pointer hover:text-blue-500"
                    key={subcategory.id}
                    onClick={() =>
                      history.push(
                        `${CATALOGUE_PATH}?subcategory=${subcategory.id}`
                      )
                    }
                  >
                    {subcategory.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex w-1/3 justify-end">
          <img width="180" height="240" src={menuTwo} alt="" />
        </div>
      </div>
    </li>
  );
};

export default ProductTab;
