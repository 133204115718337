const DESIGN = "design";
const VARIANTS = "variants";

const DEFAULT_COLORS = [
  { color: "FFFFFF", title: "White" },
  { color: "98989A", title: "Grey" },
  { color: "000000", title: "Black" },
  { color: "EB1D26", title: "Red" },
  { color: "643B13", title: "Brown" },
  { color: "FF6E31", title: "Orange" },
  { color: "FFB100", title: "Yellow" },
  { color: "82CD47", title: "Green" },
  { color: "70F8BA", title: "Aquamarine" },
  { color: "FFD7D5", title: "Pale pink" },
  { color: "0D3B66", title: "Indigo" },
  { color: "731DD8", title: "Violet" },
];

export { DESIGN, VARIANTS, DEFAULT_COLORS };
