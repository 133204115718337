import React from "react";

import { Input } from "neetoui/formik";

import Card from "components/Common/Card";

const Recipient = () => (
  <Card title="Recipient" className="mb-8 w-2/3">
    <div className="grid grid-cols-2 gap-7">
      <Input className="custom-input" name="firstName" label="First name" />
      <Input className="custom-input" name="lastName" label="Last name" />
      <Input className="custom-input" name="email" label="Email" />
      <Input className="custom-input" name="phone" label="Phone" />
    </div>
  </Card>
);

export default Recipient;
