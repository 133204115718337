import React, { useState } from "react";

import { Form, Formik } from "formik";
import { Typography, Button } from "neetoui";
import { Input, Textarea } from "neetoui/formik";

import contactApi from "apis/contact";
import Header from "components/Common/Header";
import ImagePng from "images/illustration.png";

import { CONTACT_FORM_VALIDATION_SCHEMA } from "./constants";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  question: "",
};

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async values => {
    try {
      await contactApi.create(values);
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div className="h-screen w-full">
      <Header />
      <div className="contact-us container m-auto py-3 pl-36">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange={submitted}
          validateOnBlur={submitted}
          validationSchema={CONTACT_FORM_VALIDATION_SCHEMA}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="grid w-full grid-cols-2 gap-5">
                <div>
                  <div className="mb-8 flex items-center justify-between pt-10">
                    <Typography style="h2">Contact us</Typography>
                  </div>
                  <span>
                    Feel free to get a hold of us and ask us anything you are
                    curious about regarding our products and services.
                  </span>
                  <div className="mt-4 grid grid-cols-2 gap-5">
                    <Input
                      className="custom-input"
                      name="firstName"
                      label="First name"
                    />
                    <Input
                      className="custom-input"
                      name="lastName"
                      label="Last name"
                    />
                    <Input
                      className="custom-input"
                      name="email"
                      label="Email"
                    />
                    <Input
                      className="custom-input"
                      name="phone"
                      label="Phone number"
                    />
                    <Input
                      className="custom-input col-span-2"
                      name="address"
                      label="Address"
                    />
                    <Input className="custom-input col-span-2" />
                    <Textarea
                      className="custom-input col-span-2"
                      name="question"
                      label="Question"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <Button
                      type="submit"
                      label="Submit"
                      className="mt-4 h-8 rounded-full"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => setSubmitted(true)}
                    />
                  </div>
                </div>
                <img src={ImagePng} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
