import { fabric } from "fabric";

import { dataURItoBlob, getTargetDimension } from "common/helpers";

const POSITION = {
  1297: {
    printOnSide: {
      96941: {
        x: 0.5,
        y: 0.5,
        scale: 1.02,
      },
      96942: {
        x: 0.5,
        y: 0.5,
        scale: 1.035,
      },
      96943: {
        x: 0.5,
        y: 0.5,
        scale: 1.036,
      },
      96950: {
        x: 0.5,
        y: 0.5,
        scale: 1.036,
      },
      96952: {
        x: 0.5,
        y: 0.5,
        scale: 1.037,
      },
      96953: {
        x: 0.5,
        y: 0.5,
        scale: 1.037,
      },
      96954: {
        x: 0.5,
        y: 0.5,
        scale: 1.038,
      },
      96955: {
        x: 0.5,
        y: 0.5,
        scale: 1.038,
      },
    },
  },
  default: {
    x: 0.5,
    y: 0.5,
    scale: 1.0,
  },
};

const creteScaledCanvas = (canvas, originalWidth, originalHeight, objects) => {
  const scaledCanvas = new fabric.Canvas("scaledCanvas");
  // scaledCanvas.backgroundColor = `#${background}`;

  const canvasWidth = canvas.clipPath.width;
  const canvasHeight = canvas.clipPath.height;
  const scaleFactor = Math.min(
    getTargetDimension(originalWidth) / canvasWidth,
    getTargetDimension(originalHeight) / canvasHeight
  );

  const scaledHeight = Math.round(canvasHeight * scaleFactor);
  const scaledWidth = Math.round(canvasWidth * scaleFactor);

  scaledCanvas.setDimensions({
    width: scaledWidth,
    height: scaledHeight,
  });

  objects.forEach(element => {
    if (element.type !== "line") {
      element.set({
        left: element.left - canvas.clipPath.left,
        top: element.top - canvas.clipPath.top,
      });
      scaledCanvas.add(element);
    }
  });

  scaledCanvas.renderAll();

  scaledCanvas.setZoom(scaleFactor);

  const multiplier = originalWidth > 18000 ? 3 : 4;

  const url = scaledCanvas.toDataURL({
    format: "png",
    multiplier,
  });

  scaledCanvas.dispose();

  return url;
};

const base64String = "data:image/jpeg;base64,";

const getImage = (
  canvas,
  bluePrintId,
  originalWidth,
  originalHeight,
  background,
  callBack
) =>
  canvas.clone(clonedCanvas => {
    const objects = clonedCanvas.getObjects();
    const filteredObject = objects.filter(el => el.type !== "line");
    const url = creteScaledCanvas(
      canvas,
      originalWidth,
      originalHeight,
      filteredObject,
      background
    );
    const stringLength = url.length - base64String.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    callBack(dataURItoBlob(url), sizeInBytes);
  });

const getPosition = (bluePrintId, printOnSide, variantId) => {
  if (printOnSide && POSITION[bluePrintId]) {
    return (
      POSITION[bluePrintId]["printOnSide"][variantId] ?? POSITION["default"]
    );
  }

  return !!POSITION[bluePrintId] && !!POSITION[bluePrintId][variantId]
    ? POSITION[bluePrintId][variantId]
    : POSITION["default"];
};

const createProductPayload = ({
  bluePrintId,
  printProviderId,
  variants,
  status,
  productName,
  activeVariant,
  background,
  printOnSide,
}) => {
  const { x, y, scale } = getPosition(
    bluePrintId,
    printOnSide,
    activeVariant.id
  );

  return {
    title: productName,
    active_variant: activeVariant,
    blueprint_id: bluePrintId,
    print_provider_id: printProviderId,
    variants: variants.map(({ id, price, is_enabled = true }) => ({
      id,
      price: Math.ceil(price * 100),
      is_enabled,
    })),
    print_areas: [
      {
        variant_ids: variants.map(variant => variant.id),
        placeholders: [
          {
            position: "front",
            images: [
              {
                id: null,
                x,
                y,
                scale,
                angle: 0,
              },
            ],
          },
        ],
      },
    ],
    print_details: {
      print_on_side: printOnSide ? "regular" : "off",
    },
    status,
    background,
    print_on_side: !!printOnSide,
  };
};

const getAttachmentData = (objectList, canvas) => {
  const formData = new FormData();

  objectList.forEach(list => {
    const attachment = {
      name: list.name,
      top: list.object.top,
      left: list.object.left,
      blob: list.object.toDataURL({ format: "jpeg" }),
      layer: canvas.getObjects().indexOf(list.object),
      type: list.type,
      text: list.object.text,
      is_deleted: !!list.isDeleted,
    };
    if (list.attachmentId) {
      attachment.id = list.attachmentId;
    }
    formData.append("attachments[]", JSON.stringify(attachment));
  });

  return formData;
};

const formatPayload = (
  objectList,
  canvas,
  imagePayload,
  createPayload,
  oldImageId
) => {
  const formData = new FormData();
  const canvasObject = canvas.getObjects().filter(el => el.type !== "line");
  const width = canvas.clipPath.width;
  const height = canvas.clipPath.height;
  objectList.map(list => {
    const layer = canvasObject.indexOf(list.object);
    let left = "";
    let top = "";
    let id = "";
    const scaleX = list.object.scaleX;
    const scaleY = list.object.scaleY;
    let fill = "";
    let text = "";

    if (!list.isDeleted) {
      left = canvasObject[layer].left;
      top = canvasObject[layer].top;
      fill = canvasObject[layer].fill;
      text = canvasObject[layer].text;
      id = canvasObject[layer].id;
    }

    let attachment = {
      image_identifier: id,
      name: list.name || "text",
      top,
      left,
      layer,
      type: list.type,
      text,
      is_deleted: !!list.isDeleted,
      map_id: list.mapId,
      details: list.mapDetails,
      color: fill,
      scaleX,
      scaleY,
      lib_id: list.libId,
      without_login: !!list.blob,
      is_library: !!list.isLibrary,
      width,
      height,
    };

    if (list.attachmentId) {
      attachment.id = list.attachmentId;
    }

    if (list.blob) formData.append(`${id}`, list.blob || null);
    formData.append("attachments[]", JSON.stringify(attachment));
    attachment = {};
  });

  formData.append("file_name", imagePayload.file_name);
  formData.append("blob", imagePayload.blob);
  formData.append("product_payload", JSON.stringify(createPayload));
  formData.append("image_id", oldImageId);
  return formData;
};

const showPrintOnSides = ({ canvas, height, width }) => {
  const objects = canvas.getObjects();
  const padding = height * 0.04;

  const line = new fabric.Line([0, 0, width, 0], {
    strokeDashArray: [5, 5],
    stroke: "black",
    left: canvas.width / 2 - width / 2,
    top: canvas.height / 2 - height / 2 + padding,
    type: "line",
    selectable: false,
    id: "print-on-side",
  });

  const line1 = new fabric.Line([0, 0, width, 0], {
    strokeDashArray: [5, 5],
    stroke: "black",
    left: canvas.width / 2 - width / 2,
    top: canvas.height / 2 - height / 2 + height - padding,
    type: "line",
    selectable: false,
    id: "print-on-side",
  });

  const line2 = new fabric.Line([0, 0, 0, height], {
    strokeDashArray: [5, 5],
    stroke: "black",
    left: canvas.width / 2 - width / 2 + padding,
    top: canvas.height / 2 - height / 2,
    type: "line",
    selectable: false,
    id: "print-on-side",
  });

  const line3 = new fabric.Line([0, 0, 0, height], {
    strokeDashArray: [5, 5],
    stroke: "black",
    left: canvas.width / 2 - width / 2 + width - padding,
    top: canvas.height / 2 - height / 2,
    type: "line",
    selectable: false,
    id: "print-on-side",
  });
  canvas.insertAt(line3, objects.length);
  canvas.insertAt(line2, objects.length);
  canvas.insertAt(line1, objects.length);
  canvas.insertAt(line, objects.length);

  canvas?.renderAll();
};

const getCatalogDetails = variant => {
  const originalHeight = variant.placeholders[0].height;
  const originalWidth = variant.placeholders[0].width;
  const canvasContainer = document.getElementById("canvas-container");

  const srcHeight = canvasContainer?.clientHeight / 1.5;
  const srcWidth = canvasContainer?.clientWidth / 1.5;

  const scaleFactor = Math.min(
    srcHeight / originalHeight,
    srcWidth / originalWidth
  );

  const height = Math.round(originalHeight * scaleFactor);
  const width = Math.round(originalWidth * scaleFactor);

  return { scaleFactor, height, width };
};

const updatePrintOnSideBorder = (canvas, height, width) => {
  const objects = canvas.getObjects();
  objects.forEach(element => {
    if (element.id === "print-on-side") canvas.remove(element);
  });
  showPrintOnSides({ canvas, height, width });
};

export const starShape = () => {
  const numberOfPoints = 5;
  const outerRadius = 240;
  const innerRadius = 100;

  // Calculate the points for the star shape
  const points = [];
  for (let i = 0; i < numberOfPoints * 2; i++) {
    const radius = i % 2 === 0 ? outerRadius : innerRadius;
    const angle = (Math.PI * 2 * i) / numberOfPoints - Math.PI / 2; // Shift angle by 90 degrees
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    points.push({ x, y });
  }

  // Create a Fabric.js star shape
  const clipPath = new fabric.Polygon(points, {
    fill: "transparent",
    stroke: "black",
    strokeWidth: 2,
    left: 40,
    top: 70,
  });

  return clipPath;
};

export const circleShape = () => {
  const outerRadius = 200;

  return new fabric.Circle({
    radius: outerRadius, // Set the radius of the circle
    fill: "transparent",
    stroke: "black",
    strokeWidth: 2,
  });
};

export const heart = () => {
  // Heart path
  const heartPath =
    "M461.5 141.8a130.02 130.02 90 00-28.45-41.4 132.2 132.2 90 00-42-27.75A132.67 132.67 90 00339.85 62.5c-24.65 0-48.7 6.75-69.6 19.5-5 3.05-9.75 6.4-14.25 10.05-4.5-3.65-9.25-7-14.25-10.05-20.9-12.75-44.95-19.5-69.6-19.5-17.75 0-34.95 3.4-51.2 10.15-15.7 6.5-29.85 15.85-42 27.75a129.22 129.22 90 00-28.45 41.4c-6.95 16.15-10.5 33.3-10.5 50.95 0 16.65 3.4 34 10.15 51.65 5.65 14.75 13.75 30.05 24.1 45.5 16.4 24.45 38.95 49.95 66.95 75.8 46.4 42.85 92.35 72.45 94.3 73.65l11.85 7.6c5.25 3.35 12 3.35 17.25 0l11.85-7.6c1.95-1.25 47.85-30.8 94.3-73.65 28-25.85 50.55-51.35 66.95-75.8 10.35-15.45 18.5-30.75 24.1-45.5 6.75-17.65 10.15-35 10.15-51.65.05-17.65-3.5-34.8-10.45-50.95z";

  return new fabric.Path(heartPath, {
    strokeWidth: 2,
    selectable: true,
  });
};

export {
  getImage,
  createProductPayload,
  getAttachmentData,
  formatPayload,
  showPrintOnSides,
  getCatalogDetails,
  updatePrintOnSideBorder,
};
