import React from "react";

import { Typography } from "neetoui";

import DeleteSvg from "images/delete-icon.svg";
import PencilSvg from "images/pencil.svg";

export const makeColumns = (onDelete, onEdit) => [
  {
    title: "Map",
    dataIndex: "map",
    key: "map",
    render: (_, row) => (
      <div className="flex">
        <img src={row.url} className="h-24" />
        <div className="flex items-center px-4">
          <Typography style="h5" className="font-medium">
            {row.name}
          </Typography>
        </div>
      </div>
    ),
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    align: "center",
    render: (_, row) => (
      <div className="flex justify-center">
        <div className="cursor-pointer" onClick={() => onDelete(row.id)}>
          <DeleteSvg />
        </div>
        <div className="ml-2 mt-1.5 cursor-pointer" onClick={() => onEdit(row)}>
          <PencilSvg />
        </div>
      </div>
    ),
  },
];
