import React, { useState } from "react";

import { Form, Formik } from "formik";
import { Eye } from "neetoicons";
import { Button, Typography } from "neetoui";
import { Input } from "neetoui/formik";
import PropTypes from "prop-types";

import authenticationApi from "apis/authentication";
import Address from "components/ProductShipping/Address";
import { LOGIN_PATH } from "components/routeConstants";
import LogoSvg from "images/boundri-logo-black.svg";
import slider1 from "images/slider-1.png";

import {
  SIGNUP_FORM_INITIAL_VALUES,
  SIGNUP_FORM_VALIDATION_SCHEMA,
} from "./constants";

const Signup = ({ history }) => {
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async formData => {
    try {
      await authenticationApi.signup(formData);
      history.push(LOGIN_PATH);
    } catch (error) {
      logger.error(error);
    }
  };

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login flex h-screen w-screen flex-row items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-100 p-6">
      <div className="mx-auto flex w-full items-center justify-center rounded-md border bg-white shadow sm:max-w-3xl">
        <div className="form-list w-1/2">
          <Formik
            initialValues={SIGNUP_FORM_INITIAL_VALUES}
            validateOnBlur={submitted}
            validateOnChange={submitted}
            onSubmit={handleSubmit}
            validationSchema={SIGNUP_FORM_VALIDATION_SCHEMA}
          >
            {({ isSubmitting }) => (
              <Form className="w-full space-y-6 py-6 px-10">
                <LogoSvg className="mb-10" />
                <Typography style="h1">Create an account</Typography>
                <Input
                  className="custom-input"
                  name="firstName"
                  label="First Name"
                />
                <Input
                  className="custom-input"
                  name="lastName"
                  label="Last Name"
                />
                <Input
                  className="custom-input"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="oliver@example.com"
                />
                <Input
                  className="custom-input"
                  name="phone"
                  type="number"
                  label="Phone"
                  placeholder="0000000000"
                />
                <Address />
                <Input
                  className="custom-input"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  placeholder="******"
                  suffix={
                    <Eye
                      color="#1e1e20"
                      className="cursor-pointer"
                      onClick={handleClick}
                    />
                  }
                />
                <Input
                  className="custom-input"
                  name="passwordConfirmation"
                  type="password"
                  label="Confirm password"
                  placeholder="******"
                />
                <Button
                  className="rounded-full"
                  fullWidth
                  type="submit"
                  onClick={() => setSubmitted(true)}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  label="Create an account"
                />
                <div className="mt-4 flex flex-row items-center justify-start space-x-1">
                  <p className="text-xs font-normal text-gray-600">
                    Already have an account?
                  </p>
                  <Button
                    className="text-xs"
                    label="Sign in here"
                    style="link"
                    to={LOGIN_PATH}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="w-1/2">
          <img src={slider1} className="rounded-r" />
        </div>
      </div>
    </div>
  );
};

Signup.propTypes = {
  history: PropTypes.object,
};

export default Signup;
