import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1/subcategories";

const fetchByCategoryId = params =>
  axios.get(`${BASE_URL}/get_by_category_id?${qs.stringify(params)}`);

const subcategoriesApi = {
  fetchByCategoryId,
};

export default subcategoriesApi;
