import { INITIAL_MAP_DETAILS } from "common/constants";

const userReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_LAYERS": {
      return {
        ...state,
        layers: payload,
      };
    }
    case "SET_ADMIN_LAYERS": {
      return {
        ...state,
        additionalLayers: {
          ...state.additionalLayers,
          ...payload,
        },
      };
    }
    case "SET_MAP": {
      return {
        ...state,
        ...payload,
      };
    }
    case "SET_DRAW_POLYGON": {
      return {
        ...state,
        options: {
          ...state.options,
          // isDrawPolygon: payload,
        },
      };
    }
    case "SET_FIELD_DETAILS": {
      return {
        ...state,
        ...payload,
      };
    }
    case "SET_SHOW_CANVAS_EDGES": {
      return {
        ...state,
        options: {
          ...state.options,
          showCanvasEdges: payload,
        },
      };
    }
    case "SET_MAP_ID": {
      return {
        ...state,
        uploadedMapId: payload,
      };
    }
    case "REMOVE_ID": {
      return {
        ...state,
        uploadedMapId: null,
      };
    }
    case "SET_MAP_DETAILS": {
      return {
        ...state,
        mapDetails: payload,
      };
    }
    case "REMOVE_MAP_DETAILS": {
      return {
        ...state,
        mapDetails: INITIAL_MAP_DETAILS,
      };
    }
    case "SET_MAP_URL":
      return {
        ...state,
        mapWithoutLogin: payload,
      };
    case "REMOVE_MAP_IMAGES":
      return {
        ...state,
        mapWithoutLogin: null,
      };
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default userReducer;
