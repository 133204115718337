import React from "react";

import { Checkbox } from "neetoui";
import PropTypes from "prop-types";

import Title from "./Title";

const CheckBoxList = ({ list }) => (
  <div>
    <Title title="Product type" />
    {list.map(item => (
      <Checkbox key={item} label={item} className="product-checkbox mb-3" />
    ))}
  </div>
);

CheckBoxList.propTypes = {
  list: PropTypes.array,
};

export default CheckBoxList;
