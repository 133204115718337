import React, { useState } from "react";

import { Spinner } from "neetoui";
import { useParams } from "react-router-dom";

import Stripe from "components/Common/Stripe";

const Payment = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading && (
        <div className="flex h-screen w-full items-center justify-center">
          <Spinner />;
        </div>
      )}
      <div className="flex h-screen w-full items-center justify-center">
        <Stripe orderId={id} setIsLoading={setIsLoading} />
      </div>
    </>
  );
};

export default Payment;
