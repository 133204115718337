import React from "react";

import { Modal, Typography, Button } from "neetoui";

import Carousel from "components/Common/Carousel";

const PreviewProduct = ({
  isOpen,
  onClose,
  productName,
  images,
  disabled,
  onAddToCart,
  isAddingToCart,
  onSaveAsDraft,
  isSavingToCart,
  isPreview,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="large">
    <Modal.Header>
      <Typography style="h2">
        Preview Product {productName && `: ${productName}`}
      </Typography>
    </Modal.Header>
    <Modal.Body>
      <div className="m-auto px-12">
        <Carousel
          images={[...images].reverse()}
          hasMasterSpinner={true}
          width={100}
          height={90}
        />
      </div>
    </Modal.Body>
    <Modal.Footer className="flex justify-end space-x-2">
      {isPreview ? (
        <Button
          label="Close"
          style="secondary"
          className="rounded-full px-6"
          onClick={onClose}
        />
      ) : (
        <>
          <Button
            label="Add to cart"
            className="rounded-full px-6"
            disabled={disabled}
            onClick={onAddToCart}
            loading={isAddingToCart}
          />
          <Button
            label="Save as Draft"
            style="secondary"
            className="rounded-full px-6"
            disabled={disabled}
            onClick={onSaveAsDraft}
            loading={isSavingToCart}
          />
        </>
      )}
    </Modal.Footer>
  </Modal>
);
export default PreviewProduct;
