import React, { useEffect, useState } from "react";

import { Pagination, Button, Spinner, Modal, Alert } from "neetoui";

import catalogueApi from "apis/catalogue";
import { PAGE_LIMIT } from "common/constants";

import ProductCard from "./ProductCard";

import AddCatalog from "../AddCatalog";

const Catalogue = () => {
  const [loading, setLoading] = useState(true);
  const [catalogues, setCatalogues] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [catalogId, setCatalogId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const getCatalogues = async pageNumber => {
    const {
      data: { catalogues, total_count },
    } = await catalogueApi.fetch({
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: "",
    });
    setTotalCount(total_count);
    setCatalogues(() => [...catalogues]);
    setLoading(false);
  };

  useEffect(() => {
    getCatalogues(page);
  }, []);

  const handleCreteCatalog = () => {
    setIsOpen(true);
    // history.push("/admin/add-catalog");
  };

  const deleteCatalog = async id => {
    try {
      setIsDeleting(true);
      await catalogueApi.deleteCatalog(id);
    } catch (error) {
      logger.error(error);
    } finally {
      getCatalogues(page);
      setIsDeleting(false);
      setDeleteId(null);
    }
  };

  const handleClose = () => {
    setCatalogId(null);
    setIsOpen(false);
  };

  return (
    <div className="catalogue relative w-full px-10 py-5">
      {isOpen && (
        <Modal isOpen={isOpen} size="small" onClose={handleClose}>
          <AddCatalog
            id={catalogId}
            callBack={() => getCatalogues(page)}
            handleClose={handleClose}
          />
        </Modal>
      )}
      {loading && (
        <div className="absolute top-1/2 left-1/2 z-10">
          <Spinner />
        </div>
      )}
      <div className="mb-2 flex justify-end">
        <Button label="Add catalog" onClick={handleCreteCatalog} />
      </div>
      <div className="content-height grid grid-cols-3 gap-4">
        {catalogues.map(
          ({ id, title, print_providers: { variants }, min_price, images }) => (
            <ProductCard
              key={id}
              title={title}
              size={variants.length}
              minPrice={min_price?.toFixed(2)}
              images={images}
              isAdmin
              deleteCatalog={() => setDeleteId(id)}
              editClick={() => {
                setCatalogId(id);
                setIsOpen(true);
              }}
            />
          )
        )}
      </div>
      <Pagination
        className="pagination-component my-5"
        count={totalCount}
        navigate={value => {
          setPage(value);
          setLoading(true);
          getCatalogues(value);
        }}
        pageNo={page}
        pageSize={PAGE_LIMIT}
      />
      <Alert
        isOpen={!!deleteId}
        title="Delete Catalog"
        message="Are you sure?"
        onClose={() => {
          setDeleteId(null);
        }}
        onSubmit={() => deleteCatalog(deleteId)}
        isSubmitting={isDeleting}
      />
    </div>
  );
};

export default Catalogue;
