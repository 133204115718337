import React from "react";

import { Button, Input } from "neetoui";

import Carousel from "components/Common/Carousel";
import { CATALOGUE_PATH } from "components/routeConstants";
import BlueTickSvg from "images/blueTick.svg";
import BoxesSvg from "images/boxes.svg";
import EnvelopSvg from "images/envelop.svg";
import HeartSvg from "images/heartPurple.svg";
import mapImage1 from "images/mapImage1.png";
import mapImage2 from "images/mapImage2.png";
import slide1 from "images/slide1.png";
import slide2 from "images/slide2.png";
import slide3 from "images/slide3.png";
import slide4 from "images/slide4.png";
import StartsSvg from "images/stars.svg";
import TruckSvg from "images/truck.svg";

import InvertedText from "./InvertedText";

const Boxes = ({ icon, title, description, bgColor }) => (
  <div className={`w-1/4 px-14 py-8 ${bgColor} mx-3`}>
    {icon}
    <div className="mt-4 text-lg font-semibold">{title}</div>
    <div className="mt-4	text-xs font-medium">{description}</div>
  </div>
);

const HowItWorks = () => (
  <div className="how-it-works mt-10 pb-5">
    <div className="mb-10 flex items-center justify-center">
      <div className="text-6xl font-bold">How it works</div>
    </div>
    <div className="grid grid-cols-2 gap-5">
      <div className="my-34 flex pl-16">
        <InvertedText title="How it works" />
        <div className="pl-28">
          <img src={mapImage1} className="" />
        </div>
      </div>
      <div className="mx-28 my-20">
        <div className="mt-22">
          <div className="mb-5 text-2xl font-bold">
            Select an area on the map
          </div>
          <div className="mb-5 text-sm text-gray-500">
            Getting started is as easy as finding the type of product you want,
            the area of the world you want to print, and in the product
            variation size or layout you select. There are also certain map
            layers you can turn on and off to either showcase on the product or
            just to help you find your area of interest.
          </div>
          <Button
            label="Create a product"
            to={CATALOGUE_PATH}
            style="secondary"
            className="rounded-full"
          />
        </div>
      </div>
      <div className="mt-7 pl-44">
        <div className="mt-24">
          <div className="mb-5 text-2xl font-bold">Print it </div>
          <div className="mb-5 text-sm text-gray-500">
            Once you've selected the area you want you'll be guided to editing
            the map towards the product you selected as needed. This may include
            text, logos, or other pictures or icons. From there it's as simple
            as adding to your cart, enter shipping and payment, and you are on
            your way!
          </div>
          <Button
            label="Create a product"
            to={CATALOGUE_PATH}
            style="secondary"
            className="rounded-full"
          />
        </div>
      </div>
      <div className="mt-7 pl-16">
        <img src={mapImage2} className="" />
      </div>
      <div className="mt-7 pl-44">
        <Carousel
          images={[
            { src: slide1 },
            { src: slide2 },
            { src: slide3 },
            { src: slide4 },
          ]}
          width={400}
          height={360}
          showButton={false}
          showDot={true}
          isPlaying
        />
      </div>
      <div className="mx-28 my-8">
        <div>
          <div className="mb-5 text-2xl font-bold">Enjoy it </div>
          <div className="mb-5 text-sm text-gray-500">
            Below are the main product types we will support to start with more
            to come. If there is something you don't see but would like, let us
            know and we will work with you to get what you are looking for.
          </div>
          <div className="mb-5 grid grid-cols-2 gap-10">
            <div>
              <div className="text-xs font-medium">Available now:</div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Rugs and Floormats</div>
              </div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Acrylic Prints </div>
              </div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Canvas art </div>
              </div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Wall decals </div>
              </div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Wall posters </div>
              </div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Puzzles </div>
              </div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">
                  Banners and Tapestries
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs font-medium">Upcoming soon:</div>
              <div className="my-2 flex items-center ">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">
                  Booklets & Magazines
                </div>
              </div>
              <div className="my-2 flex items-center">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Notebooks</div>
              </div>
              <div className="my-2 flex items-center">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Murals</div>
              </div>
              <div className="my-2 flex items-center">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Clothing</div>
              </div>
              <div className="my-2 flex items-center">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Accessories</div>
              </div>
              <div className="my-2 flex items-center">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">Home & Living</div>
              </div>
              <div className="my-2 flex items-center">
                <BlueTickSvg className="mr-5" />
                <div className="text-xs text-gray-500">
                  Flooring and Carpets
                </div>
              </div>
            </div>
          </div>
          <Button
            label="Browse catalog"
            to={CATALOGUE_PATH}
            className="rounded-full"
          />
        </div>
      </div>
    </div>
    <div className="my-8 flex justify-between px-32">
      <Boxes
        bgColor="bg-sky-50"
        icon={<StartsSvg />}
        title={
          <div>
            Quality <br />
            guarantee
          </div>
        }
        description="All products made from top materials and printed using the latest technology."
      />
      <Boxes
        bgColor="bg-yellow-50"
        icon={<BoxesSvg />}
        title={
          <div>
            Large
            <br /> Catalogue
          </div>
        }
        description="Many product types to choose from to suite your needs."
      />
      <Boxes
        bgColor="bg-teal-50"
        icon={<TruckSvg />}
        title={
          <div>
            Fast
            <br /> shipping
          </div>
        }
        description="Typical shipping within 7-10 business days."
      />
      <Boxes
        bgColor="bg-purple-100"
        icon={<HeartSvg />}
        title="Satisfaction guarantee"
        description="If something isn't right, let us know and we will resolve the issue promptly."
      />
    </div>
    <div className="my-16 flex justify-center">
      <div className="flex w-1/2 items-center p-10 shadow-2xl">
        <EnvelopSvg className="w-1/3" />
        <div className="w-2/3">
          <div className="mb-3	text-base font-semibold">
            Subscribe for updates
          </div>
          <div className="mb-3 text-xs text-gray-500">
            Get on the list to find out what we are working on, product deals
            and discounts, and other information we want to share with everyone!
          </div>
          <Input placeholder="Your email" />
        </div>
      </div>
    </div>
  </div>
);

export default HowItWorks;
