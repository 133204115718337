import React, { useState } from "react";

import { Input, Button } from "neetoui";
import Slider from "rc-slider";

import Title from "./Title";

const PriceComponent = () => {
  const [lowerBound, setLowerBound] = useState(20);
  const [upperBound, setUpperBound] = useState(40);

  const onSliderChange = value => {
    setLowerBound(value[0]);
    setUpperBound(value[1]);
  };

  return (
    <div>
      <Title title="Price" />
      <div className="my-3 flex">
        <Input
          className="pr-1"
          value={lowerBound}
          onChange={e => setLowerBound(e.target.value)}
        />
        <Input
          className="pl-1"
          value={upperBound}
          onChange={e => setUpperBound(e.target.value)}
        />
      </div>
      <Slider
        range
        allowCross={false}
        value={[lowerBound, upperBound]}
        onChange={onSliderChange}
        min={0}
        max={100}
      />
      <div className="slider my-3 flex justify-center">
        <Button label="Apply" className="apply-btn m-auto" />
      </div>
    </div>
  );
};

export default PriceComponent;
