import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { Button as NormalButton, Spinner } from "neetoui";
import { Button } from "neetoui/formik";

import addressBooksApi from "apis/addressBook";
import Illustration2 from "images/illustration2.svg";

import AddressForm from "./AddressForm";
import { VALIDATION_SCHEMA } from "./validation";

const INITIAL_VALUES = {
  country: "",
  region: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
};

const AddressBook = () => {
  const [list, setList] = useState([]);
  const [primary, setPrimary] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchAddress = async () => {
    try {
      setIsLoading(true);
      const { data } = await addressBooksApi.fetch();
      setList(data.filter(address => address.kind === "non_primary"));
      setPrimary(data.find(address => address.kind === "primary"));
      setIsLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  const handleOnSubmit = async (values, { resetForm }) => {
    try {
      await addressBooksApi.create({
        ...values,
        country: values.country.value,
        region: values.region.value,
      });
      resetForm();
      fetchAddress();
    } catch (err) {
      logger.error(err);
    }
  };

  const handlePrimaryClick = async id => {
    try {
      setIsLoading(true);
      await addressBooksApi.setAsPrimary(id);
      fetchAddress();
    } catch (err) {
      logger.error(err);
    }
  };
  return (
    <div className="grid grid-cols-2 gap-4 p-14">
      {isLoading && (
        <div className="absolute top-1/2 left-1/2">
          <Spinner />
        </div>
      )}
      <div>
        {primary ? (
          <div className="mb-12 rounded bg-gray-100 p-5">
            <div className="mb-3.5 text-base font-semibold">
              Primary address
            </div>
            <div className="mb-1 text-xs text-gray-400">
              {primary?.address1}
            </div>
            <div className="text-xs text-gray-400">
              {primary?.city}, {primary?.region} {primary?.zip},{" "}
              {primary?.country}
            </div>
          </div>
        ) : (
          <div className="mb-12 flex h-24 items-center justify-center rounded bg-gray-100 p-5">
            No primary address
          </div>
        )}
        <div className="mb-3.5 text-base font-semibold">Add new address</div>
        <div className="mb-3.5 text-xs text-gray-600">
          Address will be used for shipping
        </div>
      </div>
      <div className="flex justify-end">
        <Illustration2 />
      </div>
      <div className="col-span-2">
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={handleOnSubmit}
          validationSchema={VALIDATION_SCHEMA}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <AddressForm />
              <div className="col-span-2 mb-12 mt-3.5 flex gap-4">
                <Button label="Create" className="rounded-full px-9" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {list.map(address => (
        <div className="border-grey-600 rounded border p-5" key={address.id}>
          {/* <div className="mb-3.5 text-base font-semibold">Primary address</div> */}
          <div className="mb-1 text-xs text-gray-400">{address.address1}</div>
          <div className="text-xs text-gray-400">
            {address.city}, {address.region} {address.zip}, {address.country}
          </div>
          <div className="mt-4 flex justify-between">
            <NormalButton
              label="Set as primary"
              className="rounded-full"
              onClick={() => handlePrimaryClick(address.id)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddressBook;
