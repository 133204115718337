import React from "react";

import FarmSvg from "images/farm.svg";
import HouseSvg from "images/house.svg";

const AddNew = ({ handleClick }) => (
  <div className="my-4">
    <div className="title mt-2">Add new</div>
    <div
      className="my-4 flex cursor-pointer items-center"
      onClick={() => handleClick("grower")}
    >
      <HouseSvg />
      <span className="ml-2">Grower</span>
    </div>
    <div
      className="my-4 flex cursor-pointer items-center"
      onClick={() => handleClick("farm")}
    >
      <FarmSvg />
      <span className="ml-2">Farm</span>
    </div>
  </div>
);

export default AddNew;
