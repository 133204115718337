import { useRef, useEffect, useState, useMemo } from "react";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { ImageService } from "mapbox-gl-esri-sources";

import { CONFIG, DRAW_STYLES, INITIAL_FIELD_DETAILS } from "common/constants";
import { LAYERS } from "common/constants";
import { getTargetDimension } from "common/helpers";
import { coordinatesGeocoder as localGeocoder } from "common/searchHelper";
import { useMapDispatch, useMapState } from "contexts/map";
import { showFieldsOnMap } from "utils/map";

import mapboxgl from "!mapbox-gl";

const USDA_SERVER_URL =
  "https://gis.apfo.usda.gov/arcgis/rest/services/NAIP/USDA_CONUS_PRIME/ImageServer";
const USDA_NAIP = "usda-naip";

const useMaps = (center = null, zoom = null, bearing = null) => {
  const [fieldDetails, setFieldDetails] = useState(INITIAL_FIELD_DETAILS);
  const [fieldList, setFieldList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [map, setMap] = useState();

  const dispatch = useMapDispatch();
  const {
    layers,
    additionalLayers,
    // options: { isDrawPolygon },
  } = useMapState();

  const mapContainer = useRef(null);

  const accessToken = process.env.MAP_BOX_ACCESS_TOKEN;

  useEffect(() => {
    if (!!map && fieldList.length && loaded) {
      showFieldsOnMap(fieldList, map);
    }
  }, [map, fieldList, loaded]);

  const draw = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        styles: DRAW_STYLES,
      }),
    []
  );

  useEffect(() => {
    const map = new mapboxgl.Map({
      accessToken,
      style: CONFIG.styleUrl,
      zoom: zoom || CONFIG.defaultZoom,
      container: mapContainer.current,
      LngLatBounds: CONFIG.LngLatBounds,
      center: center || [CONFIG.longitude, CONFIG.latitude],
      bearing: bearing || 0,
      maxPitch: 0,
    });

    map.once("style.load", () => {
      addAdditionalSources(map);
      const list = { ...layers, ...additionalLayers };
      Object.keys(list).forEach(item => {
        if (!list[item]) {
          LAYERS[item]?.forEach(layer => {
            map.setLayoutProperty(layer, "visibility", "none");
          });
        }
      });
      setLoaded(true);
    });

    // Add search controls
    map.addControl(
      new MapboxGeocoder({
        zoom: 14,
        mapboxgl,
        accessToken,
        localGeocoder,
        reverseGeocode: true,
        countries: "us, ca",
        marker: false,
      })
    );

    // Add draw controls
    // map.addControl(draw, "top-left");

    // Add navigation control
    // map.addControl(new mapboxgl.NavigationControl(), "top-left");

    // Handle polygon draw
    // map.on("draw.create", showFieldModal);
    // map.on("draw.delete", showFieldModal);

    // map.on("draw.update", showFieldModal);

    // map.on("click", options =>
    //   handleMapClick(options, draw, map, isDrawPolygon, details => {
    //     setFieldDetails(details);
    //     dispatch({
    //       type: "SET_FIELD_DETAILS",
    //       payload: {
    //         fieldDetails: details,
    //         showFieldModal: true,
    //       },
    //     });
    //   })
    // );

    setMap(map);
    dispatch({ type: "SET_MAP", payload: { map, draw } });
    // Clean up on unmount
    return () => map.remove();
  }, []);

  const getCenterCoordinates = () => map?.getCenter().toArray();

  const resetFeatures = () => setFieldDetails(INITIAL_FIELD_DETAILS);

  const getZoomLevel = () => map?.getZoom();

  const addAdditionalSources = map => {
    const imageSourceId = USDA_NAIP;
    new ImageService(imageSourceId, map, {
      url: USDA_SERVER_URL,
      getAttributionFromService: false,
    });

    map.addLayer(
      {
        id: "naip2-layer",
        type: "raster",
        source: imageSourceId,
        minzoom: 5,
        maxzoon: 22,
      },
      "naip-layer"
    );
  };

  const generateImage = (details, mapName, setProgress, callBack) => {
    setProgress("Generating image...");
    // TODO TEST VALUES HERE
    const MAX_PIXELS = 45 * 10 ** 6;
    const previousResolution = window.devicePixelRatio;

    window.devicePixelRatio = 1;

    const newScale = Math.min(
      getTargetDimension(details.originalWidth) / details.width,
      getTargetDimension(details.originalHeight) / details.height
    );

    let width = Math.round(details.width * newScale);
    let height = Math.round(details.height * newScale);

    const pixels = width * height;

    if (pixels > MAX_PIXELS) {
      const scaleFactor = Math.sqrt(MAX_PIXELS) / Math.sqrt(pixels);
      width *= scaleFactor;
      height *= scaleFactor;
    }

    const zoom = map.getZoom();
    const center = map.getCenter();
    const bearing = map.getBearing();

    const bBox = document.querySelector("#bbox-01");
    const pixelBounds = {
      left: bBox?.offsetLeft,
      bottom: bBox?.offsetTop + bBox?.offsetHeight,
      right: bBox?.offsetLeft + bBox?.offsetWidth,
      top: bBox?.offsetTop,
    };

    const bounds = [
      map.unproject([pixelBounds.left, pixelBounds.bottom]),
      map.unproject([pixelBounds.right, pixelBounds.top]),
    ];

    const hidden = document.createElement("div");
    hidden.className = "hidden-map";
    document.body.appendChild(hidden);
    const container = document.createElement("div");
    container.style.width = `${width}px`;
    container.style.height = `${height}px`;
    hidden.appendChild(container);

    const mapOptions = {
      bounds,
      container,
      accessToken,
      fadeDuration: 0,
      interactive: false,
      style: CONFIG.styleUrl,
      attributionControl: false,
      preserveDrawingBuffer: true,
      bearing,
    };

    const renderMap = new mapboxgl.Map(mapOptions);

    renderMap.on("load", () => {
      renderMap.setBearing(bearing);
      const list = { ...layers, ...additionalLayers };
      if (layers.naip2) addAdditionalSources(renderMap);

      Object.keys(list)
        .filter(key => {
          if (key === "naip2" && !layers.naip2) {
            return false;
          }

          return true;
        })
        .forEach(item => {
          LAYERS[item]?.forEach(layer => {
            renderMap.setLayoutProperty(
              layer,
              "visibility",
              !list[item] ? "none" : "visible"
            );
          });
        });
    });

    const newDetails = {
      ...details,
      zoom,
      center,
      bearing,
    };

    renderMap.once("idle", async () => {
      const canvas = renderMap.getCanvas();
      canvas.toBlob(blob => {
        // exportTestImage(blob);
        if (blob.size > 100 * 10 ** 6) {
          throw new Error("Generated image too large", blob.size);
          // TODO SCALE AND REGENERATE
          // scale factor = 50 / imgFileSize
          // call function again with new args here or resize the map in place?
        }
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("name", mapName);
        formData.append("details", JSON.stringify(newDetails));
        hidden.remove();
        callBack(formData, newDetails, {
          name: mapName,
          blob,
        });
        window.devicePixelRatio = previousResolution;
      });
    });
  };

  return {
    mapContainer,
    fieldDetails,
    resetFeatures,
    getCenterCoordinates,
    getZoomLevel,
    setFieldList,
    map,
    generateImage,
  };
};

export default useMaps;
