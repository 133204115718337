import React from "react";

import DropDownSvg from "images/dropDownArrow.svg";

const CustomButtons = ({
  name,
  icon,
  onClick = () => {},
  showDropDown = true,
}) => (
  <div
    className="border-500 flex cursor-pointer items-center rounded border p-5"
    onClick={onClick}
  >
    {icon}
    <span className="pl-4 pr-3 text-xs font-normal text-gray-600">{name}</span>
    {showDropDown && <DropDownSvg />}
  </div>
);

export default CustomButtons;
