import React from "react";

import { Select, Button } from "neetoui";

import { PAGE_ORIENTATION, PAGE_SIZE } from "./constants";

const ScreenShotOption = ({
  generateStaticImage,
  handlePageOrientation,
  handlePageSize,
  cancelScreenShot,
}) => {
  const pageSizeOption = Object.keys(PAGE_SIZE).map(key => ({
    label: key,
    value: PAGE_SIZE[key],
  }));
  return (
    <div className="w-56 rounded bg-white p-2">
      <Select
        className="m-1"
        label="Page size"
        options={pageSizeOption}
        onChange={handlePageSize}
      />
      <Select
        className="m-1"
        label="Page orientation"
        options={PAGE_ORIENTATION}
        onChange={handlePageOrientation}
      />
      <div className="mt-2 flex justify-center gap-2">
        <Button label="Generate" onClick={generateStaticImage} />
        <Button label="Cancel" onClick={cancelScreenShot} style="text" />
      </div>
    </div>
  );
};

export default ScreenShotOption;
