import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const LOGIN_FORM_INITIAL_VALUES = {
  email: "",
  password: "",
};

export const RESET_PASSWORD_FORM_INITIAL_VALUES = {
  email: "",
};

export const SIGNUP_FORM_INITIAL_VALUES = {
  email: "",
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  phone: "",
  country: "",
  region: "",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
};

export const RESET_FORM_INITIAL_VALUES = {
  password: "",
  confirmPassword: "",
};

export const LOGIN_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Required"),
  password: yup.string().required("Required"),
});

export const RESET_PASSWORD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Required"),
});

export const PASSWORD_RESET_VALIDATION = yup.object().shape({
  password: yup.string().required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Required"),
});

export const SIGNUP_FORM_VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),
  password: yup.string().required("Required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  country: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required("Required")
    .nullable(),
});
