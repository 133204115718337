import axios from "axios";
import qs from "qs";

const BASE_URL = "api/v1/public";
const CATALOGUES_URL = `${BASE_URL}/catalogues`;

const fetch = params => axios.get(`${CATALOGUES_URL}?${qs.stringify(params)}`);
const fetchById = id => axios.get(`${CATALOGUES_URL}/${id}`);
const fetchByBluePrint = params =>
  axios.get(
    `${CATALOGUES_URL}/get_catalouge_by_blue_print_id?${qs.stringify(params)}`
  );

const fetchCatalogDetails = params =>
  axios.get(
    `${CATALOGUES_URL}/get_catalog_from_printify?${qs.stringify(params)}`
  );

const fetchPrintProviders = params =>
  axios.get(`${CATALOGUES_URL}/get_print_providers?${qs.stringify(params)}`);

const fetchVariants = params =>
  axios.get(`${CATALOGUES_URL}/get_catalog_variants?${qs.stringify(params)}`);

const createCatalog = formData =>
  axios.post(CATALOGUES_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteCatalog = id => axios.delete(`${CATALOGUES_URL}/${id}`);

const editCatalog = (id, formData) =>
  axios.put(`${CATALOGUES_URL}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const searchBluePrints = params =>
  axios.get(`${CATALOGUES_URL}/search_blue_prints?${qs.stringify(params)}`);

const cataloguesApi = {
  fetch,
  fetchById,
  fetchByBluePrint,
  fetchCatalogDetails,
  fetchPrintProviders,
  fetchVariants,
  createCatalog,
  deleteCatalog,
  editCatalog,
  searchBluePrints,
};

export default cataloguesApi;
