import React from "react";

import { Form, Formik } from "formik";
import {
  Modal,
  Typography,
  Button,
  ColorPicker,
  Label,
  DatePicker,
} from "neetoui";
import { Input, Select, Textarea } from "neetoui/formik";

import fieldsApi from "apis/fields";

import {
  FIELD_FORM_VALIDATION_SCHEMA,
  INITIAL_FORM_VALUES,
} from "../constants";

const FieldModal = ({
  details,
  showModal = true,
  resetFeatures,
  setShowModal,
  initialValues,
  setField,
  reloadFields,
  landCategories,
  groups,
  regions,
}) => {
  const onClose = () => {
    resetFeatures();
    setShowModal(false);
    setField(null);
  };

  const handleSubmit = async values => {
    try {
      const payload = {
        ...details,
        ...values,
      };
      initialValues
        ? await fieldsApi.update(values.id, payload)
        : await fieldsApi.create(payload);
      reloadFields();
      onClose();
    } catch (err) {
      // Toastr.error(err);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || INITIAL_FORM_VALUES}
      onSubmit={handleSubmit}
      validationSchema={FIELD_FORM_VALIDATION_SCHEMA}
    >
      {({ setFieldValue, values: { color }, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Modal isOpen={showModal} onClose={onClose} closeButton={false}>
            <Modal.Header>
              <Typography style="h2" id="dialog1Title">
                Field details
              </Typography>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-2">
                <Typography style="body2">
                  Area : {details.area || initialValues?.area}
                </Typography>
              </div>
              <div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-start-1 col-end-3">
                    <Input
                      size="small"
                      label="Name"
                      placeholder="Name"
                      name="fieldName"
                    />
                  </div>
                  <Select
                    size="small"
                    label="Crop"
                    placeholder="Crop"
                    name="landCategoryId"
                    options={landCategories}
                    onChange={option => {
                      setFieldValue("landCategoryId", option.value);
                      setFieldValue("color", option.color);
                    }}
                  />
                  {!!groups.length && (
                    <Select
                      size="small"
                      label="Group"
                      placeholder="Group"
                      name="groupId"
                      options={groups}
                      onChange={option =>
                        setFieldValue("groupId", option.value)
                      }
                    />
                  )}
                  {!!regions.length && (
                    <Select
                      size="small"
                      label="Region"
                      placeholder="Region"
                      name="regionId"
                      options={regions}
                      onChange={option =>
                        setFieldValue("regionId", option.value)
                      }
                    />
                  )}
                  <DatePicker
                    defaultValue={initialValues?.cropYear}
                    label="Crop year"
                    picker="year"
                    onChange={(date, dateString) =>
                      setFieldValue("cropYear", dateString)
                    }
                  />
                  <DatePicker
                    defaultValue={initialValues?.harvestDate}
                    label="Harvest date"
                    onChange={(date, dateString) =>
                      setFieldValue("harvestDate", dateString)
                    }
                  />
                  <div className="col-start-1 col-end-3">
                    <Textarea
                      name="description"
                      label="Description"
                      placeholder="Description"
                      rows={3}
                    />
                  </div>
                  <div className="col-start-1 col-end-3">
                    <Label className="mb-2">Color</Label>
                    <ColorPicker
                      color={color}
                      onChange={({ hex }) => setFieldValue("color", hex)}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="space-x-2">
              <Button
                label="Save"
                type="submit"
                size="large"
                onClick={handleSubmit}
              />
              <Button
                style="text"
                label="Cancel"
                onClick={onClose}
                size="large"
              />
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default FieldModal;
