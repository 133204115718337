import React from "react";

import { useFormikContext } from "formik";
import { Input, Select } from "neetoui/formik";

import useCountryList from "hooks/useCountryList";

const AddressForm = () => {
  const { countryList, regionList } = useCountryList();
  const {
    values: { country },
  } = useFormikContext();

  const isRegionDisabled = country.length === 0;

  return (
    <div className="grid grid-cols-2 gap-4 p-14">
      <Select
        className="custom-select"
        label="Country"
        name="country"
        options={countryList}
      />
      <Select
        className="custom-select"
        label="Region"
        options={regionList}
        name="region"
        isDisabled={isRegionDisabled}
      />
      <Input
        className="custom-input col-span-2"
        label="Address1"
        name="address1"
      />
      <Input
        className="custom-input col-span-2"
        label="Address2"
        name="address2"
      />
      <Input className="custom-input" label="City" name="city" />
      <Input className="custom-input" label="Zip code" name="zip" />
    </div>
  );
};

export default AddressForm;
