import React, { useEffect, useState } from "react";

import { Button, Table } from "neetoui";
import { Container, Header, Scrollable } from "neetoui/layouts";

import regionsApi from "apis/regions";
import CreatGroupOrRegion from "components/Common/CreatGroupOrRegion";
import EditGroupOrRegion from "components/Common/EditGroupOrRegion";

import { VALIDATION_SCHEMA, COLUMNS } from "./constant";

const Regions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [region, setRegion] = useState();

  const getRegionList = async () => {
    try {
      const { data } = await regionsApi.fetch();
      setTableData(data);
      setIsLoading(false);
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    getRegionList();
  }, []);

  const onHandleCreate = async payload => {
    try {
      await regionsApi.create(payload);
      setShowModal(false);
      getRegionList();
    } catch (err) {
      logger.error(err);
    }
  };

  const onHandleUpdate = async payload => {
    try {
      await regionsApi.update(region?.id, payload);
      setRegion(undefined);
      getRegionList();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Container>
      <Header
        actionBlock={
          <Button
            label="Add Regions"
            size="large"
            onClick={() => setShowModal(true)}
          />
        }
        title="Regions"
      />
      <Scrollable className="w-full">
        <Table
          isLoading={isLoading}
          columnData={COLUMNS}
          rowData={tableData}
          defaultPageSize={10}
          currentPageNumber={pageNumber}
          handlePageChange={page => setPageNumber(page)}
          fixedHeight
          rowSelection={false}
          onRowClick={(event, record) => setRegion(record)}
        />
        <CreatGroupOrRegion
          showModal={showModal}
          onClose={() => setShowModal(false)}
          handleSubmit={onHandleCreate}
          isRegion
          validationSchema={VALIDATION_SCHEMA}
        />
        {!!region && (
          <EditGroupOrRegion
            showPane={!!region}
            onClose={() => setRegion(undefined)}
            handleSubmit={onHandleUpdate}
            validationSchema={VALIDATION_SCHEMA}
            initialValues={{
              ...region,
              group: { value: region.group.id, label: region.group.title },
            }}
            isRegion
          />
        )}
      </Scrollable>
    </Container>
  );
};

export default Regions;
