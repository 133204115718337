import React, { useRef, useEffect, useState } from "react";

import { fabric } from "fabric";
import { Button, Select } from "neetoui";

import { circleShape, starShape, heart } from "./helpers";

const WIDTH = 500;
const HEIGHT = 500;

const CropImage = ({ imageObject, ogCanvas, onClose }) => {
  const canvasRef = useRef(null);
  const parentRef = useRef(null);
  const [canvas, setCanvas] = useState(null);

  useEffect(() => {
    // Create a new Fabric.js canvas instance
    const newCanvas = new fabric.Canvas(canvasRef.current);
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerSize = 8;
    fabric.Object.NUM_FRACTION_DIGITS = 17;

    newCanvas.width = 500;
    newCanvas.height = 500;

    newCanvas.preserveObjectStacking = true;
    newCanvas.controlsAboveOverlay = true;
    newCanvas.selection = false;

    const clipPath = starShape();
    clipPath.left = newCanvas.width / 2 - clipPath.width / 2;
    clipPath.top = newCanvas.height / 2 - clipPath.height / 2;

    newCanvas.clipPath = clipPath;
    const copy = fabric.util.object.clone(imageObject);

    newCanvas.add(
      copy.set({
        left: clipPath.left,
        top: clipPath.top,
      })
    );

    copy.scaleToWidth(450);

    newCanvas.backgroundColor = "#e5e7eb";
    newCanvas.renderAll();

    setCanvas(newCanvas);

    // Cleanup function
    return () => {
      // Clear the canvas
      newCanvas.clear();
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleChange = item => {
    let clipPath = starShape();

    if (item.value === "circle") {
      clipPath = circleShape();
    }

    if (item.value === "heart") {
      clipPath = heart();
    }

    clipPath.left = canvas.width / 2 - clipPath.width / 2;
    clipPath.top = canvas.height / 2 - clipPath.height / 2;

    canvas.clipPath = clipPath;
    canvas.renderAll();
  };

  const handleCrop = () => {
    // Export the canvas as a data URL
    const dataURL = canvas.toDataURL({
      format: "png",
    });
    // Step 3: Load the image object
    var img = new Image();
    img.onload = function () {
      // Step 4: Update fabric active object with new image
      var activeObject = ogCanvas.getActiveObject();
      activeObject.setElement(img);
      activeObject.scale(0.5);
      ogCanvas.renderAll();
      onClose();
    };
    img.src = dataURL;
  };

  return (
    <div className="relative p-10">
      <Select
        options={[
          { label: "Star", value: "star" },
          { label: "Circle", value: "circle" },
          { label: "Heart", value: "heart" },
        ]}
        className="z-10 w-44"
        onChange={handleChange}
      />
      <div
        ref={parentRef}
        className="relative my-5 mx-24 border-2 border-solid"
        style={{ width: WIDTH }}
      >
        <canvas ref={canvasRef} width={WIDTH} height={HEIGHT} />
      </div>
      <Button label="Crop" onClick={handleCrop} />
    </div>
  );
};

export default CropImage;
