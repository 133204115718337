import React, { useMemo, useState, useEffect } from "react";

import { Table } from "neetoui";
import { useHistory } from "react-router-dom";

import ordersApi from "apis/orders";
import { PAGE_LIMIT } from "common/constants";

import { makeColumns } from "./tableColumns";

const OrderList = ({ searchTerm }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const history = useHistory();

  const getOrders = async () => {
    const {
      data: { orders, total_count },
    } = await ordersApi.fetch({
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: searchTerm,
    });

    setOrderList(orders);
    setIsLoading(false);
    setTotalCount(total_count);
  };

  useEffect(() => {
    getOrders();
  }, [searchTerm, pageNumber]);

  const onEdit = id => {
    history.push(`/product-review/${id}`);
  };

  const COLUMNS = useMemo(() => makeColumns(onEdit), []);

  return (
    <div
      style={{
        height: "calc(100vh - 318px)",
      }}
    >
      <Table
        className="bg-white"
        columnData={COLUMNS}
        rowData={orderList}
        showHeader={false}
        loading={isLoading}
        fixedHeight
        currentPageNumber={pageNumber}
        handlePageChange={page => setPageNumber(page)}
        defaultPageSize={PAGE_LIMIT}
        totalCount={totalCount}
      />
    </div>
  );
};

export default OrderList;
